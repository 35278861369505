import { OverlayTrigger, Popover } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  SET_SEARCH_FILTER,
  SET_SEARCH_FILTER_API,
} from "../../../store/actions";
import { filtersObj } from "../../shared/helpers";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./popover_filter.module.css";

const PopoverFilter = () => {
  const SEARCH_FILTER_DATA = useSelector((state) => state.header.searchFilter);
  const [bool, setBool] = useState(false);
  const [liSelected, setliSelected] = useState({ ...SEARCH_FILTER_DATA });
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location.search);

  const pageNumber = query.get("page") ?? 1;
  console.log(pageNumber, "pageNumberpageNumberpageNumber");

  const SEARCH_FILTER_API = useSelector(
    (state) => state.header.searchFilterAPI
  );
  const userInfo = useSelector((state) => state.user.data);

  useEffect(() => {
    console.log(
      ["Aziendale", "Nuovo"]?.join(","),
      JSON.stringify(["Aziendale", "Nuovo"]),
      query.getAll("filter"),
      "liSelectedliSelectedliSelected",
      liSelected,
      // filter_true,
      location.search,
      bool
    );

    if (bool) {
      setBool(false);
      let filter_true = Object.keys(liSelected).filter(
        (i) => liSelected[i].val === true
      );
      let id;
      console.log(filter_true, "liSelected");
      if (filter_true.length != 0) {
        dispatch({ type: SET_SEARCH_FILTER, payload: liSelected });
        dispatch({ type: SET_SEARCH_FILTER_API, payload: true });
        history.push(
          `/inspections?page=1${
            liSelected?.self_inspect.val ? "&filter=self_inspect" : ""
          }${liSelected?.wit_inspect.val ? "&filter=wit_inspect" : ""}${
            liSelected?.un_assigned.val ? "&filter=un_assigned" : ""
          }${liSelected?.pending.val ? "&filter=pending" : ""}${
            liSelected?.in_progress.val ? "&filter=in_progress" : ""
          }${liSelected?.completed.val ? "&filter=completed" : ""}${
            liSelected?.paid.val ? "&filter=paid" : ""
          }${liSelected?.un_paid.val ? "&filter=un_paid" : ""}${
            liSelected?.active.val ? "&filter=active" : ""
          }${liSelected?.deactivate.val ? "&filter=deactivate" : ""}`
        );
      } else {
        // console.log("loll");
        dispatch({ type: SET_SEARCH_FILTER, payload: filtersObj() });
        history.push(`/inspections?page=1`);
      }
    } else {
      let filter_true = Object.keys(liSelected).filter(
        (i) => liSelected[i].val === true
      );

      if (filter_true.length !== 0) {
        history.push(
          `/inspections?page=${pageNumber}${
            liSelected?.self_inspect.val ? "&filter=self_inspect" : ""
          }${liSelected?.wit_inspect.val ? "&filter=wit_inspect" : ""}${
            liSelected?.un_assigned.val ? "&filter=un_assigned" : ""
          }${liSelected?.pending.val ? "&filter=pending" : ""}${
            liSelected?.in_progress.val ? "&filter=in_progress" : ""
          }${liSelected?.completed.val ? "&filter=completed" : ""}${
            liSelected?.paid.val ? "&filter=paid" : ""
          }${liSelected?.un_paid.val ? "&filter=un_paid" : ""}${
            liSelected?.active.val ? "&filter=active" : ""
          }${liSelected?.deactivate.val ? "&filter=deactivate" : ""}`
        );
      } else if (query.getAll("filter").length !== 0) {
        console.log("null");
      } else {
        dispatch({ type: SET_SEARCH_FILTER, payload: filtersObj() });
        history.push(`/inspections?page=${pageNumber}`);
        // console.log("hihihihih");
      }
    }
    console.log("hihihihihhihihihihhihihihihhihihihih");
  }, [liSelected]);

  const filterOption = Object.entries(liSelected).filter((obj) => {
    return obj[1]?.remove !== true && obj[1]?.ex_role !== userInfo.role;
  });

  const getCssClasses = (index) => {
    return `${
      filterOption.length % 2 === 0
        ? index + 1 === Math.ceil(filterOption.length / 2)
          ? styles.sec_last_column
          : index === filterOption.length - 1
          ? styles.last_column
          : {}
        : index + 1 === Math.ceil(filterOption.length / 2)
        ? styles.sec_last_column
        : {}
    }  
    
    ${index < filterOption.length / 2 ? styles.evenList : {}}`;
  };

  const popover = (
    <Popover id="popover-contained" className={styles.popover_container}>
      <Popover.Content className={styles.popover_filters}>
        {/* // TODO : "will remove "REMOVE TAG" when clients says open inspector mode" */}
        <ul>
          {filterOption.map(([key, value], index) => (
            <li
              tabIndex="1"
              className={`${getCssClasses(index)} ${
                value?.val ? styles.is_selected : {}
              }`}
              id={key}
              key={key}
              onClick={() => {
                setBool(!bool);
                setliSelected({
                  ...liSelected,
                  [key]: { ...value, val: !value.val },
                });
              }}
            >
              <div>{value?.name}</div>
            </li>
          ))}
        </ul>
      </Popover.Content>
    </Popover>
  );
  return (
    <div className={`${styles.overlay_container}`}>
      {" "}
      <OverlayTrigger
        trigger="click"
        rootClose
        overlay={popover}
        placement="bottom-end"
      >
        <div className={styles.filter_icon_container}>
        <img
          src={require(`../../../assets/icons/filter@2x.png`).default}
          width="100%"
        />
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default PopoverFilter;
