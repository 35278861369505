import React from "react";
import styles from "./room_feature_listing.module.css";
import FeatureListing from "main/shared_components/FeatureListing/FeatureListing";
import { RoomCLickTypesEnum } from "main/shared/constants";
import { useDispatch } from "react-redux";
import {
  handleFeatureSelectedInRoomAction,
  handleSingleRoomSelectedAction,
} from "store/actions/test_recommend/testRecommendAction";

const RomFeatureListing = ({ room }) => {
  const dispatch = useDispatch();

  const handleRoomIsSelected = async (type, roomUniqueId) => {
    try {
      await dispatch(handleSingleRoomSelectedAction(type, roomUniqueId));
    } catch (error) {}
  };

  const handleRoomFeatureIsSelected = async (type, roomUniqueId, featureId) => {
    try {
      await dispatch(
        handleFeatureSelectedInRoomAction(type, roomUniqueId, featureId)
      );
    } catch (error) {}
  };

  return (
    <div key={room?.roomUniqueId}>
      <div className={styles.room_detail}>
        <div className={styles.room_heading}>{room?.roomUniqueName}</div>
        <div>
          {room?.isSelected ? (
            <img
              src={require("assets/icons/open_room_detail_arrow.png").default}
              alt={"arrow"}
              width="24px"
              className={styles.room_icon}
              onClick={() =>
                handleRoomIsSelected(RoomCLickTypesEnum.CLOSE, room?.roomUniqueId)
              }
            />
          ) : (
            <img
              src={require("assets/icons/close_room_detail_arrow.png").default}
              alt={"arrow"}
              width="24px"
              className={styles.room_icon}
              onClick={() =>
                handleRoomIsSelected(RoomCLickTypesEnum.OPEN, room?.roomUniqueId)
              }
            />
          )}
        </div>
      </div>

      <div
        className={
          room?.isSelected ? styles.display_feature : styles.hide_feature
        }
      >
        <FeatureListing
          key={room?.roomUniqueId}
          featureList={room?.observations}
          handleSelectedFeature={handleRoomFeatureIsSelected}
        />
      </div>
    </div>
  );
};

export default RomFeatureListing;
