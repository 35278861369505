export const RoomCLickTypesEnum = {
  OPEN: "open",
  CLOSE: "close",
};

export const FeatureClickTypesEnum = {
  SELECT: true,
  NOT_SELECT: false,
};

export const TaskClickTypesEnum = {
  SELECT: true,
  NOT_SELECT: false,
};

export const ReactSelectActionTypes = {
  CLEAR_ALL: "clear",
  REMOVE_SINGLE_VALUE: "remove-value",
};

export const approvedView = "approveview";

export const filterOption = [
  {
    label: "All",
    value: "all",
    default: true,
  },
  {
    label: "Approved by Client",
    value: "approved",
  },
  {
    label: "Unapproved by Client",
    value: "unapproved",
  },
];

export const getDefaultApproveView = () => {
  const defaultActiveKey = filterOption.find(
    (option) => option.default === true
  );
  return defaultActiveKey.value;
};

export const viewTestAndRoomsQueryTabName = "viewtestandroomtab";

export const viewTestAndRoomsTabs = {
  roomView: {
    value: "home",
    label: "Room View",
    default: true,
  },
  testView: {
    value: "test",
    label: "Test View",
  },
};

export const approvedClientStatus = {
  APPROVE: "approved",
  PENDING: "pending",
};

export const getDefaultTestAndRoomsTab = () => {
  const defaultActiveKey = Object.keys(viewTestAndRoomsTabs).find(
    (key) => viewTestAndRoomsTabs[key].default === true
  );
  return (
    viewTestAndRoomsTabs[defaultActiveKey]?.value ||
    viewTestAndRoomsTabs.roomView.value
  );
};

export const ViewRecommendedTabs = [
  { key: "home", label: "Home View" },
  { key: "test", label: "Test View" },
];

export const ViewRecommendedToastMsg = {
  home: { msg: "Switched To Room View" },
  test: { msg: "Switched To Test View" },
};

export const roomActions = {
  APPROVED: "approved",
  UN_APPROVED: "unapproved",
};
