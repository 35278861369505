import React, { useState, useEffect } from "react";
import styles from "./view_recommend_test.module.css";
import TestLeftSide from "./TestLeftSide/TestLeftSide";
import TestRightSide from "./TestRightSide/TestRightSide";
import { useHistory, useLocation } from "react-router-dom";
import {
  approvedView,
  getDefaultApproveView,
  getDefaultTestAndRoomsTab,
  viewTestAndRoomsQueryTabName,
} from "main/shared/constants";
import { useDispatch } from "react-redux";
import {
  GET_VIEW_RECOMMENDED_ROOM_LIST_RESET,
  GET_VIEW_RECOMMENDED_TEST_LIST_RESET,
  VIEW_RECOMMENDED_SELECTED_ROOM_LIST_RESET,
  VIEW_RECOMMENDED_SELECTED_TEST_LIST_RESET,
} from "store/types/view_test_recommend/viewTestRecommendTypes";

const ViewRecommendedTest = () => {
  const [roomSearchValue, setRoomSearchValue] = useState([]);
  const [testSearchValue, setTestSearchValue] = useState([]);

  const [backConfirmationModal, setBackConfirmationModal] = useState(false);
  const [removeValues, setRemoveValues] = useState("");
  const [keyForResetCacheOFSelect, setKeyForResetCacheOFSelect] = useState(0);

  const dispatch = useDispatch();
  let history = useHistory();
  let location = useLocation();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);

  const handleRoomAndTestViewInQueryParameter = () => {
    if (!searchParams.get(viewTestAndRoomsQueryTabName)) {
      searchParams.set(
        viewTestAndRoomsQueryTabName,
        getDefaultTestAndRoomsTab()
      );
      searchParams.set(approvedView, getDefaultApproveView());
      history.replace({ search: searchParams.toString() });
    }
  };

  const resetSelectedRoomData = () => {
    setRoomSearchValue([]);
    setRemoveValues("");
    setBackConfirmationModal(false);
    dispatch({ type: VIEW_RECOMMENDED_SELECTED_ROOM_LIST_RESET });
  };

  const resetSelectedTestData = () => {
    setTestSearchValue([]);
    setRemoveValues("");
    setBackConfirmationModal(false);
    dispatch({ type: VIEW_RECOMMENDED_SELECTED_TEST_LIST_RESET });
  };

  const resetAllRoomData = () => {
    resetSelectedRoomData();
    dispatch({ type: GET_VIEW_RECOMMENDED_ROOM_LIST_RESET });
  };

  const resetAllTestData = () => {
    resetSelectedTestData();
    dispatch({ type: GET_VIEW_RECOMMENDED_TEST_LIST_RESET });
  };

  useEffect(() => {
    handleRoomAndTestViewInQueryParameter();
    resetAllRoomData();
    resetAllTestData();
  }, []);

  return (
    <React.Fragment>
      <div className={styles.container}>
        <div className={styles.test_left_side_container}>
          <TestLeftSide
            resetSelectedRoomData={resetSelectedRoomData}
            resetSelectedTestData={resetSelectedTestData}
            roomSearchValue={roomSearchValue}
            setRoomSearchValue={setRoomSearchValue}
            testSearchValue={testSearchValue}
            setTestSearchValue={setTestSearchValue}
            backConfirmationModal={backConfirmationModal}
            setBackConfirmationModal={setBackConfirmationModal}
            removeValues={removeValues}
            setRemoveValues={setRemoveValues}
            keyForResetCacheOFSelect={keyForResetCacheOFSelect}
            setKeyForResetCacheOFSelect={setKeyForResetCacheOFSelect}
          />
        </div>
        <div className={styles.test_right_side_container}>
          <TestRightSide
            resetSelectedTestData={resetSelectedTestData}
            resetSelectedRoomData={resetSelectedRoomData}
            keyForResetCacheOFSelect={keyForResetCacheOFSelect}
            setKeyForResetCacheOFSelect={setKeyForResetCacheOFSelect}
            setRoomSearchValue={setRoomSearchValue}
            roomSearchValue={roomSearchValue}
            testSearchValue={testSearchValue}
            setTestSearchValue={setTestSearchValue}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewRecommendedTest;
