import React, { useState } from "react";
import styles from "./name_listing.module.css";
import { FeatureClickTypesEnum } from "main/shared/constants";
import EditTestDialog from "main/shared_components/EditTestDialog/EditTestDialog";
import CustomTooltip from "main/components/Tooltip/Tooltip";
import { getShortSentenceInString } from "main/shared/helpers";

const testArrayOption = [
  {
    value: "value1",
    label: "label1",
  },
  {
    value: "value2",
    label: "label2",
  },
  {
    value: "value3",
    label: "label3",
  },
  {
    value: "value4",
    label: "label4",
  },
];

const NameListing = ({ listingData = [], handleSelectedList = () => {} }) => {
  const [showEditorModel, setShowEditorModel] = useState(false);
  const [currentData, setCurrentData] = useState(false);

  const handleEditTestBtnClick = (data) => {
    setCurrentData(data);
    setShowEditorModel(true);
  };

  const handleOnCloseEditButton = () => {
    setShowEditorModel(!showEditorModel);
  };

  const handleOnEditSubmitButton = (e) => {
    console.log({ e });
  };

  const getListName = (testList) => {
    if (testList?.location) {
      return `${testList?.name} : ${testList?.location}`;
    } else {
      return `${testList?.name}`;
    }
  };

  return (
    <div className={styles.listing_container}>
      <div>
        {listingData?.length ? (
          listingData.map((list, index) => {
            return (
              <div key={index}>
                <div className={styles.room_heading}>{list?.description}</div>
                <ul className={styles.listing_container_ul}>
                  <div className={styles.list_ul}>
                    {list?.tests?.length ? (
                      list?.tests.map((testList, index) => {
                        return (
                          <li
                            className={`${styles.listing_li}  ${
                              testList?.isSelected
                                ? styles.listing_li_selected
                                : styles.listing_li_no_selected
                            }`}
                            key={testList?.id}
                            onClick={() =>
                              !testList?.isSelected &&
                              handleSelectedList(
                                FeatureClickTypesEnum.SELECT,
                                list?.id,
                                testList?.id
                              )
                            }
                          >
                            <div className={styles.listing_li_container}>
                              <div className={styles.name_li_container}>
                                <div>
                                  {testList?.isSelected ? (
                                    <img
                                      src={
                                        require("assets/icons/radio@2x.png")
                                          .default
                                      }
                                      alt={testList?.name}
                                      className={styles.radioImage}
                                    />
                                  ) : (
                                    <img
                                      src={
                                        require("assets/icons/radio1@2x.png")
                                          .default
                                      }
                                      alt={testList?.name}
                                      className={styles.radioImage}
                                      onClick={() =>
                                        handleSelectedList(
                                          FeatureClickTypesEnum.SELECT,
                                          list?.id,
                                          testList?.id
                                        )
                                      }
                                    />
                                  )}
                                </div>
                                <div className={styles.listing_name}>
                                  <CustomTooltip
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    text={getListName(testList)}
                                  >
                                    <div className={styles.img_title_chip}>
                                      {getShortSentenceInString(
                                        getListName(testList),
                                        30
                                      )}
                                    </div>
                                  </CustomTooltip>
                                </div>
                              </div>
                              <div
                                className={styles.edit_icon}
                                onClick={() => handleEditTestBtnClick(list)}
                              >
                                <img
                                  src={
                                    require("assets/icons/edit-icon.png")
                                      .default
                                  }
                                />
                              </div>
                            </div>
                          </li>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                </ul>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>

      <EditTestDialog
        data={currentData}
        show={showEditorModel}
        handleOnClose={handleOnCloseEditButton}
        handleOnSubmit={handleOnEditSubmitButton}
        options={testArrayOption}
      />
    </div>
  );
};

export default NameListing;
