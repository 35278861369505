import React, { useState, useEffect } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import {
  getBase64,
  markersArray,
  replaceByStorageURL,
} from "../../shared/helpers";
import Magnifier from "react-magnifier";
import styles from "./full_size_image_style.module.css";
import * as markerjs2 from "markerjs2";

const FullSizeImageModal = (props) => {
  let imgRef = React.useRef(null);
  const [editorView, setEditorView] = useState(true);

  const [imageLoader, setImageLoader] = useState(false);
  const [imageRotate, setImageRotate] = useState(0);
  const [rotatedScale, setRotatedScale] = useState(1);
  const [imageUrl, setImageUrl] = useState("");
  const [showUpdateImgBtn, setShowUpdateImgBtn] = useState(false);
  const [qualityImage, setQualityImage] = useState("");

  const showMarkerArea = () => {
    if (imgRef?.current !== null) {
      const markerArea = new markerjs2.MarkerArea(imgRef?.current);
      markerArea.addRenderEventListener((dataUrl, state) => {
        if (imgRef?.current) {
          imgRef.current.src = dataUrl;
          setShowUpdateImgBtn(true);
        }
      });
      markerArea.renderAtNaturalSize = true;
      markerArea.renderImageType = "image/jpeg";
      markerArea.renderImageQuality = 0.7;
      markerArea.addEventListener("beforeclose", (event) => {
        setEditorView(true);
      });

      markerArea.addEventListener("render", (event) => {
        setEditorView(true);
      });

      markerArea.addEventListener("show", (event) => {
        setEditorView(false);
      });
      markerArea.availableMarkerTypes = markersArray(markerjs2);

      markerArea.settings.displayMode = "popup";

      // launch marker.js
      markerArea.show();
    }
  };

  const getImageURL = () => {
    const url = replaceByStorageURL(props?.selectedSubmittedImage);
    getBase64(url).then((res) => {
      setImageUrl(
        `data:image/${props?.selectedSubmittedImage
          .split(".")
          .pop()};base64,${res}`
      );
      setQualityImage(url);
      setImageLoader((prev) => false);
    });
  };

  const closeModal = () => {
    setImageUrl("");
    props.onHide();
    resetRotateState();
  };

  const imageLoaded = () => {
    setImageLoader(!imageLoader);
  };

  const handleRotateImage = () => {
    setRotatedScale(1);
    setImageRotate(imageRotate + 90);
  };

  const resetRotateState = () => {
    setRotatedScale(1);
    setImageRotate(0);
  };

  const handleImageClick = () => {
    window.open(qualityImage, "_blank");
  };

  const handleImageHandleBtn = () => {
    if (props?.imgEditorLoading) {
      return;
    }
    const data = {
      src: imgRef?.current?.src,
      callback: props.onHide,
    };
    props?.handleImageEditBtn(data);
  };

  useEffect(() => {
    if (props?.show) {
      imageLoaded();
      getImageURL();
    }
  }, [props?.show]);

  return (
    <Modal
      show={props?.show}
      onHide={closeModal}
      centered
      dialogClassName={styles.modal_container}
      className={styles.modal_container}
      className={`${styles.modal_container} ${
        !editorView ? "notShow" : "show-this-modal"
      }`}
    >
      <Modal.Body className={styles.fill_screen_image_modal_body}>
        <div className={styles.container_dialog_body}>
          {imageLoader ? (
            <div>
              <Spinner animation="border" variant="success" />
            </div>
          ) : (
            <>
              {props?.showImageAsEditorMode ? (
                <div className={styles.edit_mode_txt}>
                  Click image to view editor mode
                </div>
              ) : (
                <></>
              )}
              <div className={styles.image_icons}>
                <img
                  className={`${styles.full_screen_icons} ${styles.rotate_icon}`}
                  src={require("../../../assets/icons/rotate.png").default}
                  alt="icon"
                  onClick={handleRotateImage}
                />
                <img
                  className={`${styles.full_screen_icons} ${styles.close_icon}`}
                  src={
                    require("../../../assets/icons/cross_full_screen.png")
                      .default
                  }
                  alt="icon"
                  onClick={closeModal}
                />
              </div>
              <div
                className={
                  !showUpdateImgBtn
                    ? styles.image_container
                    : styles.image_container_btn_submit
                }
              >
                {props?.showImageAsEditorMode ? (
                  <>
                    {imageUrl && props?.show ? (
                      <div className={styles.modal_image_magnifier}>
                        <img
                          ref={imgRef}
                          src={imageUrl}
                          alt="iamge_url"
                          onClick={showMarkerArea}
                          width="100%"
                          height="100%"
                          className={styles.modal_image}
                          style={
                            imageUrl
                              ? {
                                  borderRadius: "8px",
                                  transformOrigin: "center",
                                  transform: `rotate(${imageRotate}deg) scale(${rotatedScale})`,
                                  transition: "all 0.4s ease 0s",
                                }
                              : {}
                          }
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    {imageUrl && props?.show ? (
                      <Magnifier
                        src={qualityImage}
                        onClick={handleImageClick}
                        alt="iamge_url"
                        className={styles.modal_image_magnifier}
                        style={
                          imageUrl
                            ? {
                                borderRadius: "8px",
                                transformOrigin: "center",
                                transform: `rotate(${imageRotate}deg) scale(${rotatedScale})`,
                                transition: "all 0.4s ease 0s",
                              }
                            : {}
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
              {showUpdateImgBtn ? (
                <div>
                  <Button
                    onClick={handleImageHandleBtn}
                    className={styles.submit_btn}
                    size="sm"
                  >
                    {props?.imgEditorLoading ? (
                      <Spinner animation="border" variant="success" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FullSizeImageModal;
