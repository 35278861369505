import React, { useState } from "react";

import { replaceByStorageURL } from "../../../../shared/helpers";

import EmptyListIndicator from "./EmptyListIndicator";
import ImageBox from "../../../../components/ImageBox/ImageBox";
import ImageBoxApproved from "../../../../components/ImageBoxApproved/ImageBoxApproved";
import ImageBoxInstructional from "../../../../components/ImageBoxInstructional/ImageBoxInstructional";
import ScrollBar from "react-perfect-scrollbar";
import { Button, Spinner } from "react-bootstrap";
import CustomTooltip from "main/components/Tooltip/Tooltip";
import { roomActions } from "main/shared/constants";
import { handleApproveRoomApi } from "services/ObservationService";

export const ObservationRightSide = (props) => {
  const {
    userInfo,
    RoomName,
    selectedRoomId,
    setConditionalViewer,
    setselectedSubmittedImageIndex,
    setselectedSubmittedImageModal,
    setselectedSubmittedImageNumber,
    setselectedSubmittedImageModalView,
    setselectedInstructImageModalView,
    setselectedInstructImageNumber,
    setselectedInstructImageIndex,
    inspectionDetails,
    photosLoader,
    selectedTask,
    setselectedTask,
    selectedTaskIndex,
    selectedTaskNumber,
    leftSideLoader,
    setaddInstructionalModal,
    loadingImg,
    setloadingImg,
    counterImg,
    setcounterImg,
    setConfirmTestModal,
    checkDisable,
    updateImageFullScreenModal,
    openAddTaskModal,
    setOpenAddTaskModal,
    setrefreshlinkTasks,
    setnotRootLevelRefresh,
    refreshlinkTasks,
  } = props;

  const [approveRoomLoading, setApprovedRoomLoading] = useState(false);

  const selectedRoomApproved = inspectionDetails?.rooms.find(
    (room) => Number(room?.pivot.id) === Number(selectedRoomId)
  );
  const isSelectedRoomApproved =
    selectedRoomApproved?.pivot?.observations_status === "completed";

  const handleFullscreenModal = (image_number) => {
    setselectedSubmittedImageIndex(image_number);
    setselectedSubmittedImageNumber(
      `${selectedTaskNumber}${String.fromCharCode(65 + image_number)}`
    );
    updateImageFullScreenModal();
  };

  const handleApproveBtn = async () => {
    try {
      setApprovedRoomLoading(true);
      const room_pivot_id = selectedRoomApproved?.pivot?.id;
      const data = {
        status: isSelectedRoomApproved
          ? roomActions.UN_APPROVED
          : roomActions.APPROVED,
      };
      const response = await handleApproveRoomApi(room_pivot_id, data);
      setrefreshlinkTasks(true);
      setnotRootLevelRefresh(true);
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setApprovedRoomLoading(false);
      }, 3050);
    }
  };

  const handleApproveBtnDisable = () => {
    return checkDisable;
  };

  const handleApproveRoomLoading = () => {
    return approveRoomLoading || refreshlinkTasks;
  };
  return (
    <ScrollBar
      className={`round-top ${!selectedTask && "d-center-div"}`}
      style={{
        height: "calc(100vh - 140px)",
        overflowY: "auto",
        width: "100%",
        background: "#F9F9F9 0% 0% no-repeat padding-box",
      }}
    >
      {leftSideLoader ? (
        <div
          className="d-center-div"
          style={{
            height: "100%",
          }}
        >
          <Spinner animation="border" variant="success" />
        </div>
      ) : selectedTask ? (
        <div
          className="rightside"
          style={{
            width: "100%",
          }}
        >
          <div className="rightsideinner">
            {}
            {selectedTask && (
              <React.Fragment>
                <div className="observation-details-section">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <span
                        style={{
                          font: "normal normal normal 28px Basis-Grotesque-Pro-Regular",
                        }}
                        className="protocol-top-text"
                      >
                        {`${RoomName}`}
                      </span>
                    </div>
                    <div>
                      {selectedRoomApproved ? (
                        <>
                          {handleApproveRoomLoading() ? (
                            <Spinner animation="border" variant="success" />
                          ) : (
                            <Button
                              onClick={handleApproveBtn}
                              disabled={handleApproveBtnDisable()}
                              className={
                                isSelectedRoomApproved
                                  ? "approve_btn"
                                  : "un_approve_btn"
                              }
                              id="approve_task"
                            >
                              {isSelectedRoomApproved ? (
                                <>
                                  <img
                                    src={
                                      require("assets/icons/check-green.png")
                                        .default
                                    }
                                    alt={"check_image"}
                                    className="check_image"
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    src={
                                      require("assets/icons/check-black.png")
                                        .default
                                    }
                                    alt={"check_image"}
                                    className="check_image"
                                  />
                                </>
                              )}
                              Approve Room
                            </Button>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <h4 className="mt-2">
                    Feature {selectedTaskNumber > 0 && selectedTaskNumber}:{" "}
                    {selectedTask?.name}{" "}
                  </h4>
                </div>

                {selectedTask?.status !== null &&
                  selectedTask?.status !== "pending" && (
                    <div className="observation-details-section">
                      <div className="d-flex align-items-center">
                        <span
                          style={{
                            fontSize: "18px",
                          }}
                        >
                          Submitted Tasks:
                        </span>
                        <div className="d-flex align-items-center ml-auto">
                          {selectedTask?.images.length <= "25" && (
                            <CustomTooltip
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              text={"Add a Task"}
                            >
                              <img
                                className={`constyle crossicon delete-btn `}
                                style={
                                  !checkDisable
                                    ? {
                                        cursor: "pointer",
                                        opacity: "1",
                                      }
                                    : {
                                        cursor: "not-allowed",
                                        opacity: "0.6",
                                      }
                                }
                                onClick={() =>
                                  !checkDisable && setOpenAddTaskModal(true)
                                }
                                src={
                                  require("../../../../../assets/icons/add_a_task.png")
                                    .default
                                }
                                alt="add_feat_btn"
                              />
                            </CustomTooltip>
                          )}
                        </div>
                      </div>
                      <ScrollBar
                        className="mt-2"
                        style={{
                          overflowY: "revert",
                        }}
                      >
                        <div
                          className={`${
                            photosLoader && "observation-detail-list-spinner"
                          } `}
                          style={{
                            maxHeight: "260px",
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            {photosLoader ? (
                              <Spinner animation="border" variant="success" />
                            ) : selectedTask?.images?.length !== 0 ? (
                              selectedTask?.images?.map((image, i) =>
                                selectedTask.status === "approved" ? (
                                  image.image_url !== null && (
                                    <React.Fragment key={`${i}${image.url}`}>
                                      <ImageBoxApproved
                                        key={`photos_${i}`}
                                        src={replaceByStorageURL(
                                          image?.image_url
                                        )}
                                        image_number={i}
                                        selectedTaskIndex={
                                          selectedTaskIndex + 1
                                        }
                                        selectedTask={selectedTask}
                                        loadingImg={loadingImg}
                                        setloadingImg={setloadingImg}
                                        setselectedTask={setselectedTask}
                                        counterImg={counterImg}
                                        setcounterImg={setcounterImg}
                                        setselectedSubmittedImageIndex={
                                          setselectedSubmittedImageIndex
                                        }
                                        setConfirmTestModal={
                                          setConfirmTestModal
                                        }
                                        imageId={image?.id}
                                        setselectedSubmittedImageModal={
                                          setselectedSubmittedImageModal
                                        }
                                        status={image?.status}
                                        setselectedSubmittedImageNumber={
                                          setselectedSubmittedImageNumber
                                        }
                                        setselectedSubmittedImageModalView={
                                          setselectedSubmittedImageModalView
                                        }
                                        selectedTaskStatus={
                                          selectedTask?.status
                                        }
                                        imageData={image}
                                        selectedTaskNumber={selectedTaskNumber}
                                        handleFullscreenModal={
                                          handleFullscreenModal
                                        }
                                      />
                                    </React.Fragment>
                                  )
                                ) : (
                                  <>
                                    <ImageBox
                                      key={`photos_${i}`}
                                      src={replaceByStorageURL(
                                        image?.image_url
                                      )}
                                      image_number={i}
                                      selectedTaskIndex={selectedTaskIndex + 1}
                                      setselectedSubmittedImageIndex={
                                        setselectedSubmittedImageIndex
                                      }
                                      setConfirmTestModal={setConfirmTestModal}
                                      selectedTask={selectedTask}
                                      loadingImg={loadingImg}
                                      setloadingImg={setloadingImg}
                                      counterImg={counterImg}
                                      setcounterImg={setcounterImg}
                                      imageId={image?.id}
                                      setselectedSubmittedImageModal={
                                        setselectedSubmittedImageModal
                                      }
                                      status={image?.status}
                                      setselectedSubmittedImageNumber={
                                        setselectedSubmittedImageNumber
                                      }
                                      setselectedSubmittedImageModalView={
                                        setselectedSubmittedImageModalView
                                      }
                                      selectedTaskStatus={selectedTask?.status}
                                      imageData={image}
                                      selectedTaskNumber={selectedTaskNumber}
                                      handleFullscreenModal={
                                        handleFullscreenModal
                                      }
                                    />
                                  </>
                                )
                              )
                            ) : (
                              <EmptyListIndicator text="No Photos" />
                            )}
                          </div>
                        </div>
                      </ScrollBar>
                    </div>
                  )}
                <div className="observation-details-section">
                  <div className="d-flex align-items-center">
                    <span
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      Tasks:
                    </span>
                  </div>
                  <ScrollBar
                    className="mt-2"
                    style={{
                      overflowX: "revert",
                    }}
                  >
                    <div
                      className={`${
                        photosLoader ? "observation-detail-list-spinner" : ""
                      }`}
                      style={{
                        maxHeight: "260px",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      {photosLoader ? (
                        <Spinner animation="border" variant="success" />
                      ) : selectedTask?.images?.length !== 0 ? (
                        selectedTask?.images?.map(
                          (image, i) =>
                            image?.ph_image_url && (
                              <ImageBoxInstructional
                                setselectedInstructImageModalView={
                                  setselectedInstructImageModalView
                                }
                                setselectedSubmittedImageModal={
                                  setselectedSubmittedImageModal
                                }
                                setselectedInstructImageNumber={
                                  setselectedInstructImageNumber
                                }
                                setselectedInstructImageIndex={
                                  setselectedInstructImageIndex
                                }
                                setselectedSubmittedImageNumber={
                                  setselectedSubmittedImageNumber
                                }
                                setselectedSubmittedImageIndex={
                                  setselectedSubmittedImageIndex
                                }
                                selectedTaskNumber={selectedTaskNumber}
                                inspectionDetails={inspectionDetails}
                                setConditionalViewer={setConditionalViewer}
                                selectedTaskIndex={selectedTaskIndex}
                                selectedTask={selectedTask}
                                image_number={i}
                                user_role={userInfo?.role}
                                key={`photos_${i}`}
                                image_data={image}
                              />
                            )
                        )
                      ) : (
                        <EmptyListIndicator text="No Tasks" />
                      )}
                    </div>
                  </ScrollBar>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      ) : (
        <img
          alt="notfound"
          src={
            require("../../../../../assets/icons/no-task-update.png").default
          }
          width="288px"
        />
      )}
    </ScrollBar>
  );
};
