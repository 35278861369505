import axios from "../../../axios-defaults";
import { RoomCLickTypesEnum, TaskClickTypesEnum } from "main/shared/constants";
import { handleReduxError } from "../../../main/shared/helpers";
import {
  GET_TEST_LIST_FAIL,
  GET_TEST_LIST_REQUEST,
  GET_TEST_LIST_SUCCESS,
  GET_ROOM_LIST_REQUEST,
  GET_ROOM_LIST_FAIL,
  GET_ROOM_LIST_SUCCESS,
  SELECTED_ROOM_LIST,
  SELECTED_ROOM_LIST_ERROR,
  GET_TASK_LIST_REQUEST,
  GET_TASK_LIST_SUCCESS,
  GET_TASK_LIST_FAIL,
  SUBMIT_SELECTED_TASK_WITH_TEST_FAIL,
  SUBMIT_SELECTED_TASK_WITH_TEST_REQUEST,
  SUBMIT_SELECTED_TASK_WITH_TEST_SUCCESS,
} from "../../types/test_recommend/testRecommendTypes";

export const getTestListAction = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_TEST_LIST_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.get(`/v2/tests`, config);

    dispatch({
      type: GET_TEST_LIST_SUCCESS,
      payload: data,
    });

    return data;
  } catch (error) {
    const err = handleReduxError(error);
    dispatch({
      type: GET_TEST_LIST_FAIL,
      payload: err,
    });
    throw Error(err);
  }
};

export const getRoomListAction = (inspection_id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ROOM_LIST_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.get(
      `/v2/inspections/${inspection_id}/rooms?device_type=react`,
      config
    );
    if (data?.length) {
      data.forEach((room) => {
        room.roomUniqueId = room?.pivot?.id || "";
        room.room_pivot_id = room?.pivot?.id || "";
        room.roomUniqueName = room?.pivot?.description || "";
      });
    }
    dispatch({
      type: GET_ROOM_LIST_SUCCESS,
      payload: data,
    });

    return data;
  } catch (error) {
    const err = handleReduxError(error);
    dispatch({
      type: GET_ROOM_LIST_FAIL,
      payload: err,
    });
    throw Error(err);
  }
};

export const handleRoomsSelectedAction =
  (selectedRoomList = []) =>
  async (dispatch, getState) => {
    try {
      let activeFeatureId = "";
      const {
        fetchRoomList: {
          roomList = [],
          selectedRoomsList: allReadySelectedRooms = [],
        },
      } = getState();
      const selectedRoomDetails = [];
      selectedRoomList.forEach((selectedRoom, roomIndex) => {
        let singleDetailRoom = {};
        if (allReadySelectedRooms?.length) {
          singleDetailRoom = allReadySelectedRooms.find(
            (prevSelectedRoom) => prevSelectedRoom?.roomUniqueId === selectedRoom?.value
          );
          if (!singleDetailRoom?.roomUniqueId) {
            singleDetailRoom = roomList.find(
              (allRooms) => allRooms?.roomUniqueId === selectedRoom?.value
            );
            singleDetailRoom.isSelected = false;
            singleDetailRoom?.observations.forEach((feature, featureIndex) => {
              feature.isSelected = false;
              feature.roomId = singleDetailRoom.id;
              feature.roomUniqueId = singleDetailRoom.roomUniqueId;
            });
          }
          selectedRoomDetails.push(singleDetailRoom);
        } else {
          singleDetailRoom = roomList.find(
            (allRooms) => allRooms?.roomUniqueId === selectedRoom?.value
          );
          if (singleDetailRoom) {
            if (singleDetailRoom?.observations?.length) {
              /* ===============  For Default Room Selected   ================ */
              if (roomIndex === 0) {
                singleDetailRoom.isSelected = true;
              } else {
                singleDetailRoom.isSelected = false;
              }
              singleDetailRoom?.observations.forEach(
                (feature, featureIndex) => {
                  /* ===============  For Default Feature Selected   ================ */
                  feature.isAllTaskSelected = false;
                  if (roomIndex === 0 && featureIndex === 0) {
                    feature.isSelected = true;
                    feature.roomId = singleDetailRoom.id;
                    feature.roomUniqueId = singleDetailRoom.roomUniqueId;
                    if (!feature?.tasks?.length) {
                      activeFeatureId = feature?.id;
                    }
                  } else {
                    feature.isSelected = false;
                    feature.roomId = singleDetailRoom.id;
                    feature.roomUniqueId = singleDetailRoom.roomUniqueId;
                  }
                }
              );
            }
            selectedRoomDetails.push(singleDetailRoom);
          }
        }
      });
      dispatch({
        type: SELECTED_ROOM_LIST,
        payload: selectedRoomDetails,
      });
      if (activeFeatureId) {
        dispatch(getTaskListForSelectedFeatureAction(activeFeatureId));
      }
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: SELECTED_ROOM_LIST_ERROR,
        payload: err,
      });
      throw Error(err);
    }
  };

export const handleSingleRoomSelectedAction =
  (type, roomUniqueId = "") =>
  async (dispatch, getState) => {
    try {
      let activeFeatureId = "";
      const {
        fetchRoomList: { selectedRoomsList = [], currentSelectedRoom },
      } = getState();
      const cloneSelectedRoomsList = JSON.parse(
        JSON.stringify(selectedRoomsList)
      );

      if (cloneSelectedRoomsList?.length) {
        cloneSelectedRoomsList.forEach((room) => {
          if (type === RoomCLickTypesEnum.OPEN && room?.roomUniqueId === roomUniqueId) {
            room.isSelected = true;
            if (room?.roomUniqueId !== currentSelectedRoom?.roomUniqueId) {
              room?.observations.forEach((feature, featureIndex) => {
                if (featureIndex === 0) {
                  if (!feature?.tasks?.length) {
                    activeFeatureId = feature?.id;
                  }
                  feature.isSelected = true;
                } else {
                  feature.isSelected = false;
                }
              });
            }
          } else {
            room.isSelected = false;
            if (roomUniqueId !== currentSelectedRoom?.roomUniqueId) {
              room?.observations.forEach((feature) => {
                feature.isSelected = false;
              });
            }
          }
        });
      }
      dispatch({
        type: SELECTED_ROOM_LIST,
        payload: cloneSelectedRoomsList,
      });
      if (activeFeatureId) {
        dispatch(getTaskListForSelectedFeatureAction(activeFeatureId));
      }
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: SELECTED_ROOM_LIST_ERROR,
        payload: err,
      });
      throw Error(err);
    }
  };

export const handleFeatureSelectedInRoomAction =
  (type, roomUniqueId = "", featureId = "") =>
  async (dispatch, getState) => {
    try {
      let activeFeatureId = "";
      const {
        fetchRoomList: { selectedRoomsList = [] },
      } = getState();
      const cloneSelectedRoomsList = JSON.parse(
        JSON.stringify(selectedRoomsList)
      );
      const selectedRoomDetails = cloneSelectedRoomsList.find(
        (room) => room?.roomUniqueId === roomUniqueId
      );
      if (selectedRoomDetails?.observations?.length) {
        selectedRoomDetails?.observations.forEach((feature) => {
          if (feature?.id === featureId) {
            feature.isSelected = type;
            if (!feature?.tasks?.length) {
              activeFeatureId = feature?.id;
            }
          } else {
            feature.isSelected = false;
          }
        });
      }
      dispatch({
        type: SELECTED_ROOM_LIST,
        payload: cloneSelectedRoomsList,
      });
      if (activeFeatureId) {
        dispatch(getTaskListForSelectedFeatureAction(activeFeatureId));
      }
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: SELECTED_ROOM_LIST_ERROR,
        payload: err,
      });
      throw Error(err);
    }
  };

export const getTaskListForSelectedFeatureAction =
  (featureId = "") =>
  async (dispatch, getState) => {
    try {
      const {
        fetchRoomList: { selectedRoomsList = [] },
      } = getState();
      const cloneSelectedRoomsList = JSON.parse(
        JSON.stringify(selectedRoomsList)
      );
      dispatch({
        type: GET_TASK_LIST_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data = [] } = await axios.get(
        `/v2/observation/${featureId}/images`,
        config
      );

      if (data?.length) {
        data.forEach((task) => {
          task.isSelected = false;
        });
      }

      if (cloneSelectedRoomsList?.length) {
        cloneSelectedRoomsList.forEach((room) => {
          if (room.isSelected) {
            room?.observations.forEach((feature) => {
              if (data?.length) {
                if (feature?.id === data[0]?.observation_id) {
                  feature.tasks = data;
                }
              }
            });
          }
        });
      }

      dispatch({
        type: SELECTED_ROOM_LIST,
        payload: cloneSelectedRoomsList,
      });
      dispatch({
        type: GET_TASK_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: GET_TASK_LIST_FAIL,
        payload: err,
      });
      throw Error(err);
    }
  };

export const handleAllSelectTaskAction =
  (type, roomUniqueId = "", featureId = "") =>
  async (dispatch, getState) => {
    try {
      const {
        fetchRoomList: { selectedRoomsList = [] },
      } = getState();
      const cloneSelectedRoomsList = JSON.parse(
        JSON.stringify(selectedRoomsList)
      );
      dispatch({
        type: GET_TASK_LIST_REQUEST,
      });

      if (cloneSelectedRoomsList?.length) {
        const selectedRoomIndex = cloneSelectedRoomsList.findIndex(
          (room) => room?.roomUniqueId === roomUniqueId
        );
        if (cloneSelectedRoomsList[selectedRoomIndex]?.observations?.length) {
          const selectedFeatureIndex = cloneSelectedRoomsList[
            selectedRoomIndex
          ]?.observations.findIndex((feature) => feature?.id === featureId);
          cloneSelectedRoomsList[selectedRoomIndex].observations[
            selectedFeatureIndex
          ].isAllTaskSelected = type;
          cloneSelectedRoomsList[selectedRoomIndex].observations[
            selectedFeatureIndex
          ]?.tasks.forEach((task) => {
            task.isSelected = type;
          });
        }
      }
      dispatch({
        type: SELECTED_ROOM_LIST,
        payload: cloneSelectedRoomsList,
      });
      dispatch({
        type: GET_TASK_LIST_SUCCESS,
      });
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: GET_TASK_LIST_FAIL,
        payload: err,
      });
      throw Error(err);
    }
  };

export const handleSingleTaskSelectionAction =
  (type, roomUniqueId = "", featureId = "", taskId = "") =>
  async (dispatch, getState) => {
    try {
      const {
        fetchRoomList: { selectedRoomsList = [] },
      } = getState();
      const cloneSelectedRoomsList = JSON.parse(
        JSON.stringify(selectedRoomsList)
      );
      dispatch({
        type: GET_TASK_LIST_REQUEST,
      });

      if (cloneSelectedRoomsList?.length) {
        const selectedRoomIndex = cloneSelectedRoomsList.findIndex(
          (room) => room?.roomUniqueId === roomUniqueId
        );
        if (cloneSelectedRoomsList[selectedRoomIndex]?.observations?.length) {
          const selectedFeatureIndex = cloneSelectedRoomsList[
            selectedRoomIndex
          ]?.observations.findIndex((feature) => feature?.id === featureId);
          const selectedTaskIndex = cloneSelectedRoomsList[
            selectedRoomIndex
          ].observations[selectedFeatureIndex].tasks.findIndex(
            (task) => task?.id === taskId
          );
          if (type === TaskClickTypesEnum.NOT_SELECT) {
            cloneSelectedRoomsList[selectedRoomIndex].observations[
              selectedFeatureIndex
            ].isAllTaskSelected = false;
            cloneSelectedRoomsList[selectedRoomIndex].observations[
              selectedFeatureIndex
            ].tasks[selectedTaskIndex].isSelected = false;
          } else {
            cloneSelectedRoomsList[selectedRoomIndex].observations[
              selectedFeatureIndex
            ].tasks[selectedTaskIndex].isSelected = true;
            const checkAnyFalseExist = cloneSelectedRoomsList[
              selectedRoomIndex
            ].observations[selectedFeatureIndex].tasks.find(
              (task) => task.isSelected === false && task.id !== taskId
            );
            if (!checkAnyFalseExist) {
              cloneSelectedRoomsList[selectedRoomIndex].observations[
                selectedFeatureIndex
              ].isAllTaskSelected = true;
            }
          }
        }
      }
      dispatch({
        type: SELECTED_ROOM_LIST,
        payload: cloneSelectedRoomsList,
      });
      dispatch({
        type: GET_TASK_LIST_SUCCESS,
      });
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: GET_TASK_LIST_FAIL,
        payload: err,
      });
      throw Error(err);
    }
  };

export const handleSubmitSelectedTaskWithTestAction =
  (inspectionId, bodyData = {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SUBMIT_SELECTED_TASK_WITH_TEST_REQUEST,
      });
      const { data } = await axios.post(
        `/v2/inspections/${inspectionId}/test`,
        bodyData
      );
      dispatch({
        type: SUBMIT_SELECTED_TASK_WITH_TEST_SUCCESS,
      });
      return data;
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: SUBMIT_SELECTED_TASK_WITH_TEST_FAIL,
        payload: err,
      });
      throw Error(err);
    }
  };
