export const GET_TEST_LIST_REQUEST = "GET_TEST_LIST_REQUEST";
export const GET_TEST_LIST_SUCCESS = "GET_TEST_LIST_SUCCESS";
export const GET_TEST_LIST_FAIL = "GET_TEST_LIST_FAIL";
export const GET_TEST_LIST_RESET = "GET_TEST_LIST_RESET";
export const SELECTED_TEST = "SELECTED_TEST";
export const SELECTED_TEST_LIST_RESET = "SELECTED_TEST_LIST_RESET";


export const GET_ROOM_LIST_REQUEST = "GET_ROOM_LIST_REQUEST";
export const GET_ROOM_LIST_SUCCESS = "GET_ROOM_LIST_SUCCESS";
export const GET_ROOM_LIST_FAIL = "GET_ROOM_LIST_FAIL";
export const GET_ROOM_LIST_RESET = "GET_ROOM_LIST_RESET";

export const SELECTED_ROOM_LIST = "SELECTED_ROOM_LIST";
export const SELECTED_ROOM_LIST_ERROR = "SELECTED_ROOM_LIST_ERROR";
export const SELECTED_ROOM_LIST_RESET = "SELECTED_ROOM_LIST_RESET";

export const GET_TASK_LIST_REQUEST = "GET_TASK_LIST_REQUEST";
export const GET_TASK_LIST_SUCCESS = "GET_TASK_LIST_SUCCESS";
export const GET_TASK_LIST_FAIL = "GET_TASK_LIST_FAIL";
export const GET_TASK_LIST_RESET = "GET_TASK_LIST_RESET";


export const SUBMIT_SELECTED_TASK_WITH_TEST_REQUEST = "SUBMIT_SELECTED_TASK_WITH_TEST_REQUEST";
export const SUBMIT_SELECTED_TASK_WITH_TEST_SUCCESS = "SUBMIT_SELECTED_TASK_WITH_TEST_SUCCESS";
export const SUBMIT_SELECTED_TASK_WITH_TEST_FAIL = "SUBMIT_SELECTED_TASK_WITH_TEST_FAIL";
