import React from "react";
import styles from "./test_left_side.module.css";
import SelectDropDown from "../../../../../shared_components/SelectDropdown/SelectDropDown";
import { convertLabelIntoValueAndOption, showToast } from "main/shared/helpers";
import RomFeatureListing from "./RomFeatureListing/RomFeatureListing";
import { useDispatch, useSelector } from "react-redux";
import {
  getRoomListAction,
  getTestListAction,
  handleRoomsSelectedAction,
} from "store/actions/test_recommend/testRecommendAction";
import ConfirmationModal from "main/components/ConfirmationModal/ConfirmationModal";
import { ReactSelectActionTypes } from "main/shared/constants";
import {
  SELECTED_ROOM_LIST_RESET,
  SELECTED_TEST,
} from "store/types/test_recommend/testRecommendTypes";
import { useParams } from "react-router-dom";

const TestLeftSide = (props) => {
  const { insp_id } = useParams();
  const dispatch = useDispatch();
  const fetchTestList = useSelector((state) => state.fetchTestList);
  const { testList } = fetchTestList;

  const fetchRoomList = useSelector((state) => state.fetchRoomList);
  const {
    roomList,
    selectedRoomsList = [],
    currentSelectedRoom,
  } = fetchRoomList;

  const handleRoomDeletion = () => {
    if (Array.isArray(props.removeValues)) {
      props.setRoomSearchValue([]);
      resetAllRoomDetails();
    } else {
      /* ======== Currently This is not working ======== */
      props.setRoomSearchValue(
        roomSearchValue.filter((val) => val.value !== props.removeValues.value)
      );
    }
    props.setRemoveValues("");
    props.setBackConfirmationModal(false);
  };

  const resetAllRoomDetails = () => {
    dispatch({
      type: SELECTED_ROOM_LIST_RESET,
    });
  };

  const handleOnTestSelection = (value) => {
    props.setTestSearchValue(value);
    dispatch({
      type: SELECTED_TEST,
      payload: value,
    });
  };

  const handleOnRoomSelection = (value, event) => {
    if (
      event?.action === ReactSelectActionTypes.CLEAR_ALL ||
      event?.action === ReactSelectActionTypes.REMOVE_SINGLE_VALUE
    ) {
      if (event?.removedValues?.length) {
        props.setRemoveValues(event?.removedValues);
      } else {
        props.setRemoveValues(event?.removedValue);
      }
      props.setBackConfirmationModal(true);
      return;
    }
    if (Array.isArray(value) && value?.length > 5) {
      showToast("Maximum room selection is 5");
    } else {
      props.setRoomSearchValue(value);
    }
  };

  const handleRoomDetailsClick = async () => {
    try {
      await dispatch(handleRoomsSelectedAction(props.roomSearchValue));
    } catch (error) {
      console.log(error);
    }
  };
  const fetchTaskRooms = async (search, loadedOptions) => {
    try {
      if (search && roomList?.length) {
        const options = convertLabelIntoValueAndOption(
          roomList,
          "roomUniqueId",
          "roomUniqueName"
        );
        const filterOption = options.filter((i) =>
          i.label.toLowerCase().includes(search.toLowerCase())
        );
        return {
          options: filterOption,
          hasMore: false,
        };
      } else {
        const roomList = await dispatch(getRoomListAction(insp_id));
        const options = convertLabelIntoValueAndOption(
          roomList,
          "roomUniqueId",
          "roomUniqueName"
        );
        return {
          options: options,
          hasMore: false,
        };
      }
    } catch (error) {
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const fetchTests = async (search, loadedOptions) => {
    try {
      if (search && testList?.length) {
        const options = convertLabelIntoValueAndOption(testList, "id", "name");
        const filterOption = options.filter((i) =>
          i.label.toLowerCase().includes(search.toLowerCase())
        );
        return {
          options: filterOption,
          hasMore: false,
        };
      } else {
        const testList = await dispatch(getTestListAction());
        const options = convertLabelIntoValueAndOption(testList, "id", "name");
        return {
          options: options,
          hasMore: false,
        };
      }
    } catch (error) {
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  return (
    <div className={styles.container}>
      <h4 className={styles.test_heading_v4}>Recommend a Test</h4>
      <div>
        <div className={styles.testDropdown}>
          <SelectDropDown
            placeholder="Select Test"
            loadOptions={fetchTests}
            searchValue={props.testSearchValue}
            handleOnChange={handleOnTestSelection}
            isSearchable={true}
          />
        </div>
        <div className={styles.roomDropdown}>
          <SelectDropDown
            isMulti={true}
            closeMenuOnSelect={false}
            placeholder="Select Rooms"
            loadOptions={fetchTaskRooms}
            searchValue={props.roomSearchValue}
            handleOnChange={handleOnRoomSelection}
            onMenuClose={handleRoomDetailsClick}
            isSearchable={true}
          />
        </div>
        <div>
          <textarea
            placeholder="Location"
            maxLength="400"
            className={styles.sample_name_input_field}
            value={props.testNotes}
            onChange={(e) => props.setTestNotes(e.target.value)}
          />
        </div>
      </div>
      <div>
        {selectedRoomsList && selectedRoomsList?.length ? (
          selectedRoomsList.map((room, index) => {
            return (
              <div key={index}>
                <RomFeatureListing room={room} key={index} />
                <div
                  className={` ${
                    selectedRoomsList?.length - 1 !== index
                      ? styles.divider
                      : styles.margin_from_bottom
                  }  `}
                ></div>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
      <ConfirmationModal
        show={props.backConfirmationModal}
        onHide={() => props.setBackConfirmationModal(false)}
        disabled="false"
        imgSrc={require("assets/icons/delete.png").default}
        helperText={`Remove ${
          Array.isArray(props.removeValues) ? "rooms" : "room"
        } may result in data loss. Are you sure you want to proceed?`}
        onSave={handleRoomDeletion}
      />
    </div>
  );
};

export default TestLeftSide;
