import React from "react";
import styles from "./feature_listing.module.css";
import { FeatureClickTypesEnum } from "main/shared/constants";

const FeatureListing = ({
  featureList = [],
  handleSelectedFeature = () => {},
  displayAsACheckbox = false,
}) => {
  return (
    <div>
      <ul className={styles.feature_list_container}>
        <div className={styles.feature_ul}>
          {featureList?.length ? (
            featureList.map((roomFeature, index) => {
              return (
                <li
                  className={`${styles.feature_list_li}  ${
                    roomFeature?.isSelected
                      ? styles.feature_list_li_selected
                      : {}
                  }`}
                  key={roomFeature?.id}
                >
                  <div className={styles.feature_list_li_container}>
                    {displayAsACheckbox ? (
                      <div className={styles.form_group}>
                        <input
                          checked={roomFeature?.isSelected}
                          type="checkbox"
                          id={`checkbox${roomFeature?.name}${roomFeature?.id}${index}${roomFeature?.roomUniqueId}`}
                          onChange={(e) =>
                            handleSelectedFeature(
                              e,
                              roomFeature?.roomUniqueId,
                              roomFeature?.id
                            )
                          }
                        />
                        <label
                          htmlFor={`checkbox${roomFeature?.name}${roomFeature?.id}${index}${roomFeature?.roomUniqueId}`}
                        ></label>
                      </div>
                    ) : (
                      <div>
                        {roomFeature?.isSelected ? (
                          <img
                            src={require("assets/icons/radio@2x.png").default}
                            alt={roomFeature?.name}
                            className={styles.radioImage}
                          />
                        ) : (
                          <img
                            src={require("assets/icons/radio1@2x.png").default}
                            alt={roomFeature?.name}
                            className={styles.radioImage}
                            onClick={() =>
                              handleSelectedFeature(
                                FeatureClickTypesEnum.SELECT,
                                roomFeature?.roomUniqueId,
                                roomFeature?.id
                              )
                            }
                          />
                        )}
                      </div>
                    )}
                    <div className={styles.listing_name}>{roomFeature?.name}</div>
                  </div>
                </li>
              );
            })
          ) : (
            <></>
          )}
        </div>
      </ul>
    </div>
  );
};

export default FeatureListing;
