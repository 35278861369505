import {
  GET_TEST_LIST_FAIL,
  GET_TEST_LIST_REQUEST,
  GET_TEST_LIST_RESET,
  GET_TEST_LIST_SUCCESS,
  GET_ROOM_LIST_REQUEST,
  GET_ROOM_LIST_FAIL,
  GET_ROOM_LIST_SUCCESS,
  GET_ROOM_LIST_RESET,
  SELECTED_ROOM_LIST_ERROR,
  SELECTED_ROOM_LIST,
  SELECTED_ROOM_LIST_RESET,
  GET_TASK_LIST_REQUEST,
  GET_TASK_LIST_FAIL,
  GET_TASK_LIST_SUCCESS,
  SELECTED_TEST,
  SELECTED_TEST_LIST_RESET,
  SUBMIT_SELECTED_TASK_WITH_TEST_FAIL,
  SUBMIT_SELECTED_TASK_WITH_TEST_REQUEST,
  SUBMIT_SELECTED_TASK_WITH_TEST_SUCCESS,
} from "../../types/test_recommend/testRecommendTypes";

const testListInitialState = {
  loading: false,
  testList: [],
  currentSelectedTest: null,
  error: "",
};

export const fetchTestListReducers = (state = testListInitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TEST_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_TEST_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        testList: payload,
        error: "",
      };

    case SELECTED_TEST:
      return {
        ...state,
        loading: false,
        currentSelectedTest: payload,
        error: "",
      };

    case GET_TEST_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case SELECTED_TEST_LIST_RESET:
      return {
        ...state,
        loading: false,
        currentSelectedTest: null,
        error: "",
      };

    case GET_TEST_LIST_RESET:
      return {
        loading: false,
        currentSelectedTest: null,
        testList: [],
        error: "",
      };

    default:
      return state;
  }
};

const roomListInitialState = {
  loading: false,
  taskLoading: false,
  roomList: [],
  selectedRoomsList: [],
  currentSelectedRoom: {},
  error: "",
};

export const fetchRoomListReducers = (state = roomListInitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ROOM_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_TASK_LIST_REQUEST:
      return {
        ...state,
        taskLoading: true,
      };

    case GET_ROOM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        roomList: payload,
        error: "",
      };

    case SELECTED_ROOM_LIST:
      let selectedRoom = {};
      payload.forEach((room) => {
        room?.observations.forEach((feature) => {
          if (feature?.isSelected === true) {
            selectedRoom = JSON.parse(JSON.stringify(room));
          }
        });
      });

      return {
        ...state,
        loading: false,
        selectedRoomsList: payload,
        currentSelectedRoom: selectedRoom,
        error: "",
      };

    case GET_TASK_LIST_SUCCESS:
      return {
        ...state,
        taskLoading: false,
        error: "",
      };

    case GET_TASK_LIST_FAIL:
      return {
        ...state,
        taskLoading: false,
        error: payload,
      };

    case SELECTED_ROOM_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case GET_ROOM_LIST_FAIL:
      return {
        ...state,
        loading: false,
        taskLoading: false,
        error: payload,
      };

    case SELECTED_ROOM_LIST_RESET:
      return {
        ...state,
        loading: false,
        taskLoading: false,
        error: "",
        selectedRoomsList: [],
        currentSelectedRoom: {},
      };

    case GET_ROOM_LIST_RESET:
      return {
        loading: false,
        taskLoading: false,
        error: "",
        selectedRoomsList: [],
        currentSelectedRoom: {},
        roomList: [],
      };

    default:
      return state;
  }
};

const submitSelectedRoomWithTestInitialState = {
  loading: false,
  error: "",
};

export const submitSelectedRoomWithTestReducers = (
  state = submitSelectedRoomWithTestInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case SUBMIT_SELECTED_TASK_WITH_TEST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SUBMIT_SELECTED_TASK_WITH_TEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case SUBMIT_SELECTED_TASK_WITH_TEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
