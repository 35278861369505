export const GET_VIEW_RECOMMENDED_ROOM_LIST_REQUEST = "GET_VIEW_RECOMMENDED_ROOM_LIST_REQUEST";
export const GET_VIEW_RECOMMENDED_ROOM_LIST_SUCCESS = "GET_VIEW_RECOMMENDED_ROOM_LIST_SUCCESS";
export const GET_VIEW_RECOMMENDED_ROOM_LIST_FAIL = "GET_VIEW_RECOMMENDED_ROOM_LIST_FAIL";
export const GET_VIEW_RECOMMENDED_ROOM_LIST_RESET = "GET_VIEW_RECOMMENDED_ROOM_LIST_RESET";
export const VIEW_RECOMMENDED_SELECTED_ROOM_LIST_RESET = "VIEW_RECOMMENDED_SELECTED_ROOM_LIST_RESET";

export const GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_REQUEST = "GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_REQUEST";
export const GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_SUCCESS = "GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_SUCCESS";
export const GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_FAIL = "GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_FAIL";
export const GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_RESET = "GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_RESET";

export const GET_VIEW_REC_TEST_LIST_BEHAVE_OF_ROOMS_REQUEST = "GET_VIEW_REC_TEST_LIST_BEHAVE_OF_ROOMS_REQUEST";
export const GET_VIEW_REC_TEST_LIST_BEHAVE_OF_ROOMS_SUCCESS = "GET_VIEW_REC_TEST_LIST_BEHAVE_OF_ROOMS_SUCCESS";
export const GET_VIEW_REC_TEST_LIST_BEHAVE_OF_ROOMS_FAIL = "GET_VIEW_REC_TEST_LIST_BEHAVE_OF_ROOMS_FAIL";
export const GET_VIEW_REC_TEST_LIST_BEHAVE_OF_ROOMS_RESET = "GET_VIEW_REC_TEST_LIST_BEHAVE_OF_ROOMS_RESET";

export const GET_VIEW_RECOMMENDED_ROOM_TASK_EDITOR_REQUEST = "GET_VIEW_RECOMMENDED_ROOM_TASK_EDITOR_REQUEST";
export const GET_VIEW_RECOMMENDED_ROOM_TASK_EDITOR_SUCCESS = "GET_VIEW_RECOMMENDED_ROOM_TASK_EDITOR_SUCCESS";
export const GET_VIEW_RECOMMENDED_ROOM_TASK_EDITOR_FAIL = "GET_VIEW_RECOMMENDED_ROOM_TASK_EDITOR_FAIL";
export const GET_VIEW_RECOMMENDED_ROOM_TASK_EDITOR_RESET = "GET_VIEW_RECOMMENDED_ROOM_TASK_EDITOR_RESET";

export const GET_VIEW_RECOMMENDED_ROOM_APPROVE_CLIENT_REQUEST = "GET_VIEW_RECOMMENDED_ROOM_APPROVE_CLIENT_REQUEST";
export const GET_VIEW_RECOMMENDED_ROOM_APPROVE_CLIENT_SUCCESS = "GET_VIEW_RECOMMENDED_ROOM_APPROVE_CLIENT_SUCCESS";
export const GET_VIEW_RECOMMENDED_ROOM_APPROVE_CLIENT_FAIL = "GET_VIEW_RECOMMENDED_ROOM_APPROVE_CLIENT_FAIL";
export const GET_VIEW_RECOMMENDED_ROOM_APPROVE_CLIENT_RESET = "GET_VIEW_RECOMMENDED_ROOM_APPROVE_CLIENT_RESET";


export const GET_VIEW_RECOMMENDED_TEST_LIST_REQUEST = "GET_VIEW_RECOMMENDED_TEST_LIST_REQUEST";
export const GET_VIEW_RECOMMENDED_TEST_LIST_SUCCESS = "GET_VIEW_RECOMMENDED_TEST_LIST_SUCCESS";
export const GET_VIEW_RECOMMENDED_TEST_LIST_FAIL = "GET_VIEW_RECOMMENDED_TEST_LIST_FAIL";
export const GET_VIEW_RECOMMENDED_TEST_LIST_RESET = "GET_VIEW_RECOMMENDED_TEST_LIST_RESET";
export const VIEW_RECOMMENDED_SELECTED_TEST_LIST_RESET = "VIEW_RECOMMENDED_SELECTED_TEST_LIST_RESET";

export const GET_VIEW_REC_ROOM_LIST_BEHAVE_OF_TESTS_REQUEST = "GET_VIEW_REC_ROOM_LIST_BEHAVE_OF_TESTS_REQUEST";
export const GET_VIEW_REC_ROOM_LIST_BEHAVE_OF_TESTS_SUCCESS = "GET_VIEW_REC_ROOM_LIST_BEHAVE_OF_TESTS_SUCCESS";
export const GET_VIEW_REC_ROOM_LIST_BEHAVE_OF_TESTS_FAIL = "GET_VIEW_REC_ROOM_LIST_BEHAVE_OF_TESTS_FAIL";
export const GET_VIEW_REC_ROOM_LIST_BEHAVE_OF_TESTS_RESET = "GET_VIEW_REC_ROOM_LIST_BEHAVE_OF_TESTS_RESET";

export const GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_REQUEST = "GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_REQUEST";
export const GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_SUCCESS = "GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_SUCCESS";
export const GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_FAIL = "GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_FAIL";
export const GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_RESET = "GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_RESET";

export const GET_VIEW_RECOMMENDED_TEST_TASK_EDITOR_REQUEST = "GET_VIEW_RECOMMENDED_TEST_TASK_EDITOR_REQUEST";
export const GET_VIEW_RECOMMENDED_TEST_TASK_EDITOR_SUCCESS = "GET_VIEW_RECOMMENDED_TEST_TASK_EDITOR_SUCCESS";
export const GET_VIEW_RECOMMENDED_TEST_TASK_EDITOR_FAIL = "GET_VIEW_RECOMMENDED_TEST_TASK_EDITOR_FAIL";
export const GET_VIEW_RECOMMENDED_TEST_TASK_EDITOR_RESET = "GET_VIEW_RECOMMENDED_TEST_TASK_EDITOR_RESET";

export const GET_VIEW_RECOMMENDED_TEST_APPROVE_CLIENT_REQUEST = "GET_VIEW_RECOMMENDED_TEST_APPROVE_CLIENT_REQUEST";
export const GET_VIEW_RECOMMENDED_TEST_APPROVE_CLIENT_SUCCESS = "GET_VIEW_RECOMMENDED_TEST_APPROVE_CLIENT_SUCCESS";
export const GET_VIEW_RECOMMENDED_TEST_APPROVE_CLIENT_FAIL = "GET_VIEW_RECOMMENDED_TEST_APPROVE_CLIENT_FAIL";
export const GET_VIEW_RECOMMENDED_TEST_APPROVE_CLIENT_RESET = "GET_VIEW_RECOMMENDED_TEST_APPROVE_CLIENT_RESET";
