import React from "react";
import styles from "./custom_filter_popover.module.css";
import stylesMax from "./custom_filter_popover_more.module.css";
import { OverlayTrigger, Popover } from "react-bootstrap";

const CustomFilterPopover = ({
  size = "lg",
  filterOption = [],
  handleOnClick = () => {},
  selectedOption = [],
  closeOnSelect = false,
}) => {
  const [show, setShow] = React.useState(false);

  const getStyleClassSize = () => {
    if (size === "lg") {
      return stylesMax;
    } else {
      return styles;
    }
  };

  const getCssClasses = (index) => {
    return `${
      filterOption.length % 2 === 0
        ? index + 1 === Math.ceil(filterOption.length / 2)
          ? getStyleClassSize().sec_last_column
          : index === filterOption.length - 1
          ? getStyleClassSize().last_column
          : {}
        : index + 1 === Math.ceil(filterOption.length / 2)
        ? getStyleClassSize().sec_last_column
        : {}
    }  
    
    ${index < filterOption.length / 2 ? getStyleClassSize().evenList : {}}`;
  };

  const handleToggle = () => {
    setShow((prev) => !prev);
  };

  const handleOnSelect = (option) => {
    handleOnClick(option);
    if (closeOnSelect) {
      handleToggle();
    }
  };

  const popover = (
    <Popover
      id="popover-contained"
      className={getStyleClassSize().popover_container}
    >
      <Popover.Content className={getStyleClassSize().popover_filters}>
        <ul>
          {filterOption?.length ? (
            filterOption.map((option, index) => {
              return (
                <li
                  tabIndex={index}
                  className={`${getCssClasses(index)} ${
                    selectedOption.indexOf(option?.value) !== -1
                      ? getStyleClassSize().is_selected
                      : {}
                  }`}
                  id={option?.value}
                  key={option?.value}
                  onClick={() => handleOnSelect(option)}
                >
                  <div>{option?.label}</div>
                </li>
              );
            })
          ) : (
            <></>
          )}
        </ul>
      </Popover.Content>
    </Popover>
  );

  return (
    <div className={getStyleClassSize().overlay_container}>
      <OverlayTrigger
        trigger="click"
        rootClose
        overlay={popover}
        placement="bottom-end"
        onToggle={handleToggle}
        show={show}
      >
        <div
          className={getStyleClassSize().filter_icon_container}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            e.nativeEvent.stopImmediatePropagation();
          }}
        >
          <img
            src={require(`assets/icons/filter@2x.png`).default}
            width="100%"
          />
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default CustomFilterPopover;
