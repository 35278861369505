import React from "react";
import styles from "./test_left_side.module.css";
import CustomFilterPopover from "main/shared_components/CustomFilterPoppver/CustomFilterPopover";
import RoomView from "./RoomView/RoomView";
import {
  ViewRecommendedToastMsg,
  approvedView,
  filterOption,
  getDefaultApproveView,
  viewTestAndRoomsQueryTabName,
  viewTestAndRoomsTabs,
} from "main/shared/constants";
import { Tab, Tabs } from "react-bootstrap";
import TestView from "./TestView/TestView";
import { useLocation, useHistory } from "react-router-dom";
import ConfirmationModal from "main/components/ConfirmationModal/ConfirmationModal";
import { showToast } from "main/shared/helpers";

const TestLeftSide = ({
  resetSelectedRoomData,
  resetSelectedTestData,
  roomSearchValue,
  setRoomSearchValue,
  testSearchValue,
  setTestSearchValue,
  backConfirmationModal,
  setBackConfirmationModal,
  removeValues,
  setRemoveValues,
  keyForResetCacheOFSelect,
  setKeyForResetCacheOFSelect,
}) => {
  const history = useHistory();
  const search = useLocation().search;

  const searchParams = new URLSearchParams(search);
  const activeApprovedView =
    searchParams.get(approvedView) || getDefaultApproveView();

  searchParams.get(viewTestAndRoomsQueryTabName);

  const isRoomModeEnable = () => {
    const mode = searchParams.get(viewTestAndRoomsQueryTabName);
    return mode === viewTestAndRoomsTabs.roomView.value;
  };

  const getHelperMsg = () => {
    let helperMsg = "";
    if (isRoomModeEnable()) {
      helperMsg = `Remove ${
        removeValues.length > 1 ? "Rooms" : "Room"
      } may result in data loss. Are you sure you want to proceed?`;
    } else {
      helperMsg = `Remove ${
        removeValues.length > 1 ? "Tests" : "Test"
      } may result in data loss. Are you sure you want to proceed?`;
    }
    return helperMsg;
  };

  const handleSelectedTab = (selectedType) => {
    resetSelectedRoomData();
    resetSelectedTestData();
    searchParams.set(viewTestAndRoomsQueryTabName, selectedType);
    setKeyForResetCacheOFSelect((prev) => prev + 1);
    showToast(ViewRecommendedToastMsg[selectedType].msg, true);
    history.replace({ search: searchParams.toString() });
  };

  const handleApprovedViewClick = (selectedType) => {
    if (selectedType?.value) {
      searchParams.set(approvedView, selectedType?.value);
      setKeyForResetCacheOFSelect((prev) => prev + 1);
      resetSelectedRoomData();
      resetSelectedTestData();
      history.replace({ search: searchParams.toString() });
    }
  };

  const handleTestDeletion = () => {
    if (Array.isArray(removeValues)) {
      setTestSearchValue([]);
      resetSelectedTestData();
    }
    setRemoveValues("");
    setBackConfirmationModal(false);
  };

  const handleRoomDeletion = () => {
    try {
      if (Array.isArray(removeValues)) {
        setRoomSearchValue([]);
        resetSelectedRoomData();
      }
      setRemoveValues("");
      setBackConfirmationModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeletion = () => {
    if (isRoomModeEnable()) {
      handleRoomDeletion();
    } else {
      handleTestDeletion();
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.filter_and_tab_container}>
        <div className={styles.tab_container}>
          <Tabs
            className={styles.tabs_view}
            justify
            activeKey={searchParams.get(viewTestAndRoomsQueryTabName)}
            onSelect={handleSelectedTab}
          >
            <Tab
              eventKey={viewTestAndRoomsTabs.roomView.value}
              title={viewTestAndRoomsTabs.roomView.label}
            >
              <div>
                <RoomView
                  roomSearchValue={roomSearchValue}
                  setRoomSearchValue={setRoomSearchValue}
                  backConfirmationModal={backConfirmationModal}
                  setBackConfirmationModal={setBackConfirmationModal}
                  removeValues={removeValues}
                  setRemoveValues={setRemoveValues}
                  resetSelectedRoomData={resetSelectedRoomData}
                  keyForResetCacheOFSelect={keyForResetCacheOFSelect}
                  setKeyForResetCacheOFSelect={setKeyForResetCacheOFSelect}
                />
              </div>
            </Tab>

            <Tab
              eventKey={viewTestAndRoomsTabs.testView.value}
              title={viewTestAndRoomsTabs.testView.label}
            >
              <TestView
                testSearchValue={testSearchValue}
                setTestSearchValue={setTestSearchValue}
                backConfirmationModal={backConfirmationModal}
                setBackConfirmationModal={setBackConfirmationModal}
                removeValues={removeValues}
                setRemoveValues={setRemoveValues}
                resetSelectedTestData={resetSelectedTestData}
                keyForResetCacheOFSelect={keyForResetCacheOFSelect}
                setKeyForResetCacheOFSelect={setKeyForResetCacheOFSelect}
              />
            </Tab>
          </Tabs>
        </div>
        <div className={styles.filter_container}>
          <CustomFilterPopover
            filterOption={filterOption}
            size="sm"
            closeOnSelect={true}
            selectedOption={[activeApprovedView]}
            handleOnClick={handleApprovedViewClick}
          />
        </div>
      </div>
      <ConfirmationModal
        show={backConfirmationModal}
        onHide={() => setBackConfirmationModal(false)}
        disabled="false"
        imgSrc={require("assets/icons/delete.png").default}
        helperText={getHelperMsg()}
        onSave={handleDeletion}
      />
    </div>
  );
};

export default TestLeftSide;
