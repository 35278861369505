import React from "react";
import styles from "./room_view_left_side.module.css";
import SelectDropDown from "main/shared_components/SelectDropdown/SelectDropDown";
import RoomListing from "./RoomListing/RoomListing";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getViewRecTestListBehaveOfRoomsAction,
  getViewRecommendedRoomListAction
} from "store/actions/view_test_recommend/viewTestRecommendAction";
import { convertLabelIntoValueAndOption, showToast } from "main/shared/helpers";
import { Spinner } from "react-bootstrap";
import { ReactSelectActionTypes } from "main/shared/constants";

const RoomView = ({
  roomSearchValue,
  setRoomSearchValue,
  setBackConfirmationModal,
  setRemoveValues,
  keyForResetCacheOFSelect,
  setKeyForResetCacheOFSelect
}) => {
  const { insp_id } = useParams();
  const dispatch = useDispatch();

  const fetchRoomList = useSelector(
    (state) => state.fetchViewRecommendedRoomList
  );

  const {
    roomData: storeRoomData,
    roomListLoading,
    selectedRoomsList,
  } = fetchRoomList;

  const handleOnRoomSelection = (value, event) => {
    if (
      event?.action === ReactSelectActionTypes.CLEAR_ALL ||
      event?.action === ReactSelectActionTypes.REMOVE_SINGLE_VALUE
    ) {
      if (event?.removedValues?.length) {
        setRemoveValues(event?.removedValues);
      } else {
        setRemoveValues(event?.removedValue);
      }
      setKeyForResetCacheOFSelect((prev) => prev + 1);
      setBackConfirmationModal(true);
      return;
    }
    if (Array.isArray(value) && value?.length > 5) {
      showToast("Maximum room selection is 5");
    } else {
      setRoomSearchValue(value);
    }
  };

  const fetchRooms = async (search, loadedOptions = []) => {
    try {
      const page = loadedOptions.length
        ? Number(storeRoomData?.current_page + 1)
        : 1;
      const roomData = await dispatch(
        getViewRecommendedRoomListAction(insp_id, page)
      );
      const roomList = roomData?.data || [];
      const options = convertLabelIntoValueAndOption(
        roomList,
        "roomUniqueId",
        "roomUniqueName"
      );
      return {
        options: options,
        hasMore: Boolean(roomData?.next_page_url),
      };
    } catch (error) {
      console.log({ error });
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const handleRoomDetailsClick = async () => {
    try {
      if (roomSearchValue.length) {
        const roomIds = [];
        roomSearchValue.forEach((room) => {
          roomIds.push(room.value);
        });
        await dispatch(getViewRecTestListBehaveOfRoomsAction(insp_id, roomIds));
      } else {
        // reset
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.container}>
      <div>
        <div>
          <SelectDropDown
            isMulti={true}
            closeMenuOnSelect={false}
            placeholder="Select Rooms"
            loadOptions={fetchRooms}
            searchValue={roomSearchValue}
            handleOnChange={handleOnRoomSelection}
            onMenuClose={handleRoomDetailsClick}
            isSearchable={false}
            resetCacheKey={keyForResetCacheOFSelect}
          />
        </div>
        <div>
          {roomListLoading ? (
            <div className={styles.loader}>
              <Spinner animation="border" variant="success" />
            </div>
          ) : (
            <>
              {selectedRoomsList?.length ? (
                selectedRoomsList.map((roomData, index) => {
                  return (
                    <div key={index}>
                      <RoomListing data={roomData} />
                      <div
                        className={` ${
                          selectedRoomsList?.length - 1 !== index
                            ? styles.divider
                            : styles.margin_from_bottom
                        }  `}
                      ></div>
                    </div>
                  );
                })
              ) : (
                <></>
              )}{" "}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RoomView;
