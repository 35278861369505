import {
  GET_VIEW_RECOMMENDED_ROOM_LIST_FAIL,
  GET_VIEW_RECOMMENDED_ROOM_LIST_REQUEST,
  GET_VIEW_RECOMMENDED_ROOM_LIST_RESET,
  GET_VIEW_RECOMMENDED_ROOM_LIST_SUCCESS,
  GET_VIEW_RECOMMENDED_TEST_LIST_FAIL,
  GET_VIEW_RECOMMENDED_TEST_LIST_REQUEST,
  GET_VIEW_RECOMMENDED_TEST_LIST_RESET,
  GET_VIEW_RECOMMENDED_TEST_LIST_SUCCESS,
  GET_VIEW_REC_TEST_LIST_BEHAVE_OF_ROOMS_REQUEST,
  GET_VIEW_REC_TEST_LIST_BEHAVE_OF_ROOMS_FAIL,
  GET_VIEW_REC_TEST_LIST_BEHAVE_OF_ROOMS_SUCCESS,
  VIEW_RECOMMENDED_SELECTED_ROOM_LIST_RESET,
  GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_REQUEST,
  GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_FAIL,
  GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_SUCCESS,
  GET_VIEW_REC_ROOM_LIST_BEHAVE_OF_TESTS_REQUEST,
  GET_VIEW_REC_ROOM_LIST_BEHAVE_OF_TESTS_SUCCESS,
  GET_VIEW_REC_ROOM_LIST_BEHAVE_OF_TESTS_FAIL,
  GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_REQUEST,
  GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_SUCCESS,
  GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_FAIL,
  VIEW_RECOMMENDED_SELECTED_TEST_LIST_RESET,
  GET_VIEW_RECOMMENDED_ROOM_TASK_EDITOR_REQUEST,
  GET_VIEW_RECOMMENDED_ROOM_TASK_EDITOR_SUCCESS,
  GET_VIEW_RECOMMENDED_ROOM_TASK_EDITOR_FAIL,
  GET_VIEW_RECOMMENDED_TEST_TASK_EDITOR_REQUEST,
  GET_VIEW_RECOMMENDED_TEST_TASK_EDITOR_SUCCESS,
  GET_VIEW_RECOMMENDED_TEST_TASK_EDITOR_FAIL,
  GET_VIEW_RECOMMENDED_ROOM_APPROVE_CLIENT_SUCCESS,
  GET_VIEW_RECOMMENDED_ROOM_APPROVE_CLIENT_REQUEST,
  GET_VIEW_RECOMMENDED_ROOM_APPROVE_CLIENT_FAIL,
  GET_VIEW_RECOMMENDED_TEST_APPROVE_CLIENT_FAIL,
  GET_VIEW_RECOMMENDED_TEST_APPROVE_CLIENT_SUCCESS,
  GET_VIEW_RECOMMENDED_TEST_APPROVE_CLIENT_REQUEST,
} from "../../types/view_test_recommend/viewTestRecommendTypes";

const roomListInitialState = {
  loading: false,
  roomData: {},
  roomListLoading: false,
  featureListLoading: false,
  taskEditImageLoading: false,
  approveClientLoading: false,
  featureAndTaskList: [],
  selectedRoomsList: [],
  currentSelectedRoom: {},
  currentSelectedTest: {},
  currentSelectedTestInTest: {},
  error: "",
};

export const fetchViewRecommendedRoomListReducers = (
  state = roomListInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_VIEW_RECOMMENDED_ROOM_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_VIEW_RECOMMENDED_ROOM_APPROVE_CLIENT_REQUEST:
      return {
        ...state,
        approveClientLoading: true,
      };

    case GET_VIEW_RECOMMENDED_ROOM_TASK_EDITOR_REQUEST:
      return {
        ...state,
        taskEditImageLoading: true,
      };

    case GET_VIEW_REC_TEST_LIST_BEHAVE_OF_ROOMS_REQUEST:
      return {
        ...state,
        roomListLoading: true,
      };

    case GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_REQUEST:
      return {
        ...state,
        featureListLoading: true,
      };

    case GET_VIEW_RECOMMENDED_ROOM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        roomData: payload,
        error: "",
      };

    case GET_VIEW_RECOMMENDED_ROOM_APPROVE_CLIENT_SUCCESS:
      return {
        ...state,
        approveClientLoading: false,
      };

    case GET_VIEW_REC_TEST_LIST_BEHAVE_OF_ROOMS_SUCCESS:
      const getCurrentSelectedRoom = payload.length
        ? payload.find((room) => room.isSelected === true)
        : {};
      const getCurrentTestRoom = getCurrentSelectedRoom?.tests?.length
        ? getCurrentSelectedRoom?.tests.find((test) => test.isSelected === true)
        : {};
      const getCurrentTestInTest = getCurrentTestRoom?.pivot?.length
        ? getCurrentTestRoom?.pivot.find((test) => test.isSelected === true)
        : {};

      return {
        ...state,
        roomListLoading: false,
        selectedRoomsList: payload,
        currentSelectedRoom: getCurrentSelectedRoom,
        currentSelectedTest: getCurrentTestRoom,
        currentSelectedTestInTest: getCurrentTestInTest,
        error: "",
      };

    case GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_SUCCESS:
      return {
        ...state,
        featureListLoading: false,
        featureAndTaskList: payload,
        error: "",
      };

    case GET_VIEW_RECOMMENDED_ROOM_TASK_EDITOR_SUCCESS:
      return {
        ...state,
        taskEditImageLoading: false,
      };

    case GET_VIEW_RECOMMENDED_ROOM_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case GET_VIEW_RECOMMENDED_ROOM_APPROVE_CLIENT_FAIL:
      return {
        ...state,
        approveClientLoading: false,
        error: payload,
      };

    case GET_VIEW_RECOMMENDED_ROOM_TASK_EDITOR_FAIL:
      return {
        ...state,
        taskEditImageLoading: false,
        error: payload,
      };

    case GET_VIEW_REC_TEST_LIST_BEHAVE_OF_ROOMS_FAIL:
      return {
        ...state,
        roomListLoading: false,
        error: payload,
      };

    case GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_FAIL:
      return {
        ...state,
        featureListLoading: false,
        error: payload,
      };

    case VIEW_RECOMMENDED_SELECTED_ROOM_LIST_RESET:
      return {
        ...state,
        loading: false,
        roomListLoading: false,
        featureListLoading: false,
        approveClientLoading: false,
        taskEditImageLoading: false,
        error: "",
        selectedRoomsList: [],
        featureAndTaskList: [],
        currentSelectedRoom: {},
        currentSelectedTestInTest: {},
        currentSelectedTest: {},
      };

    case GET_VIEW_RECOMMENDED_ROOM_LIST_RESET:
      return {
        loading: false,
        roomListLoading: false,
        featureListLoading: false,
        taskEditImageLoading: false,
        approveClientLoading: false,
        error: "",
        selectedRoomsList: [],
        featureAndTaskList: [],
        currentSelectedRoom: {},
        currentSelectedTest: {},
        currentSelectedTestInTest: {},
        roomData: {},
      };

    default:
      return state;
  }
};

const testListInitialState = {
  loading: false,
  testListLoading: false,
  featureListLoading: false,
  taskEditImageLoading: false,
  approveClientLoading: false,
  featureAndTaskList: [],
  testList: [],
  selectedTestList: [],
  currentSelectedRoom: {},
  currentSelectedTest: {},
  currentSelectedTestInRoom: {},
  error: "",
};

export const fetchViewRecommendedTestListReducers = (
  state = testListInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_VIEW_RECOMMENDED_TEST_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_VIEW_RECOMMENDED_TEST_TASK_EDITOR_REQUEST:
      return {
        ...state,
        taskEditImageLoading: true,
      };

    case GET_VIEW_RECOMMENDED_TEST_APPROVE_CLIENT_REQUEST:
      return {
        ...state,
        approveClientLoading: true,
      };

    case GET_VIEW_REC_ROOM_LIST_BEHAVE_OF_TESTS_REQUEST:
      return {
        ...state,
        testListLoading: true,
      };

    case GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_REQUEST:
      return {
        ...state,
        featureListLoading: true,
      };

    case GET_VIEW_RECOMMENDED_TEST_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        testList: payload,
        error: "",
      };

    case GET_VIEW_RECOMMENDED_TEST_APPROVE_CLIENT_SUCCESS:
      return {
        ...state,
        approveClientLoading: false,
      };

    case GET_VIEW_RECOMMENDED_TEST_TASK_EDITOR_SUCCESS:
      return {
        ...state,
        taskEditImageLoading: false,
        error: "",
      };

    case GET_VIEW_REC_ROOM_LIST_BEHAVE_OF_TESTS_SUCCESS:
      const getCurrentTestRoom = payload.length
        ? payload.find((test) => test.isSelected === true)
        : {};
      const getCurrentSelectedRoom = getCurrentTestRoom?.inspection_room?.length
        ? getCurrentTestRoom?.inspection_room.find(
            (room) => room.isSelected === true
          )
        : {};

      const getCurrentSelectedTestInRoom = getCurrentSelectedRoom?.tests?.length
        ? getCurrentSelectedRoom?.tests.find(
            (testInRoom) => testInRoom.isSelected === true
          )
        : {};

      return {
        ...state,
        testListLoading: false,
        selectedTestList: payload,
        currentSelectedTest: getCurrentTestRoom,
        currentSelectedRoom: getCurrentSelectedRoom,
        currentSelectedTestInRoom: getCurrentSelectedTestInRoom,
        error: "",
      };

    case GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_SUCCESS:
      return {
        ...state,
        featureListLoading: false,
        featureAndTaskList: payload,
        error: "",
      };

    case GET_VIEW_RECOMMENDED_TEST_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case GET_VIEW_RECOMMENDED_TEST_APPROVE_CLIENT_FAIL:
      return {
        ...state,
        approveClientLoading: false,
      };

    case GET_VIEW_RECOMMENDED_TEST_TASK_EDITOR_FAIL:
      return {
        ...state,
        taskEditImageLoading: false,
        error: payload,
      };

    case GET_VIEW_REC_ROOM_LIST_BEHAVE_OF_TESTS_FAIL:
      return {
        ...state,
        testListLoading: false,
        error: payload,
      };

    case GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_FAIL:
      return {
        ...state,
        featureListLoading: false,
        error: payload,
      };

    case VIEW_RECOMMENDED_SELECTED_TEST_LIST_RESET:
      return {
        ...state,
        loading: false,
        testListLoading: false,
        featureListLoading: false,
        taskEditImageLoading: false,
        approveClientLoading: false,
        error: "",
        selectedTestList: [],
        featureAndTaskList: [],
        currentSelectedTest: {},
        currentSelectedRoom: {},
        currentSelectedTestInRoom: {},
      };

    case GET_VIEW_RECOMMENDED_TEST_LIST_RESET:
      return {
        loading: false,
        testListLoading: false,
        featureListLoading: false,
        taskEditImageLoading: false,
        approveClientLoading: false,
        error: "",
        selectedTestList: [],
        featureAndTaskList: [],
        testList: [],
        currentSelectedTest: {},
        currentSelectedRoom: {},
        currentSelectedTestInRoom: {},
      };

    default:
      return state;
  }
};
