import { approvedClientStatus, approvedView } from "main/shared/constants";
import axios from "../../../axios-defaults";
import { dataUrlToFile1, handleReduxError } from "../../../main/shared/helpers";
import {
  GET_VIEW_RECOMMENDED_ROOM_LIST_REQUEST,
  GET_VIEW_RECOMMENDED_ROOM_LIST_SUCCESS,
  GET_VIEW_RECOMMENDED_ROOM_LIST_FAIL,
  GET_VIEW_RECOMMENDED_TEST_LIST_REQUEST,
  GET_VIEW_RECOMMENDED_TEST_LIST_SUCCESS,
  GET_VIEW_RECOMMENDED_TEST_LIST_FAIL,
  GET_VIEW_REC_TEST_LIST_BEHAVE_OF_ROOMS_REQUEST,
  GET_VIEW_REC_TEST_LIST_BEHAVE_OF_ROOMS_SUCCESS,
  GET_VIEW_REC_TEST_LIST_BEHAVE_OF_ROOMS_FAIL,
  GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_SUCCESS,
  GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_FAIL,
  GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_REQUEST,
  GET_VIEW_REC_ROOM_LIST_BEHAVE_OF_TESTS_FAIL,
  GET_VIEW_REC_ROOM_LIST_BEHAVE_OF_TESTS_SUCCESS,
  GET_VIEW_REC_ROOM_LIST_BEHAVE_OF_TESTS_REQUEST,
  GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_REQUEST,
  GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_SUCCESS,
  GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_FAIL,
  GET_VIEW_RECOMMENDED_ROOM_TASK_EDITOR_REQUEST,
  GET_VIEW_RECOMMENDED_ROOM_TASK_EDITOR_SUCCESS,
  GET_VIEW_RECOMMENDED_ROOM_TASK_EDITOR_FAIL,
  GET_VIEW_RECOMMENDED_TEST_TASK_EDITOR_FAIL,
  GET_VIEW_RECOMMENDED_TEST_TASK_EDITOR_SUCCESS,
  GET_VIEW_RECOMMENDED_TEST_TASK_EDITOR_REQUEST,
  GET_VIEW_RECOMMENDED_ROOM_APPROVE_CLIENT_SUCCESS,
  GET_VIEW_RECOMMENDED_ROOM_APPROVE_CLIENT_REQUEST,
  GET_VIEW_RECOMMENDED_ROOM_APPROVE_CLIENT_FAIL,
  GET_VIEW_RECOMMENDED_TEST_APPROVE_CLIENT_REQUEST,
  GET_VIEW_RECOMMENDED_TEST_APPROVE_CLIENT_SUCCESS,
  GET_VIEW_RECOMMENDED_TEST_APPROVE_CLIENT_FAIL,
} from "../../types/view_test_recommend/viewTestRecommendTypes";
import { uploadFile } from "services/UploadFile";

export const getViewRecommendedRoomListAction =
  (inspection_id, page = 1) =>
  async (dispatch) => {
    const searchParams = new URLSearchParams(window.location.search);
    const activeApprovedView = searchParams.get(approvedView);
    try {
      dispatch({
        type: GET_VIEW_RECOMMENDED_ROOM_LIST_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.get(
        `/v2/inspections/${inspection_id}/rooms?page=${page}&search=${activeApprovedView}`,
        config
      );

      if (data?.data?.length) {
        data?.data.forEach((room) => {
          room.roomUniqueId = room?.pivot?.id || "";
          room.room_pivot_id = room?.pivot?.id || "";
          room.roomUniqueName = room?.pivot?.description || "";
        });
      }

      dispatch({
        type: GET_VIEW_RECOMMENDED_ROOM_LIST_SUCCESS,
        payload: data,
      });

      return data;
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: GET_VIEW_RECOMMENDED_ROOM_LIST_FAIL,
        payload: err,
      });
      throw Error(err);
    }
  };

export const getViewRecTestListBehaveOfRoomsAction =
  (inspection_id, roomPivotIds = []) =>
  async (dispatch) => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const activeApprovedView = searchParams.get(approvedView);
      let room_pivot_id = "";
      let testObj = {};
      dispatch({
        type: GET_VIEW_REC_TEST_LIST_BEHAVE_OF_ROOMS_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.get(
        `/v2/inspections/${inspection_id}/room?room_pivot_id=${roomPivotIds}&search=${activeApprovedView}`,
        config
      );
      if (data.length) {
        data.forEach((roomData, roomIndex) => {
          if (roomData?.tests?.length) {
            roomData.tests.forEach((test, testIndex) => {
              test?.pivot.forEach((testInTestId, testInTestIdIndex) => {
                if (roomIndex === 0) {
                  roomData.isSelected = true;
                  room_pivot_id = roomData?.id;
                  if (testIndex === 0) {
                    test.isSelected = true;
                    if (testInTestIdIndex === 0) {
                      testInTestId.isSelected = true;
                      testObj = JSON.parse(JSON.stringify(testInTestId));
                    } else {
                      testInTestId.isSelected = false;
                    }
                  } else {
                    test.isSelected = false;
                    testInTestId.isSelected = false;
                  }
                } else {
                  roomData.isSelected = false;
                  test.isSelected = false;
                  testInTestId.isSelected = false;
                }
              });
            });
          }
        });
      }

      dispatch({
        type: GET_VIEW_REC_TEST_LIST_BEHAVE_OF_ROOMS_SUCCESS,
        payload: data,
      });

      if (room_pivot_id && testObj) {
        dispatch(
          getFeaturesWithTaskBehaveOfRoomsGivenTestIdAction(
            room_pivot_id,
            testObj
          )
        );
      }
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: GET_VIEW_REC_TEST_LIST_BEHAVE_OF_ROOMS_FAIL,
        payload: err,
      });
      throw Error(err);
    }
  };

export const getFeaturesWithTaskBehaveOfRoomsGivenTestIdAction =
  (room_pivot_id, testData) => async (dispatch) => {
    try {
      dispatch({
        type: GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.get(
        `/v2/rooms/${room_pivot_id}/tests/${testData?.test_id}/observations?test_name=${testData?.name}`,
        config
      );

      if (data.length) {
        data.forEach((feature) => {
          if (feature.length) {
            feature.images.forEach((task) => {
              task.isSelected = false;
            });
          }
        });
      }
      dispatch({
        type: GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_FAIL,
        payload: err,
      });
      throw Error(err);
    }
  };

export const handleSelectedTestFromRoomAction =
  (type, testId, testInTestId, roomDetails) => async (dispatch, getState) => {
    try {
      let room_pivot_id = "";
      let testObj = {};
      const {
        fetchViewRecommendedRoomList: { selectedRoomsList = [] },
      } = getState();
      const cloneSelectedRoomsList = JSON.parse(
        JSON.stringify(selectedRoomsList)
      );
      dispatch({
        type: GET_VIEW_REC_TEST_LIST_BEHAVE_OF_ROOMS_REQUEST,
      });

      if (cloneSelectedRoomsList.length) {
        cloneSelectedRoomsList.forEach((roomData) => {
          roomData.isSelected = false;
          if (roomData?.tests?.length) {
            roomData.tests.forEach((test) => {
              test.isSelected = false;
              test?.pivot.forEach((testInTest) => {
                testInTest.isSelected = false;
              });
            });
          }
        });
        cloneSelectedRoomsList.forEach((roomData) => {
          if (Number(roomData.id) === Number(roomDetails.id)) {
            roomData.isSelected = true;
            room_pivot_id = roomData?.id;
            roomData.tests.forEach((test) => {
              if (Number(test.id) === Number(testId)) {
                test.isSelected = true;
                test?.pivot.forEach((testInTest) => {
                  if (Number(testInTest.id) === Number(testInTestId)) {
                    testInTest.isSelected = true;
                    testObj = JSON.parse(JSON.stringify(testInTest));
                  }
                });
              }
            });
          }
        });
      }
      dispatch({
        type: GET_VIEW_REC_TEST_LIST_BEHAVE_OF_ROOMS_SUCCESS,
        payload: cloneSelectedRoomsList,
      });

      if (room_pivot_id && testObj) {
        dispatch(
          getFeaturesWithTaskBehaveOfRoomsGivenTestIdAction(
            room_pivot_id,
            testObj
          )
        );
      }
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: GET_VIEW_REC_TEST_LIST_BEHAVE_OF_ROOMS_FAIL,
        payload: err,
      });
      throw Error(err);
    }
  };

export const handleRoomEditorNewImageAction =
  (imageUrl, originalImageUrl, imageId) => async (dispatch, getState) => {
    try {
      const {
        fetchViewRecommendedRoomList: { featureAndTaskList = [] },
      } = getState();
      const cloneFeatureAndTaskList = JSON.parse(
        JSON.stringify(featureAndTaskList)
      );
      dispatch({
        type: GET_VIEW_RECOMMENDED_ROOM_TASK_EDITOR_REQUEST,
      });

      const pathName = `mobile/images/observation_images/${new Date().getTime()}.${originalImageUrl
        .split(".")
        .pop()}`;

      const compressImage = await dataUrlToFile1(imageUrl, pathName);
      const url = await uploadFile(compressImage, compressImage.name);
      const bodyData = {
        image_url: url,
        thumb_url: url,
      };
      const { data } = await axios.patch(`/v2/image/${imageId}`, bodyData);
      if (cloneFeatureAndTaskList?.length) {
        cloneFeatureAndTaskList.forEach((feat) => {
          if (feat.images.length) {
            feat.images.forEach((task) => {
              if (Number(task?.id) === Number(imageId)) {
                task.image_url = url;
                task.thumb_url = url;
              }
            });
          }
        });
      }
      const payload = {
        ...bodyData,
        ...data,
      };
      dispatch({
        type: GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_SUCCESS,
        payload: cloneFeatureAndTaskList,
      });

      dispatch({
        type: GET_VIEW_RECOMMENDED_ROOM_TASK_EDITOR_SUCCESS,
        payload: payload,
      });
      return payload;
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: GET_VIEW_RECOMMENDED_ROOM_TASK_EDITOR_FAIL,
        payload: err,
      });
      throw Error(err);
    }
  };

export const handleApprovedTaskFromRoomAction =
  (inspection_id, bodyData = {}, isApproved = false) =>
  async (dispatch, getState) => {
    try {
      let shouldReset = false;
      let removeRoomId = "";
      const searchParams = new URLSearchParams(window.location.search);
      const activeApprovedView = searchParams.get(approvedView);
      const {
        fetchViewRecommendedRoomList: { selectedRoomsList = [] },
      } = getState();
      let cloneSelectedRoomsList = JSON.parse(
        JSON.stringify(selectedRoomsList)
      );

      dispatch({
        type: GET_VIEW_RECOMMENDED_ROOM_APPROVE_CLIENT_REQUEST,
      });
      const payload = {
        room_pivot_id: bodyData?.room_pivot_id,
        test_id: bodyData?.testInTestTestId,
        test_name: bodyData?.testInTestRoom,
        image_id: bodyData.image_id,
      };
      const { data } = await axios.patch(
        `/v2/inspections/${inspection_id}/image?approved=${isApproved}`,
        payload
      );
      dispatch({
        type: GET_VIEW_RECOMMENDED_ROOM_APPROVE_CLIENT_SUCCESS,
        payload: data,
      });
      const roomIndex = cloneSelectedRoomsList.findIndex(
        (room) => Number(room.id) === Number(bodyData.room_pivot_id)
      );
      const testIndex = cloneSelectedRoomsList[roomIndex].tests.findIndex(
        (testObj) =>
          Number(testObj.id) === Number(bodyData.test_id) &&
          testObj.name === bodyData.test_name
      );

      const testInTestIndex = cloneSelectedRoomsList[roomIndex].tests[
        testIndex
      ].pivot.findIndex(
        (testObj) => Number(testObj.id) === Number(bodyData.testInTestId)
      );

      if (isApproved) {
        cloneSelectedRoomsList[roomIndex].tests[testIndex].pivot[
          testInTestIndex
        ].status = approvedClientStatus.APPROVE;
      } else {
        cloneSelectedRoomsList[roomIndex].tests[testIndex].pivot[
          testInTestIndex
        ].status = approvedClientStatus.PENDING;
      }
      if (activeApprovedView !== "all") {
        cloneSelectedRoomsList[roomIndex].isSelected = false;
        cloneSelectedRoomsList[roomIndex].tests[testIndex].isSelected = false;
        cloneSelectedRoomsList[roomIndex].tests[testIndex].pivot[
          testInTestIndex
        ].isSelected = false;

        if (cloneSelectedRoomsList.length === 1) {
          cloneSelectedRoomsList[0].isSelected = true;
          if (cloneSelectedRoomsList[0].tests?.length === 1) {
            cloneSelectedRoomsList[0].tests[testIndex].isSelected = true;
            if (
              cloneSelectedRoomsList[roomIndex].tests[testIndex].pivot
                ?.length === 1
            ) {
              shouldReset = true;
            } else {
              if (
                cloneSelectedRoomsList[0].tests[testIndex].pivot.length - 1 ===
                Number(testInTestIndex)
              ) {
                cloneSelectedRoomsList[0].tests[testIndex].pivot[
                  testInTestIndex - 1
                ].isSelected = true;
              } else {
                cloneSelectedRoomsList[0].tests[testIndex].pivot[
                  testInTestIndex + 1
                ].isSelected = true;
              }
              cloneSelectedRoomsList[0].tests[testIndex].pivot =
                cloneSelectedRoomsList[0].tests[testIndex].pivot.filter(
                  (testObj) =>
                    Number(testObj.id) !== Number(bodyData.testInTestId) &&
                    testObj.name !== bodyData.testInTestRoom
                );
            }
          } else {
            if (
              cloneSelectedRoomsList[roomIndex].tests[testIndex].pivot
                ?.length === 1
            ) {
              if (
                cloneSelectedRoomsList[0].tests.length - 1 ===
                Number(testIndex)
              ) {
                cloneSelectedRoomsList[0].tests[
                  testIndex - 1
                ].isSelected = true;
                cloneSelectedRoomsList[0].tests[
                  testIndex - 1
                ].pivot[0].isSelected = true;
              } else {
                cloneSelectedRoomsList[0].tests[
                  testIndex + 1
                ].isSelected = true;
                cloneSelectedRoomsList[0].tests[
                  testIndex + 1
                ].pivot[0].isSelected = true;
              }
              cloneSelectedRoomsList[0].tests[testIndex].pivot =
                cloneSelectedRoomsList[0].tests[testIndex].pivot.filter(
                  (testObj) =>
                    Number(testObj.id) !== Number(bodyData.testInTestId) &&
                    testObj.name !== bodyData.testInTestRoom
                );
              cloneSelectedRoomsList[0].tests =
                cloneSelectedRoomsList[0].tests.filter(
                  (testObj) =>
                    Number(testObj.id) !== Number(bodyData.test_id) &&
                    testObj.name !== bodyData.test_name
                );
            } else {
              cloneSelectedRoomsList[roomIndex].tests[
                testIndex
              ].isSelected = true;
              if (
                cloneSelectedRoomsList[0].tests[testIndex].pivot.length - 1 ===
                Number(testInTestIndex)
              ) {
                cloneSelectedRoomsList[0].tests[testIndex].pivot[
                  testInTestIndex - 1
                ].isSelected = true;
              } else {
                cloneSelectedRoomsList[0].tests[testIndex].pivot[
                  testInTestIndex + 1
                ].isSelected = true;
              }
              cloneSelectedRoomsList[0].tests[testIndex].pivot =
                cloneSelectedRoomsList[0].tests[testIndex].pivot.filter(
                  (testObj) =>
                    Number(testObj.id) !== Number(bodyData.testInTestId) &&
                    testObj.name !== bodyData.testInTestRoom
                );
            }
          }
        } else {
          if (cloneSelectedRoomsList[roomIndex].tests?.length === 1) {
            // select next room and select that test of zero index and testInTest zero index
            if (
              cloneSelectedRoomsList[roomIndex].tests[testIndex].pivot
                .length === 1
            ) {
              if (cloneSelectedRoomsList.length - 1 === Number(roomIndex)) {
                cloneSelectedRoomsList[roomIndex - 1].isSelected = true;
                cloneSelectedRoomsList[
                  roomIndex - 1
                ].tests[0].isSelected = true;
                cloneSelectedRoomsList[
                  roomIndex - 1
                ].tests[0].pivot[0].isSelected = true;
              } else {
                cloneSelectedRoomsList[roomIndex + 1].isSelected = true;
                cloneSelectedRoomsList[
                  roomIndex + 1
                ].tests[0].isSelected = true;
                cloneSelectedRoomsList[
                  roomIndex + 1
                ].tests[0].pivot[0].isSelected = true;
              }
              removeRoomId = cloneSelectedRoomsList[roomIndex].id;
              cloneSelectedRoomsList[roomIndex].tests[testIndex].pivot =
                cloneSelectedRoomsList[roomIndex].tests[testIndex].pivot.filter(
                  (testObj) =>
                    Number(testObj.id) !== Number(bodyData.testInTestId) &&
                    testObj.name !== bodyData.testInTestRoom
                );
              cloneSelectedRoomsList[roomIndex].tests = cloneSelectedRoomsList[
                roomIndex
              ].tests.filter(
                (testObj) =>
                  Number(testObj.id) !== Number(bodyData.test_id) &&
                  testObj.name !== bodyData.test_name
              );

              cloneSelectedRoomsList = cloneSelectedRoomsList.filter(
                (room) => Number(room.id) !== Number(bodyData.room_pivot_id)
              );
            } else {
              cloneSelectedRoomsList[roomIndex].isSelected = true;
              cloneSelectedRoomsList[roomIndex].tests[
                testIndex
              ].isSelected = true;
              if (
                cloneSelectedRoomsList[roomIndex].tests[testIndex].pivot
                  .length -
                  1 ===
                Number(testInTestIndex)
              ) {
                cloneSelectedRoomsList[roomIndex].tests[testIndex].pivot[
                  testInTestIndex - 1
                ].isSelected = true;
              } else {
                cloneSelectedRoomsList[roomIndex].tests[testIndex].pivot[
                  testInTestIndex + 1
                ].isSelected = true;
              }
              cloneSelectedRoomsList[roomIndex].tests[testIndex].pivot =
                cloneSelectedRoomsList[roomIndex].tests[testIndex].pivot.filter(
                  (testObj) =>
                    Number(testObj.id) !== Number(bodyData.testInTestId) &&
                    testObj.name !== bodyData.testInTestRoom
                );
            }
          } else {
            cloneSelectedRoomsList[roomIndex].isSelected = true;
            if (
              cloneSelectedRoomsList[roomIndex].tests[testIndex].pivot
                ?.length === 1
            ) {
              if (
                cloneSelectedRoomsList[roomIndex].tests?.length - 1 ===
                Number(testIndex)
              ) {

                cloneSelectedRoomsList[roomIndex].tests[
                  testIndex - 1
                ].isSelected = true;
                cloneSelectedRoomsList[roomIndex].tests[
                  testIndex - 1
                ].pivot[0].isSelected = true;
              } else {
                cloneSelectedRoomsList[roomIndex].tests[
                  testIndex + 1
                ].isSelected = true;
                cloneSelectedRoomsList[roomIndex].tests[
                  testIndex + 1
                ].pivot[0].isSelected = true;
              }
              cloneSelectedRoomsList[roomIndex].tests[testIndex].pivot =
                cloneSelectedRoomsList[roomIndex].tests[testIndex].pivot.filter(
                  (testObj) =>
                    Number(testObj.id) !== Number(bodyData.testInTestId) &&
                    testObj.name !== bodyData.testInTestRoom
                );
              cloneSelectedRoomsList[roomIndex].tests = cloneSelectedRoomsList[
                roomIndex
              ].tests.filter(
                (testObj) =>
                  Number(testObj.id) !== Number(bodyData.test_id) &&
                  testObj.name !== bodyData.test_name
              );
            } else {
              cloneSelectedRoomsList[roomIndex].tests[
                testIndex
              ].isSelected = true;
              if (
                cloneSelectedRoomsList[roomIndex].tests[testIndex].pivot
                  .length -
                  1 ===
                Number(testInTestIndex)
              ) {
                cloneSelectedRoomsList[roomIndex].tests[testIndex].pivot[
                  testInTestIndex - 1
                ].isSelected = true;
              } else {
                cloneSelectedRoomsList[roomIndex].tests[testIndex].pivot[
                  testInTestIndex + 1
                ].isSelected = true;
              }
              cloneSelectedRoomsList[roomIndex].tests[testIndex].pivot =
                cloneSelectedRoomsList[roomIndex].tests[testIndex].pivot.filter(
                  (testObj) =>
                    Number(testObj.id) !== Number(bodyData.testInTestId) &&
                    testObj.name !== bodyData.testInTestRoom
                );
            }
          }
        }
      }

      dispatch({
        type: GET_VIEW_REC_TEST_LIST_BEHAVE_OF_ROOMS_SUCCESS,
        payload: cloneSelectedRoomsList,
      });

      if (!shouldReset && activeApprovedView !== "all") {
        const selectedRoom = cloneSelectedRoomsList.find(
          (room) => room.isSelected === true
        );

        const selectedTest = selectedRoom.tests.find(
          (test) => test.isSelected === true
        );

        const selectedTestInTest = selectedTest.pivot.find(
          (test) => test.isSelected === true
        );
        dispatch(
          getFeaturesWithTaskBehaveOfRoomsGivenTestIdAction(
            selectedRoom.id,
            selectedTestInTest
          )
        );
      }
      return {
        shouldReset,
        cloneSelectedRoomsList,
        data,
        removeRoomId,
      };
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: GET_VIEW_RECOMMENDED_ROOM_APPROVE_CLIENT_FAIL,
        payload: err,
      });
      throw Error(err);
    }
  };

/**           Test APIS                   */

export const getViewRecommendedTestListAction =
  (inspection_id) => async (dispatch) => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const activeApprovedView = searchParams.get(approvedView);

      dispatch({
        type: GET_VIEW_RECOMMENDED_TEST_LIST_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.get(
        `/v2/inspections/${inspection_id}/test?device_type=react&search=${activeApprovedView}`,
        config
      );
      if (data?.length) {
        data.forEach((test) => {
          test.testUniqueId = test?.id || "";
          test.testUniqueName = test?.name || "";
        });
      }

      dispatch({
        type: GET_VIEW_RECOMMENDED_TEST_LIST_SUCCESS,
        payload: data,
      });

      return data;
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: GET_VIEW_RECOMMENDED_TEST_LIST_FAIL,
        payload: err,
      });
      throw Error(err);
    }
  };

export const getViewRecRoomListBehaveOfTestAction =
  (inspection_id, testIds = [], testNames = []) =>
  async (dispatch) => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const activeApprovedView = searchParams.get(approvedView);

      let room_pivot_id = "";
      let testObj = {};
      dispatch({
        type: GET_VIEW_REC_ROOM_LIST_BEHAVE_OF_TESTS_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `/v2/inspections/${inspection_id}/tests?test_id=${testIds}&test_name=${testNames}&search=${activeApprovedView}`,
        config
      );

      if (data.length) {
        data.forEach((testData, testIndex) => {
          testData.testUniqueId = testData.id;
          testData.testUniqueName = testData.name;
          if (testData?.inspection_room?.length) {
            testData.inspection_room.forEach((room, roomIndex) => {
              room?.tests.forEach((testInRoom, testInRoomIndex) => {
                if (testIndex === 0) {
                  testData.isSelected = true;

                  if (roomIndex === 0) {
                    room.isSelected = true;
                    room_pivot_id = room?.id;
                    if (testInRoomIndex === 0) {
                      testInRoom.isSelected = true;
                      testObj = JSON.parse(JSON.stringify(testInRoom));
                    } else {
                      testInRoom.isSelected = false;
                    }
                  } else {
                    room.isSelected = false;
                    testInRoom.isSelected = false;
                  }
                } else {
                  testData.isSelected = false;
                  room.isSelected = false;
                  testInRoom.isSelected = false;
                }
              });
            });
          }
        });
      }

      dispatch({
        type: GET_VIEW_REC_ROOM_LIST_BEHAVE_OF_TESTS_SUCCESS,
        payload: data,
      });

      if (room_pivot_id && testObj) {
        dispatch(
          getFeaturesWithTaskBehaveOfTestGivenTestIdAction(
            room_pivot_id,
            testObj
          )
        );
      }
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: GET_VIEW_REC_ROOM_LIST_BEHAVE_OF_TESTS_FAIL,
        payload: err,
      });
      throw Error(err);
    }
  };

export const getFeaturesWithTaskBehaveOfTestGivenTestIdAction =
  (room_pivot_id, testData) => async (dispatch) => {
    try {
      dispatch({
        type: GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.get(
        `/v2/rooms/${room_pivot_id}/tests/${testData?.test_id}/observations?test_name=${testData?.name}`,
        config
      );

      if (data.length) {
        data.forEach((feature) => {
          if (feature.length) {
            feature.images.forEach((task) => {
              task.isSelected = false;
            });
          }
        });
      }
      dispatch({
        type: GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_FAIL,
        payload: err,
      });
      throw Error(err);
    }
  };

export const handleSelectedRoomFromTestAction =
  (type, room_pivot_id, testInRoomId, testDetails) =>
  async (dispatch, getState) => {
    try {
      let testObj = {};
      const {
        fetchViewRecommendedTestList: { selectedTestList = [] },
      } = getState();
      const cloneSelectedTestList = JSON.parse(
        JSON.stringify(selectedTestList)
      );
      dispatch({
        type: GET_VIEW_REC_ROOM_LIST_BEHAVE_OF_TESTS_REQUEST,
      });

      if (cloneSelectedTestList.length) {
        cloneSelectedTestList.forEach((testData) => {
          testData.isSelected = false;
          testData.inspection_room.forEach((room) => {
            room.isSelected = false;
            room.tests.forEach((testInRoom) => {
              testInRoom.isSelected = false;
            });
          });
          if (
            Number(testData.id) === Number(testDetails.id) &&
            testData.name === testDetails.name
          ) {
            testData.isSelected = true;
            testData.inspection_room.forEach((room) => {
              if (Number(room.id) === Number(room_pivot_id)) {
                room.isSelected = true;
                room.tests.forEach((testInRoom) => {
                  if (Number(testInRoom.id) === Number(testInRoomId)) {
                    testInRoom.isSelected = true;
                    testObj = JSON.parse(JSON.stringify(testInRoom));
                  }
                });
              } else {
                room.isSelected = false;
              }
            });
          }
        });
      }

      dispatch({
        type: GET_VIEW_REC_ROOM_LIST_BEHAVE_OF_TESTS_SUCCESS,
        payload: cloneSelectedTestList,
      });
      if (room_pivot_id && testObj) {
        dispatch(
          getFeaturesWithTaskBehaveOfTestGivenTestIdAction(
            room_pivot_id,
            testObj
          )
        );
      }
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: GET_VIEW_REC_ROOM_LIST_BEHAVE_OF_TESTS_FAIL,
        payload: err,
      });
      throw Error(err);
    }
  };

export const handleTestEditorNewImageAction =
  (imageUrl, originalImageUrl, imageId) => async (dispatch, getState) => {
    try {
      const {
        fetchViewRecommendedTestList: { featureAndTaskList = [] },
      } = getState();
      const cloneFeatureAndTaskList = JSON.parse(
        JSON.stringify(featureAndTaskList)
      );
      dispatch({
        type: GET_VIEW_RECOMMENDED_TEST_TASK_EDITOR_REQUEST,
      });

      const pathName = `mobile/images/observation_images/${new Date().getTime()}.${originalImageUrl
        .split(".")
        .pop()}`;

      const compressImage = await dataUrlToFile1(imageUrl, pathName);
      const url = await uploadFile(compressImage, compressImage.name);
      const bodyData = {
        image_url: url,
        thumb_url: url,
      };
      const { data } = await axios.patch(`/v2/image/${imageId}`, bodyData);
      if (cloneFeatureAndTaskList?.length) {
        cloneFeatureAndTaskList.forEach((feat) => {
          if (feat.images.length) {
            feat.images.forEach((task) => {
              if (Number(task?.id) === Number(imageId)) {
                task.image_url = url;
                task.thumb_url = url;
              }
            });
          }
        });
      }
      const payload = {
        ...bodyData,
        ...data,
      };
      dispatch({
        type: GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_SUCCESS,
        payload: cloneFeatureAndTaskList,
      });

      dispatch({
        type: GET_VIEW_RECOMMENDED_TEST_TASK_EDITOR_SUCCESS,
        payload: payload,
      });
      return payload;
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: GET_VIEW_RECOMMENDED_TEST_TASK_EDITOR_FAIL,
        payload: err,
      });
      throw Error(err);
    }
  };

export const handleApprovedTaskFromTestAction =
  (inspection_id, bodyData = {}, isApproved = false) =>
  async (dispatch, getState) => {
    try {
      let shouldReset = false;
      let removeTestId = "";
      const searchParams = new URLSearchParams(window.location.search);
      const activeApprovedView = searchParams.get(approvedView);
      const {
        fetchViewRecommendedTestList: { selectedTestList = [] },
      } = getState();
      let cloneSelectedTestList = JSON.parse(JSON.stringify(selectedTestList));

      dispatch({
        type: GET_VIEW_RECOMMENDED_TEST_APPROVE_CLIENT_REQUEST,
      });
      const payload = {
        room_pivot_id: bodyData?.room_pivot_id,
        test_id: bodyData?.testInRoomTestId,
        test_name: bodyData?.testInRoomName,
        image_id: bodyData.image_id,
      };
      const { data } = await axios.patch(
        `/v2/inspections/${inspection_id}/image?approved=${isApproved}`,
        payload
      );

      dispatch({
        type: GET_VIEW_RECOMMENDED_TEST_APPROVE_CLIENT_SUCCESS,
        payload: data,
      });

      const testIndex = cloneSelectedTestList.findIndex(
        (testObj) =>
          Number(testObj.id) === Number(bodyData.test_id) &&
          testObj.name === bodyData.test_name
      );
      const roomIndex = cloneSelectedTestList[
        testIndex
      ].inspection_room.findIndex(
        (roomObj) => Number(roomObj.id) === Number(bodyData.room_pivot_id)
      );

      const testInRoomIndex = cloneSelectedTestList[testIndex].inspection_room[
        roomIndex
      ].tests.findIndex(
        (testInRoomObj) =>
          Number(testInRoomObj.id) === Number(bodyData.testInRoomId)
      );

      if (isApproved) {
        cloneSelectedTestList[testIndex].inspection_room[roomIndex].tests[
          testInRoomIndex
        ].status = approvedClientStatus.APPROVE;
      } else {
        cloneSelectedTestList[testIndex].inspection_room[roomIndex].tests[
          testInRoomIndex
        ].status = approvedClientStatus.PENDING;
      }
      if (activeApprovedView !== "all") {
        cloneSelectedTestList[testIndex].isSelected = false;
        cloneSelectedTestList[testIndex].inspection_room[
          roomIndex
        ].isSelected = false;
        cloneSelectedTestList[testIndex].inspection_room[roomIndex].tests[
          testInRoomIndex
        ].isSelected = false;

        if (cloneSelectedTestList.length === 1) {
          cloneSelectedTestList[0].isSelected = true;
          if (cloneSelectedTestList[0].inspection_room?.length === 1) {
            cloneSelectedTestList[0].inspection_room[0].isSelected = true;
            if (
              cloneSelectedTestList[0].inspection_room[0].tests.length === 1
            ) {
              shouldReset = true;
            } else {
              if (
                cloneSelectedTestList[0].inspection_room[0].tests.length - 1 ===
                Number(testInRoomIndex)
              ) {
                cloneSelectedTestList[0].inspection_room[0].tests[
                  testInRoomIndex - 1
                ].isSelected = true;
              } else {
                cloneSelectedTestList[0].inspection_room[0].tests[
                  testInRoomIndex + 1
                ].isSelected = true;
              }
              cloneSelectedTestList[0].inspection_room[0].tests =
                cloneSelectedTestList[0].inspection_room[0].tests.filter(
                  (testInRoom) =>
                    Number(testInRoom.id) !== Number(bodyData.testInRoomId)
                );
            }
          } else {
            if (
              cloneSelectedTestList[0].inspection_room[roomIndex].tests
                .length === 1
            ) {
              if (
                cloneSelectedTestList[0].inspection_room?.length - 1 ===
                Number(roomIndex)
              ) {
                cloneSelectedTestList[0].inspection_room[
                  roomIndex - 1
                ].isSelected = true;
                cloneSelectedTestList[0].inspection_room[
                  roomIndex - 1
                ].tests[0].isSelected = true;
              } else {
                cloneSelectedTestList[0].inspection_room[
                  roomIndex + 1
                ].isSelected = true;
                cloneSelectedTestList[0].inspection_room[
                  roomIndex + 1
                ].tests[0].isSelected = true;
              }
              cloneSelectedTestList[0].inspection_room =
                cloneSelectedTestList[0].inspection_room.filter(
                  (roomObj) =>
                    Number(roomObj.id) !== Number(bodyData.room_pivot_id)
                );
            } else {
              cloneSelectedTestList[0].inspection_room[
                roomIndex
              ].isSelected = true;
              if (
                cloneSelectedTestList[0].inspection_room[roomIndex].tests
                  .length -
                  1 ===
                Number(testInRoomIndex)
              ) {
                cloneSelectedTestList[0].inspection_room[roomIndex].tests[
                  testInRoomIndex - 1
                ].isSelected = true;
              } else {
                cloneSelectedTestList[0].inspection_room[roomIndex].tests[
                  testInRoomIndex + 1
                ].isSelected = true;
              }
              cloneSelectedTestList[0].inspection_room[roomIndex].tests =
                cloneSelectedTestList[0].inspection_room[
                  roomIndex
                ].tests.filter(
                  (testInRoom) =>
                    Number(testInRoom.id) !== Number(bodyData.testInRoomId)
                );
            }
          }
        } else {
          if (cloneSelectedTestList[testIndex].inspection_room?.length === 1) {
            // select next room and select that test of zero index
            if (
              cloneSelectedTestList[testIndex].inspection_room[roomIndex].tests
                ?.length === 1
            ) {
              if (cloneSelectedTestList.length - 1 === Number(testIndex)) {
                cloneSelectedTestList[testIndex - 1].isSelected = true;
                cloneSelectedTestList[
                  testIndex - 1
                ].inspection_room[0].isSelected = true;
                cloneSelectedTestList[
                  testIndex - 1
                ].inspection_room[0].tests[0].isSelected = true;
              } else {
                cloneSelectedTestList[testIndex + 1].isSelected = true;
                cloneSelectedTestList[
                  testIndex + 1
                ].inspection_room[0].isSelected = true;
                cloneSelectedTestList[
                  testIndex + 1
                ].inspection_room[0].tests[0].isSelected = true;
              }
              removeTestId = cloneSelectedTestList[testIndex].name;
              cloneSelectedTestList[testIndex].inspection_room[
                roomIndex
              ].tests = cloneSelectedTestList[testIndex].inspection_room[
                roomIndex
              ].tests.filter(
                (testInRoom) =>
                  Number(testInRoom.id) !== Number(bodyData.testInRoomId)
              );

              cloneSelectedTestList[testIndex].inspection_room =
                cloneSelectedTestList[testIndex].inspection_room.filter(
                  (roomObj) =>
                    Number(roomObj.id) !== Number(bodyData.room_pivot_id)
                );

              cloneSelectedTestList = cloneSelectedTestList.filter(
                (testObj) =>
                  Number(testObj.id) !== Number(bodyData.test_id) &&
                  testObj.name !== bodyData.test_name
              );
            } else {
              cloneSelectedTestList[testIndex].isSelected = true;
              cloneSelectedTestList[testIndex].inspection_room[
                roomIndex
              ].isSelected = true;
              if (
                cloneSelectedTestList[testIndex].inspection_room[roomIndex]
                  .tests.length -
                  1 ===
                Number(testInRoomIndex)
              ) {
                cloneSelectedTestList[testIndex].inspection_room[
                  roomIndex
                ].tests[testInRoomIndex - 1].isSelected = true;
              } else {
                cloneSelectedTestList[testIndex].inspection_room[
                  roomIndex
                ].tests[testInRoomIndex + 1].isSelected = true;
              }
              cloneSelectedTestList[testIndex].inspection_room[
                roomIndex
              ].tests = cloneSelectedTestList[testIndex].inspection_room[
                roomIndex
              ].tests.filter(
                (testInRoom) =>
                  Number(testInRoom.id) !== Number(bodyData.testInRoomId)
              );
            }
          } else {
            cloneSelectedTestList[testIndex].isSelected = true;
            if (
              cloneSelectedTestList[testIndex].inspection_room[roomIndex].tests
                ?.length === 1
            ) {
              if (
                cloneSelectedTestList[testIndex].inspection_room?.length - 1 ===
                Number(roomIndex)
              ) {
                cloneSelectedTestList[testIndex].inspection_room[
                  roomIndex - 1
                ].isSelected = true;
                cloneSelectedTestList[testIndex].inspection_room[
                  roomIndex - 1
                ].tests[0].isSelected = true;
              } else {
                cloneSelectedTestList[testIndex].inspection_room[
                  roomIndex + 1
                ].isSelected = true;
                cloneSelectedTestList[testIndex].inspection_room[
                  roomIndex + 1
                ].tests[0].isSelected = true;
              }
              cloneSelectedTestList[testIndex].inspection_room =
                cloneSelectedTestList[testIndex].inspection_room.filter(
                  (roomObj) =>
                    Number(roomObj.id) !== Number(bodyData.room_pivot_id)
                );
            } else {
              cloneSelectedTestList[testIndex].inspection_room[
                roomIndex
              ].isSelected = true;
              if (
                cloneSelectedTestList[testIndex].inspection_room[roomIndex]
                  .tests.length -
                  1 ===
                Number(testInRoomIndex)
              ) {
                cloneSelectedTestList[testIndex].inspection_room[
                  roomIndex
                ].tests[testInRoomIndex - 1].isSelected = true;
              } else {
                cloneSelectedTestList[testIndex].inspection_room[
                  roomIndex
                ].tests[testInRoomIndex + 1].isSelected = true;
              }
              cloneSelectedTestList[testIndex].inspection_room[
                roomIndex
              ].tests = cloneSelectedTestList[testIndex].inspection_room[
                roomIndex
              ].tests.filter(
                (testInRoom) =>
                  Number(testInRoom.id) !== Number(bodyData.testInRoomId)
              );
            }
          }
        }
      }

      dispatch({
        type: GET_VIEW_REC_ROOM_LIST_BEHAVE_OF_TESTS_SUCCESS,
        payload: cloneSelectedTestList,
      });

      if (!shouldReset && activeApprovedView !== "all") {
        const selectedTest = cloneSelectedTestList.find(
          (test) => test.isSelected === true
        );
        const selectedRoom = selectedTest.inspection_room.find(
          (room) => room.isSelected === true
        );
        const selectedTestInRoom = selectedRoom.tests.find(
          (test) => test.isSelected === true
        );
        dispatch(
          getFeaturesWithTaskBehaveOfTestGivenTestIdAction(
            selectedRoom.id,
            selectedTestInRoom
          )
        );
      }

      return {
        shouldReset,
        cloneSelectedTestList,
        data,
        removeTestId,
      };
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: GET_VIEW_RECOMMENDED_TEST_APPROVE_CLIENT_FAIL,
        payload: err,
      });
      throw Error(err);
    }
  };

export const handleRoomViewTaskSelectionCheckbox =
  (type, taskId) => async (dispatch, getState) => {
    try {
      const {
        fetchViewRecommendedRoomList: { featureAndTaskList = [] },
      } = getState();
      const cloneFeatureAndTaskList = JSON.parse(
        JSON.stringify(featureAndTaskList)
      );

      dispatch({
        type: GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_REQUEST,
      });

      if (cloneFeatureAndTaskList.length) {
        cloneFeatureAndTaskList.forEach((feat) => {
          if (feat?.images?.length) {
            feat?.images.forEach((image) => {
              if (Number(image.id) === Number(taskId)) {
                image.isSelected = type;
              }
            });
          }
        });
      }
      dispatch({
        type: GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_SUCCESS,
        payload: cloneFeatureAndTaskList,
      });
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_FAIL,
        payload: err,
      });
      throw Error(err);
    }
  };

export const handleRoomViewAllTaskSelectionCheckbox =
  (type) => async (dispatch, getState) => {
    try {
      const {
        fetchViewRecommendedRoomList: { featureAndTaskList = [] },
      } = getState();
      const cloneFeatureAndTaskList = JSON.parse(
        JSON.stringify(featureAndTaskList)
      );

      dispatch({
        type: GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_REQUEST,
      });

      if (cloneFeatureAndTaskList.length) {
        cloneFeatureAndTaskList.forEach((feat) => {
          if (feat?.images?.length) {
            feat?.images.forEach((image) => {
              image.isSelected = type;
            });
          }
        });
      }
      dispatch({
        type: GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_SUCCESS,
        payload: cloneFeatureAndTaskList,
      });
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_FAIL,
        payload: err,
      });
      throw Error(err);
    }
  };

export const handleRoomViewDeleteTaskCheckbox =
  () => async (dispatch, getState) => {
    try {
      const {
        fetchViewRecommendedRoomList: { featureAndTaskList = [] },
      } = getState();
      const cloneFeatureAndTaskList = JSON.parse(
        JSON.stringify(featureAndTaskList)
      );

      dispatch({
        type: GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_REQUEST,
      });
      const taskIds = [];
      if (cloneFeatureAndTaskList.length) {
        cloneFeatureAndTaskList.forEach((feat) => {
          if (feat?.images?.length) {
            feat?.images.forEach((image) => {
              if (image.isSelected) {
                taskIds.push(image.id);
              }
            });
          }
        });
      }
      console.log({ taskIds });
      dispatch({
        type: GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_SUCCESS,
        payload: cloneFeatureAndTaskList,
      });
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_ROOMS_FAIL,
        payload: err,
      });
      throw Error(err);
    }
  };

export const handleTestViewTaskSelectionCheckbox =
  (type, taskId) => async (dispatch, getState) => {
    try {
      const {
        fetchViewRecommendedTestList: { featureAndTaskList = [] },
      } = getState();
      const cloneFeatureAndTaskList = JSON.parse(
        JSON.stringify(featureAndTaskList)
      );
      console.log({ featureAndTaskList });
      dispatch({
        type: GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_REQUEST,
      });

      if (cloneFeatureAndTaskList.length) {
        cloneFeatureAndTaskList.forEach((feat) => {
          if (feat?.images?.length) {
            feat?.images.forEach((image) => {
              if (Number(image.id) === Number(taskId)) {
                image.isSelected = type;
              }
            });
          }
        });
      }
      dispatch({
        type: GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_SUCCESS,
        payload: cloneFeatureAndTaskList,
      });
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_FAIL,
        payload: err,
      });
      throw Error(err);
    }
  };

export const handleTestViewAllTaskSelectionCheckbox =
  (type) => async (dispatch, getState) => {
    try {
      const {
        fetchViewRecommendedTestList: { featureAndTaskList = [] },
      } = getState();
      const cloneFeatureAndTaskList = JSON.parse(
        JSON.stringify(featureAndTaskList)
      );

      dispatch({
        type: GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_REQUEST,
      });

      if (cloneFeatureAndTaskList.length) {
        cloneFeatureAndTaskList.forEach((feat) => {
          if (feat?.images?.length) {
            feat?.images.forEach((image) => {
              image.isSelected = type;
            });
          }
        });
      }
      dispatch({
        type: GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_SUCCESS,
        payload: cloneFeatureAndTaskList,
      });
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_FAIL,
        payload: err,
      });
      throw Error(err);
    }
  };

export const handleTestViewDeleteTaskCheckbox =
  () => async (dispatch, getState) => {
    try {
      const {
        fetchViewRecommendedTestList: { featureAndTaskList = [] },
      } = getState();
      const cloneFeatureAndTaskList = JSON.parse(
        JSON.stringify(featureAndTaskList)
      );

      dispatch({
        type: GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_REQUEST,
      });
      const taskIds = [];
      if (cloneFeatureAndTaskList.length) {
        cloneFeatureAndTaskList.forEach((feat) => {
          if (feat?.images?.length) {
            feat?.images.forEach((image) => {
              if (image.isSelected) {
                taskIds.push(image.id);
              }
            });
          }
        });
      }
      console.log({ taskIds });
      dispatch({
        type: GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_SUCCESS,
        payload: cloneFeatureAndTaskList,
      });
    } catch (error) {
      const err = handleReduxError(error);
      dispatch({
        type: GET_VIEW_RECOMMENDED_FEATURE_LIST_BEHAVE_OF_TESTS_FAIL,
        payload: err,
      });
      throw Error(err);
    }
  };
