import React from "react";
import styles from "./test_view_left_side.module.css";
import SelectDropDown from "main/shared_components/SelectDropdown/SelectDropDown";
import TestListing from "./TestListing/TestListing";
import { convertLabelIntoValueAndOption, showToast } from "main/shared/helpers";
import {
  getViewRecRoomListBehaveOfTestAction,
  getViewRecommendedTestListAction,
} from "store/actions/view_test_recommend/viewTestRecommendAction";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { ReactSelectActionTypes } from "main/shared/constants";

const TestView = ({
  testSearchValue,
  setTestSearchValue,
  setBackConfirmationModal,
  setRemoveValues,
  keyForResetCacheOFSelect,
  setKeyForResetCacheOFSelect,
}) => {
  const { insp_id } = useParams();

  const dispatch = useDispatch();

  const fetchTestList = useSelector(
    (state) => state.fetchViewRecommendedTestList
  );

  const { testList, testListLoading, selectedTestList } = fetchTestList;

  const handleOnTestSelection = (value, event) => {
    if (
      event?.action === ReactSelectActionTypes.CLEAR_ALL ||
      event?.action === ReactSelectActionTypes.REMOVE_SINGLE_VALUE
    ) {
      if (event?.removedValues?.length) {
        setRemoveValues(event?.removedValues);
      } else {
        setRemoveValues(event?.removedValue);
      }
      setKeyForResetCacheOFSelect((prev) => prev + 1);
      setBackConfirmationModal(true);
      return;
    }
    if (Array.isArray(value) && value?.length > 5) {
      showToast("Maximum test selection is 5");
    } else {
      setTestSearchValue(value);
    }
  };

  const handleTestDetailsClick = async () => {
    try {
      if (testSearchValue.length) {
        const testIds = [];
        const testNames = [];
        testSearchValue.forEach((selectedTestObj) => {
          const testObj = testList.find(
            (test) => test.id === selectedTestObj.value
          );
          if (testObj) {
            testIds.push(testObj.id);
            testNames.push(testObj.name);
          }
        });
        await dispatch(
          getViewRecRoomListBehaveOfTestAction(insp_id, testIds, testNames)
        );
      } else {
        // reset
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTests = async (search, loadedOptions) => {
    try {
      const testData = await dispatch(
        getViewRecommendedTestListAction(insp_id)
      );
      const options = convertLabelIntoValueAndOption(
        testData,
        "testUniqueId",
        "testUniqueName"
      );
      return {
        options: options,
        hasMore: false,
      };
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };
  return (
    <div className={styles.container}>
      <div>
        <SelectDropDown
          isMulti={true}
          closeMenuOnSelect={false}
          placeholder="Select Test"
          loadOptions={fetchTests}
          searchValue={testSearchValue}
          handleOnChange={handleOnTestSelection}
          onMenuClose={handleTestDetailsClick}
          isSearchable={false}
          resetCacheKey={keyForResetCacheOFSelect}
        />
      </div>
      <div>
        {testListLoading ? (
          <div className={styles.loader}>
            <Spinner animation="border" variant="success" />
          </div>
        ) : (
          <>
            {selectedTestList?.length ? (
              selectedTestList.map((testData, index) => {
                return (
                  <div key={index}>
                    <TestListing data={testData} />
                    <div
                      className={` ${
                        selectedTestList?.length - 1 !== index
                          ? styles.divider
                          : styles.margin_from_bottom
                      }  `}
                    ></div>
                  </div>
                );
              })
            ) : (
              <></>
            )}{" "}
          </>
        )}
      </div>
    </div>
  );
};

export default TestView;
