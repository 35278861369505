import React, { useState, useEffect } from "react";
import styles from "./recommend_test.module.css";
import TestLeftSide from "./TestLeftSide/TestLeftSide";
import TestRightSide from "./TestRightSide/TestRightSide";
import { useSelector, useDispatch } from "react-redux";
import {
  GET_ROOM_LIST_RESET,
  GET_TEST_LIST_RESET,
  SELECTED_ROOM_LIST_RESET,
  SELECTED_TEST_LIST_RESET,
} from "store/types/test_recommend/testRecommendTypes";

const RecommendTest = () => {
  const dispatch = useDispatch();
  const fetchRoomList = useSelector((state) => state.fetchRoomList);
  const { currentSelectedRoom } = fetchRoomList;

  const [testSearchValue, setTestSearchValue] = useState();
  const [roomSearchValue, setRoomSearchValue] = useState([]);
  const [testNotes, setTestNotes] = useState("");
  const [backConfirmationModal, setBackConfirmationModal] = useState(false);
  const [removeValues, setRemoveValues] = useState("");

  const resetAllData = () => {
    setBackConfirmationModal(false);
    setTestSearchValue();
    setRoomSearchValue([]);
    setRemoveValues("");
    setTestNotes("");
    dispatch({
      type: GET_ROOM_LIST_RESET,
    });
    dispatch({
      type: GET_TEST_LIST_RESET,
    });
  };

  const resetOnlySelectedData = () => {
    setBackConfirmationModal(false);
    setTestSearchValue();
    setRoomSearchValue([]);
    setRemoveValues("");
    setTestNotes("");
    dispatch({
      type: SELECTED_TEST_LIST_RESET,
    });
    dispatch({
      type: SELECTED_ROOM_LIST_RESET,
    });
  };

  useEffect(() => {
    resetAllData();
  }, []);

  return (
    <React.Fragment>
      <div className={styles.container}>
        <div className={styles.test_left_side_container}>
          <TestLeftSide
            backConfirmationModal={backConfirmationModal}
            setBackConfirmationModal={setBackConfirmationModal}
            testSearchValue={testSearchValue}
            setTestSearchValue={setTestSearchValue}
            roomSearchValue={roomSearchValue}
            setRoomSearchValue={setRoomSearchValue}
            removeValues={removeValues}
            setRemoveValues={setRemoveValues}
            testNotes={testNotes}
            setTestNotes={setTestNotes}
          />
        </div>
        <div className={styles.test_right_side_container}>
          {!currentSelectedRoom?.observations?.length ? (
            <div className={styles.default_image}>
              <img
                alt="default_image"
                src={
                  require("../../../../../assets/icons/no-task-update.png")
                    .default
                }
                width="288px"
              />
            </div>
          ) : (
            <TestRightSide testNotes={testNotes} resetOnlySelectedData={resetOnlySelectedData}/>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default RecommendTest;
