import React, { useState } from "react";
import styles from "./edit_test_dialog.module.css";
import { Button, Modal } from "react-bootstrap";
import SelectDropDown from "../SelectDropdown/SelectDropDown";

const EditTestDialog = ({
  data = {},
  show = false,
  handleOnClose = () => {},
  handleOnSubmit = () => {},
  options = [],
}) => {
  const [testSearchValue, setTestSearchValue] = useState(options[0]);
  const [sampleTestValue, setSampleTestValue] = useState("");

  const handleOnTestSelection = (value, event) => {
    setTestSearchValue(value);
  };

  const handleOnSubmitButton = () => {
    const payload = {
      testSearchValue,
      sampleTestValue,
    };
    handleOnSubmit(payload);
  };

  //   useEffect(() => {
  //     if (show) {
  //       if (options?.length) {
  //         const option = options.find(
  //           (op) => Number(op.value) === Number(data?.id)
  //         );
  //         setTestSearchValue(option);
  //       }
  //     }
  //   }, [show]);

  return (
    <div>
      <Modal
        show={show}
        onHide={handleOnClose}
        dialogClassName={styles.modal_container}
        centered
        className={styles.modal_container}
      >
        <Modal.Header className={styles.modal_container_header}>
          <Modal.Title id="contained-modal-title">
            <div className={styles.modal_title_container}>
              <div></div>
              <div className={styles.modal_title}>Edit Test</div>
              <div>
                <img
                  className={styles.cross_icon}
                  src={require("assets/icons/Cross1@2x.png").default}
                  alt="icon"
                  onClick={handleOnClose}
                />
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modal_container_body}>
          <div className={styles.search_and_list_cont}>
            <div className={styles.select_test_field}>
              <SelectDropDown
                searchValue={testSearchValue}
                options={options}
                handleOnChange={handleOnTestSelection}
                isSearchable={false}
                isClearable={false}
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="Sample Test"
                className={styles.title_input_field}
                value={sampleTestValue}
                onChange={(e) => setSampleTestValue(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.modal_container_footer}>
          <Button
            className={styles.footer_next_btn}
            onClick={handleOnSubmitButton}
          >
            {/* {customTaskLoading ? (
              <Spinner animation="border" variant="success" />
            ) : ( */}
            "Next"
            {/* )} */}
          </Button>
          <Button className={styles.footer_cancel_btn} onClick={handleOnClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditTestDialog;
