import React from "react";
import styles from "./test_right_side.module.css";
import TaskImageViewTestRec from "main/shared_components/TaskImageViewTestRec/TaskImageViewTestRec";
import { useSelector, useDispatch } from "react-redux";
import { Button, Spinner } from "react-bootstrap";
import {
  handleAllSelectTaskAction,
  handleSingleTaskSelectionAction,
  handleSubmitSelectedTaskWithTestAction,
} from "store/actions/test_recommend/testRecommendAction";
import { createSubmitTestAPIPayload } from "../helperFunction";
import { showToast } from "main/shared/helpers";
import { useParams } from "react-router-dom";

const TestRightSide = (props) => {
  const { insp_id } = useParams();
  const dispatch = useDispatch();
  const fetchTestList = useSelector((state) => state.fetchTestList);
  const { currentSelectedTest } = fetchTestList;
  const fetchRoomList = useSelector((state) => state.fetchRoomList);
  const {
    currentSelectedRoom,
    taskLoading,

    selectedRoomsList = [],
  } = fetchRoomList;
  const submitSelectedRoomWithTest = useSelector(
    (state) => state.submitSelectedRoomWithTest
  );
  const { loading: submitLoading } = submitSelectedRoomWithTest;
  const selectedFeature = currentSelectedRoom?.observations?.find(
    (feature) => feature?.isSelected === true
  );

  const validateApproveRoomButton = () => {
    let selectedTask = false;
    let selectedTest = false;
    selectedRoomsList?.forEach((room) => {
      room?.observations?.forEach((feature) => {
        feature?.tasks?.forEach((task) => {
          if (task.isSelected) {
            selectedTask = true;
          }
        });
      });
    });
    if (currentSelectedTest) {
      selectedTest = true;
    }
    return selectedTask && selectedTest;
  };

  const handleAllSelectTask = (event) => {
    try {
      dispatch(
        handleAllSelectTaskAction(
          event.target.checked,
          currentSelectedRoom?.roomUniqueId,
          selectedFeature?.id
        )
      );
    } catch (error) {}
  };

  const handleSingleTaskSelect = (event, taskId) => {
    try {
      dispatch(
        handleSingleTaskSelectionAction(
          event.target.checked,
          currentSelectedRoom?.roomUniqueId,
          selectedFeature?.id,
          taskId
        )
      );
    } catch (error) {}
  };

  const handleApproveRoomSubmit = async () => {
    try {
      const data = createSubmitTestAPIPayload(
        props?.testNotes,
        currentSelectedTest,
        selectedRoomsList
      );
       await dispatch(
        handleSubmitSelectedTaskWithTestAction(insp_id, data)
      );
      showToast("Test is recommended on selected room", true);
      props.resetOnlySelectedData();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.submit_btn_container}>
        <Button
          size="sm"
          className={styles.submit_btn}
          onClick={!submitLoading ? handleApproveRoomSubmit : () => {}}
          disabled={!validateApproveRoomButton()}
        >
          {submitLoading ? (
            <Spinner animation="border" variant="success" />
          ) : (
            "Submit"
          )}
        </Button>
      </div>
      <div className={styles.room_heading}>{currentSelectedRoom?.roomUniqueName}</div>
      <div>
        <div className={styles.feature_heading}>{selectedFeature?.name}</div>

        {taskLoading ? (
          <>
            <div className={styles.spinner_loader}>
              <Spinner animation="border" variant="success" />
            </div>
          </>
        ) : (
          <>
            <div className={styles.all_selection_container}>
              <div className={styles.submitted_task_heading}>
                Submitted Tasks:
              </div>
              <div className={styles.all_selection}>
                <div className={styles.form_group}>
                  <input
                    checked={selectedFeature?.isAllTaskSelected}
                    type="checkbox"
                    id={`${selectedFeature?.name}checkbox`}
                    onChange={handleAllSelectTask}
                  />
                  <label htmlFor={`${selectedFeature?.name}checkbox`}></label>
                </div>
                <div className={styles.all_selection_heading}>Select All</div>
              </div>
            </div>
            <div className={styles.task_container}>
              {selectedFeature?.tasks?.length ? (
                selectedFeature?.tasks.map((task) => {
                  return (
                    <TaskImageViewTestRec
                      imageOverLay={true}
                      handleTaskSelection={(e) =>
                        handleSingleTaskSelect(e, task?.id)
                      }
                      index={task?.id}
                      isTaskChecked={task?.isSelected}
                      key={task?.id}
                      taskObj={{
                        thumbnail: task?.image_url,
                        image_name: task?.name,
                      }}
                    />
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TestRightSide;
