import {
  LOADER_STATE,
  LOG_IN,
  SET_HEADER,
  SET_SEARCH_LABEL,
  SHOW_SEARCH,
} from "../../store/actions";
/* eslint-disable react-hooks/exhaustive-deps */
import {
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
} from "@fortawesome/free-brands-svg-icons";
import { useEffect, useRef } from "react";

import Compressor from "compressorjs";
import axios from "axios";
import currency from "currency.js";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { parsePhoneNumber } from "libphonenumber-js";
import { profileRequest } from "../../services/auth-service";
import toast from "react-hot-toast";

var utc = require("dayjs/plugin/utc");

dayjs.extend(customParseFormat);

export const isAuthenticated = () => {
  return localStorage.getItem("access_token") ? true : false;
};
export const checkTasks = (tasks) => {
  let totalTasks = tasks?.data?.filter((task) => task.test_assigned);
  console.log(totalTasks, "totalTaskstotalTaskstotalTasks");
  return totalTasks;
};

export const isMaintenance = () => {
  profileRequest()
    .then((res) => {})
    .catch((err) => console.log(err.response))
    .finally(() => {});
};
export const color = {
  primary300: "#57932F",
  light20: "#2F481E",
  error: "#B44A4A",
  primary500: "#84857A",
  light50: "#84857A",
  alert: "#EAE34C",
  primary900: "#F1F9D",
  light80: "#DEE2DF",
  accent500: "#B44A9A",
  secondary500: "#4AA2B4",
  light90: "#E7ECE8",
  secondary800: "CFE7EC",
  light100: "#FFFFFF",
  secondary900: "#EDF6F8",
};

export const fonts = {
  opensans: "Open Sans",
  exo2: "Exo 2",
};

export const setHeader = (dispatch, payload) => {
  dispatch({
    type: SET_HEADER,
    payload,
  });
};

export const phoneNumberFormat = (item) => {
  if (
    (item?.phone_number?.charAt(0) === "1" ||
      item?.phone_number?.charAt(0) === 1) &&
    item?.phone_number?.length === 11
  ) {
    return `+${item?.phone_number.substring(
      0,
      1
    )} ${item?.phone_number.substring(1, 4)} ${item?.phone_number.substring(
      4,
      7
    )} ${item?.phone_number.substring(7)}
    `;
  } else {
    if (item?.phone_number[0] !== "0") {
      return parsePhoneNumber(`+${item?.phone_number}`).formatInternational();
    } else {
      return `+${item?.phone_number}`;
    }
  }
};
export const getErrorMsg = (err) => {
  let errMsg = err.noInternet ?? "";
  if (err?.response?.status === 500) {
    return "Temporarily Down for maintenance.";
  }
  if (err?.response?.status === 400) {
    let msg_val = err?.response?.data?.message;
    let msg_err =
      msg_val.charAt(0).toUpperCase() + msg_val.slice(1).toLowerCase();
    return msg_err;
  }

  const data = err?.response?.data;

  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      for (let i = 0; i < data[key].length; i++) {
        const element = data[key][i];
        errMsg = errMsg + element + "\n";
      }
    }
  }
  errMsg = errMsg || "Temporarily Down for maintenance.";
  return errMsg;
};

export const truncate = (str, len = 20) => {
  return str?.length > len ? str?.substring(0, len) + "..." : str;
};

export const showToast = (msg, success = false, dispatch) => {
  if (success === true) {
    toast.success(msg);
  } else {
    toast.error(msg);
  }
  if (dispatch) {
    setTimeout(() => {
      dispatch({
        type: LOADER_STATE,
        payload: true,
      });
    }, 2000);
  }

  // }
};

export const utcDate = (datenTime) => {
  const d1 = dayjs(
    datenTime,
    { format: "YYYY-MM-DD HH:mm:ss" /* utc: true */ },
    true
  );
  const d2 = dayjs(
    dayjs(datenTime, "MM-DD-YYYY HH:mm:ss", true).format("YYYY-MM-DD HH:mm:ss"),
    {
      utc: true,
    }
  );
  if (d1.isValid()) {
    return d1.format("MM/DD/YYYY");
  } else if (d2.isValid()) {
    return d2.format("MM/DD/YYYY");
  } else {
    return "Invalid Date";
  }
};

export const timeConversionUTC = (dateWithTime) => {
  dayjs.extend(utc);
  dateWithTime = dayjs(dateWithTime).format("MM-DD-YYYY HH:mm:ss");
  // console.log(dayjs(dateWithTime, "YYYY-MM-DD HH:mm:ss", true));

  // var dateInUtc = new Date(Date.parse(dateWithTime + " UTC")); // in 12hr format
  // console.log(
  //   dayjs(dateWithTime, "MM-DD-YYYY HH:mm:ss", true)
  //     .utc("Z")
  //     .local()
  //     .format(" hh:mm A ")
  // );
  // console.log(dateWithTime, "dateWithTimedateWithTime");
  // dateWithTime = dateWithTime.replace(" ", "T");
  // console.log(dateWithTime, "dateSplitdateSplit");
  // var dateInUtc = new Date(Date.parse(dateWithTime));
  // let dateSplit = dateInUtc?.toLocaleTimeString()?.split(":");
  // let hourSet = dateSplit[2]?.split(" ");
  // if (hourSet) {
  //   return dateSplit[0] + ":" + dateSplit[1] + " " + hourSet[1];
  // }
  // return dateSplit[0] + ":" + dateSplit[1];
  return dayjs(dateWithTime, "MM-DD-YYYY HH:mm:ss", true)
    .utc()
    .local()
    .format(" hh:mm A ");
};

export const timeConversion = (dateWithTime) => {
  // var dateInUtc = new Date(Date.parse(dateWithTime + " UTC")); // in 12hr format
  let dateSplit = dateWithTime?.split(" ");
  let time = dateSplit[1].split(":");
  let hour = parseInt(time[0]);
  let minutes = time[1];
  let hourSet = hour >= 12 ? "PM" : "AM";
  return (hour % 12 || 12) + ":" + minutes + " " + hourSet;
};

export const captalizeFirst = (word) => {
  let newWord = word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase();
  return newWord;
};

export const getAddress = (address) => {
  return (
    (address?.address1 ? address?.address1 + ", " : "") +
    (address?.address2 ? address.address2 + ", " : "") +
    (address?.city ? address?.city + ", " : "") +
    (address?.state ? address?.state : "") +
    (address?.zipcode ? ", " + address?.zipcode : "")
  );
};
const getClickableLink = (link) => {
  return link?.startsWith("http://") || link?.startsWith("https://")
    ? link
    : `http://${link}`;
};
export const parsedUrls = (notes = "") => {
  let expression =
    /([\w+]+\:\/\/)?([\w\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\#\.]?[\w-]+)*\/?/gm;

  let result = "";
  if (notes) {
    result = notes?.toString()?.replace(expression, (p) => {
      return `<a target="_blank" href="${getClickableLink(p)}">${p} </a>`;
    });
  }
  return result;
};

export const inspectionStatusName = (value) => {
  if (value === "completed") {
    return "Completed";
  } else if (value === "pending") {
    return "Pending";
  } else if (value === "in_progress") {
    return "In Progress";
  }
};
export const getStringFromObject = (object, key, separator) => {
  const dotSeparator = (object, key) => {
    const nest = key.split(".");
    let tempObj = object[nest[0]];
    for (let i = 1; i < nest.length; i++) {
      tempObj = tempObj[nest[i]];
    }
    return tempObj;
  };

  if (key.includes(".")) {
    return dotSeparator(object, key);
  } else if (Array.isArray(key)) {
    let temp = key.map((element) => dotSeparator(object, element));
    return temp.join(separator);
  } else {
    return object[key];
  }
};

export const utcToLocalDateTime = (value) => {
  //"2021-03-11 08:39:36"
  const dateInUtc = new Date(Date.parse(value + " UTC"));
  const formatedDate = dateInUtc.toLocaleDateString();
  const result = [formatedDate, timeConversionUTC(value)];
  return result;
};

export const deleteArrayIndex = (index, stateArray) => {
  var array = [...stateArray];
  array.splice(index, 1);
  return array;
};

export const updateArrayIndex = (index, stateArray, replacement) => {
  var array = [...stateArray];
  array[index] = replacement;
  return array;
};

export const USD = (value) =>
  currency(value, { separator: "", symbol: "$", precision: 2 });

export const priceInputHandle = (e) => {
  let start = e.target.selectionStart;
  let val = e.target.value;
  e.target.value = USD(e.target.value).format();
  if (val.length > 0) {
    e.target.value = USD(e.target.value).format();
    e.target.setSelectionRange(start, start);
  }
};

export const getLocalUser = () => {
  const user = localStorage.getItem("user");
  // console.log(user);
  return user ? JSON.parse(atob(user)) : null;
};

export const refreshProfile = (dispatch) => {
  profileRequest().then((profile) => {
    dispatch({
      type: LOG_IN,
      payload: profile.data,
    });
    setTimeout(() => {
      showToast("Company Details updated successfully.", true);
    }, 500);
  });
};

export const roleNames = {
  superAdmin: "super_admin",
  bu_homeowner: "customer",
  buAdmin: "admin",
  bu_inspector: "inspector",
};

export const roleNamesForAPI = {
  superAdmin: "po",
  customer: "cust",
  admin: "am",
};

export const checkCustomer = (userInfo, propertyData) => {
  return userInfo?.role === roleNames.superAdmin ||
    userInfo?.role === roleNames.buAdmin
    ? parseInt(propertyData?.customer_id) ?? null
    : parseInt(userInfo?.id);
};
export const roleToText = (name) => {
  if (name === roleNames.superAdmin) {
    return "Super Admin";
  } else if (name === roleNames.bu_homeowner) {
    return "Home Owner";
  } else if (name === roleNames.buAdmin) {
    return "Account Manager";
  } else if (name === roleNames.bu_inspector) {
    return "Inspector";
  }
};
export const getCreatedAtDate = (dateWithT) => {
  const arr = dateWithT.split("T")[0]?.split("-");
  return `${arr[1]}/${arr[2]}/${arr[0]}`;
};

export const defaultAvatar = require("../../assets/icons/name.png").default;
export const changeImage = (url, change) => {
  if (change) {
    return url;
  } else {
    return url;
  }
};
export const permissions = [
  {
    name: "Manage Tools",
    description: "Add, Edit, or Delete Inspections",
    value: "tools",
    routePath: "/tools",
  },
  {
    name: "Manage Customers",
    description: "Add or Edit Customers",
    value: "customers",
    routePath: "/customers",
  },
  {
    name: "Manage Properties",
    description: "Add, Edit, or Delete Properties",
    value: "properties",
    routePath: "/properties",
  },
  {
    name: "Manage Inspections",
    description: "Add or Edit Inspections",
    value: "inspections",
    routePath: "/inspections",
  },
  {
    name: "Manage Settings",
    description: "Add or Delete Users",
    value: "settings",
    routePath: "/settings",
  },
];

export const stripeCards = {
  amex: { name: "American Express", icon: faCcAmex },
  diners: { name: "Diners Club", icon: faCcDinersClub },
  discover: { name: "Discover", icon: faCcDiscover },
  jcb: { name: "JCB", icon: faCcJcb },
  mastercard: { name: "Mastercard", icon: faCcMastercard },
  visa: { name: "Visa", icon: faCcVisa },
  unionpay: { name: "UnionPay", icon: faCreditCard },
};

export const updateHeaderSearch = (dispatch, show, label) => {
  dispatch({ type: SHOW_SEARCH, payload: show });
  dispatch({ type: SET_SEARCH_LABEL, payload: label });
};

export const useDidUpdateEffect = (fn, inputs) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      fn();
    }
    return () => {
      didMountRef.current = true;
    };
  }, inputs);
};

// TODO : "will remove "REMOVE TAG" when clients says open inspector mode"
export const filtersObj = () => {
  return JSON.parse(
    JSON.stringify({
      self_inspect: {
        val: false,
        name: "Home Owner Assigned",
        ex_role: "",
        remove: true,
      },
      wit_inspect: {
        val: false,
        name: "Inspector Assigned",
        ex_role: "",
        remove: true,
      },
      un_assigned: {
        val: false,
        name: "Not Assigned",
        ex_role: "",
        remove: true,
      },
      pending: { val: false, name: "Pending", ex_role: "" },
      in_progress: { val: false, name: "In Progress", ex_role: "" },
      completed: {
        val: false,
        name: "Inspection Completed",
      },
      paid: { val: false, name: "Paid", ex_role: "" },
      un_paid: { val: false, name: "Un Paid", ex_role: "" },
      deactivate: {
        val: false,
        name: "Deactivated",
        ex_role: roleNames.bu_homeowner,
      },
      active: { val: false, name: "Active", ex_role: roleNames.bu_homeowner },
    })
  );
};

export const getFilters = () => {
  const params = new URLSearchParams(window.location.search);

  let obj = filtersObj();
  params.getAll("filter").forEach((f) => {
    console.log(f);
    if (obj[f]) {
      obj[f].val = true;
    }
  });
  return obj;
};
export const insp_type = (insp_val) => {
  let insps = [
    { value: "self_inspect", label: "Self Inspector" },
    { value: "wit_inspect", label: "WIT Inspector" },
  ];

  return insps.filter((val) => val.value === insp_val && val?.label);
};

export const itemsIds = {
  HVAC: 8,
  APPLIANCES: 9,
};

export const paid_label = (paid_status) => {
  if (paid_status === "paid") {
    return [
      {
        value: "paid",
        label: "Paid",
      },
    ];
  } else {
    return [
      {
        value: "un_paid",
        label: "Un Paid",
      },
    ];
  }
};

export const paid_value = (paid_status) => {
  if (paid_status === "paid") {
    return "paid";
  } else {
    return "un_paid";
  }
};

export const getReduxPermissions = (roles) => {
  const permissions = {
    tools: false,
    customers: false,
    properties: false,
    inspections: false,
    settings: false,
  };

  roles.forEach((i) => {
    permissions[i.alias] = true;
  });

  return permissions;
};

export const replaceByStorageURL = (url) => {
  return url?.replace(/wit-compression/g, "wit-storage");
};

export const markersArray = (markerjs2) => {
  return [
    "FrameMarker",
    markerjs2.ArrowMarker,
    markerjs2.FreehandMarker,
    markerjs2.EllipseFrameMarker,
    markerjs2.EllipseMarker,
    markerjs2.CoverMarker,
    markerjs2.FrameMarker,
    markerjs2.CurveMarker,
    markerjs2.HighlightMarker,
    markerjs2.LineMarker,
    markerjs2.TextMarker,
  ];
};

export function getBase64(url) {
  let valid_url = url
    ? url
    : require("../../assets/icons/dummy-image.png").default;
  axios.defaults.headers = {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  };
  return axios
    .get(valid_url, {
      responseType: "arraybuffer",
    })
    .then((response) =>
      Buffer.from(response.data, "binary").toString("base64")
    );
}

export const inputNumberKeyPress = (e) => {
  const { value, maxLength } = e.target;
  console.log(e.which);
  if (
    e.which === 69 ||
    e.which === 101 ||
    e.which == 45 ||
    e.which == 43 ||
    e.which == 46
  ) {
    e.preventDefault();
  }
  if (value.length >= maxLength) {
    e.preventDefault();
    return;
  }
};

export const valueExists = (property) => {
  if (
    property?.address1 &&
    property?.address1.trim() &&
    property?.city &&
    property?.city.trim() &&
    property?.state &&
    property?.zipcode &&
    property?.customer_id &&
    property?.zipcode.length > 4 &&
    property?.zipcode.trim()
  ) {
    return true;
  }
  return false;
};
export function compressImage(imgToCompress) {
  // showing the compressed image
  let compressedImageBlob;
  console.log(imgToCompress, "imgToCompressimgToCompress");

  let resizingFactor = 1;
  let quality = 0.1;
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  const originalWidth = imgToCompress.w;
  const originalHeight = imgToCompress.h;

  const canvasWidth = originalWidth * resizingFactor;
  const canvasHeight = originalHeight * resizingFactor;

  canvas.width = canvasWidth;
  canvas.height = canvasHeight;

  context.drawImage(
    imgToCompress.newImg,
    0,
    0,
    originalWidth * resizingFactor,
    originalHeight * resizingFactor
  );

  // reducing the quality of the image
  canvas.toBlob(
    (blob) => {
      if (blob) {
        compressedImageBlob = blob;
        console.log(
          blob,
          "compressedImageBlobcompressedImageBlobcompressedImageBlob",
          URL.createObjectURL(compressedImageBlob)
        );
        // compressedImage.src = URL.createObjectURL(compressedImageBlob);
        // document.querySelector("#size").innerHTML = bytesToSize(blob.size);
      }
    },
    imgToCompress.dataImage.type,
    quality
  );
}

export function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export async function dataUrlToFile1(dataUrl, fileName) {
  const res = await fetch(dataUrl);
  console.log(res, "resresresres");
  const blob = await res.blob();
  // const blob: Blob = res.data;
  console.log(blob, "blob");

  let data = new Promise((resolve) => {
    new Compressor(blob, {
      quality: 0.5,
      convertSize: 500000,
      convertTypes: ["image/png", "image/jpeg", "image/jpg"],
      success(result) {
        // replace extension from current image
        let updatedFile =
          fileName.substr(0, fileName.lastIndexOf(".")) +
          "." +
          result.type.split("/").pop();
        console.log(result, "updated file");
        result.name = updatedFile;
        resolve(result);
      },
      error(err) {
        console.log(err.message);
      },
    });
  });
  return data;
}

function readableBytes(bytes) {
  const i = Math.floor(Math.log(bytes) / Math.log(1024)),
    sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  return (bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i];
}

export async function dataUrlToFile(dataUrl, fileName) {
  // let newImg = document.createElement("img");

  const res = await fetch(dataUrl);
  const blob = await res.blob();
  let dataImage = new File([blob], fileName, {
    type: blob.type,
  });

  return dataImage;
}
export const getFallbackUrl = (roles) => {
  if (roles) {
    const fallback = permissions.find((p) =>
      roles.find((r) => p.value === r.alias)
    );
    return fallback.routePath === "/properties"
      ? "/tools/tests"
      : fallback.routePath;
  } else {
    return "/properties";
  }
};

export const handleReduxError = (error) => {
  let err = "";
  if (error?.message) {
    err = error.message;
  }
  if (!err) {
    err = "Network Error";
  }
  return err;
};

export const getShortSentenceInString = (name, maxCharacter = 8) => {
  let result = "";
  if (name && name.length > maxCharacter) {
    for (let index = 0; index < maxCharacter; index++) {
      result = result + name[index];
    }
    result = result + "...";
    return result;
  } else {
    return name;
  }
};

export const acceptInstructionalImageTypes = (type) => {
  const imageTypes = ["image/png", "image/jpeg", "image/jpg"];
  return imageTypes.indexOf(type) !== -1;
};

export const getCurrentRoleForAPI = (role) => {
  if (roleNamesForAPI[role]) {
    return roleNamesForAPI[role];
  } else {
    return roleNamesForAPI.superAdmin;
  }
};

export const convertLabelIntoValueAndOption = (
  list = [],
  valueField = "id",
  optionField
) => {
  const options = [];
  list.forEach((data) => {
    const singleOption = {
      value: data[valueField],
      label: data[optionField],
    };
    options.push(singleOption);
  });
  return options;
};
