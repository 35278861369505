import React from "react";
import styles from "./test_and_room_listing.module.css";
import NameListing from "./NameListing/NameListing";
import { handleSelectedRoomFromTestAction } from "store/actions/view_test_recommend/viewTestRecommendAction";
import { useDispatch } from "react-redux";

const TestListing = ({ data }) => {
  const dispatch = useDispatch();

  const handleSelectedRoom = (type, room_pivot_id, testInRoomId, data) => {
    const testObj = {
      id: data?.testUniqueId,
      name: data?.testUniqueName,
    };
    dispatch(
      handleSelectedRoomFromTestAction(
        type,
        room_pivot_id,
        testInRoomId,
        testObj
      )
    );
  };

  return (
    <div key={data?.testUniqueId}>
      <div className={styles.listing_detail}>
        <div className={styles.listing_heading}>{data?.testUniqueName}</div>
      </div>
      <NameListing
        listingData={data.inspection_room}
        key={data?.testUniqueId}
        handleSelectedList={(type, room_pivot_id, testInRoomId) =>
          handleSelectedRoom(type, room_pivot_id, testInRoomId, data)
        }
      />
    </div>
  );
};

export default TestListing;
