import React, { useState } from "react";
import styles from "./test_view_right_side.module.css";
import { useSelector, useDispatch } from "react-redux";
import { approvedClientStatus } from "main/shared/constants";
import { Button, Spinner } from "react-bootstrap";
import TaskImageViewTestRec from "main/shared_components/TaskImageViewTestRec/TaskImageViewTestRec";
import {
  handleApprovedTaskFromTestAction,
  handleTestEditorNewImageAction,
  handleTestViewAllTaskSelectionCheckbox,
  handleTestViewDeleteTaskCheckbox,
  handleTestViewTaskSelectionCheckbox,
} from "store/actions/view_test_recommend/viewTestRecommendAction";
import { showToast } from "main/shared/helpers";
import { useParams } from "react-router-dom";

const TestView = ({
  resetSelectedTestData,
  keyForResetCacheOFSelect,
  setKeyForResetCacheOFSelect,
  testSearchValue,
  setTestSearchValue,
}) => {
  const [showCheckboxOnTask, setShowCheckboxOnTask] = useState(false);

  const dispatch = useDispatch();
  const { insp_id } = useParams();

  const fetchTestList = useSelector(
    (state) => state.fetchViewRecommendedTestList
  );
  const {
    currentSelectedRoom,
    currentSelectedTest,
    currentSelectedTestInRoom,
    featureAndTaskList,
    featureListLoading,
    taskEditImageLoading,
    approveClientLoading,
  } = fetchTestList;
  const isClientApproved =
    currentSelectedTestInRoom?.status === approvedClientStatus.APPROVE;

  const handleImageEditBtn = async (imgObj, task) => {
    try {
      const result = await dispatch(
        handleTestEditorNewImageAction(imgObj.src, task.image_url, task.id)
      );
      if (result?.Message) {
        showToast("Image edit successfully", true);
        await imgObj.callback();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleApproveByClient = async () => {
    const imageIds = [];
    if (featureAndTaskList?.length) {
      featureAndTaskList.forEach((feat) => {
        if (feat?.images.length) {
          feat.images.forEach((task) => {
            imageIds.push(task.id);
          });
        }
      });
    }

    try {
      const body = {
        test_id: currentSelectedTest?.id,
        test_name: currentSelectedTest?.name,
        room_pivot_id: currentSelectedRoom?.id,
        testInRoomId: currentSelectedTestInRoom?.id,
        testInRoomName: currentSelectedTestInRoom?.name,
        testInRoomTestId: currentSelectedTestInRoom?.test_id,
        image_id: imageIds,
      };
      const response = await dispatch(
        handleApprovedTaskFromTestAction(insp_id, body, !isClientApproved)
      );
      if (response.removeTestId) {
        setTestSearchValue(
          testSearchValue.filter(
            (testObj) => testObj.label !== response.removeTestId
          )
        );
      }
      if (response?.shouldReset) {
        resetSelectedTestData();
        setKeyForResetCacheOFSelect((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleIsTaskSelected = (event, taskId) => {
    dispatch(handleTestViewTaskSelectionCheckbox(event.target.checked, taskId));
  };

  const handleShowCheckboxOnTask = () => {
    setShowCheckboxOnTask(!showCheckboxOnTask);
  };

  const handleCancelCheckboxOnTask = () => {
    dispatch(handleTestViewAllTaskSelectionCheckbox(false));
    handleShowCheckboxOnTask();
  };

  const handleSubmitCheckboxOnTask = () => {
    dispatch(handleTestViewDeleteTaskCheckbox());
    handleCancelCheckboxOnTask();
  };

  const handleSelectAllCheckboxOnTask = () => {
    dispatch(handleTestViewAllTaskSelectionCheckbox(true));
  };

  return (
    <div className={styles.container}>
      <div className={styles.test_heading_container}>
        <div className={styles.test_heading}>{currentSelectedTest?.name}</div>
        <div className={styles.test_top_btn}>
          <div className={styles.approve_container}>
            {approveClientLoading ? (
              <div className={styles.app_client_loader}>
                <Spinner animation="border" variant="success" />
              </div>
            ) : (
              <Button
                size="lg"
                className={
                  isClientApproved ? styles.approve_btn : styles.un_approve_btn
                }
                onClick={handleApproveByClient}
              >
                {isClientApproved ? (
                  <>
                    <img
                      src={require("assets/icons/check-green.png").default}
                      alt={"check_image"}
                      className={styles.check_image}
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={require("assets/icons/check-black.png").default}
                      alt={"check_image"}
                      className={styles.check_image}
                    />
                  </>
                )}
                Approved by Client
              </Button>
            )}
          </div>
          <div className={styles.delete_icon_cont}>
            {!showCheckboxOnTask ? (
              <Button onClick={handleShowCheckboxOnTask}>icon</Button>
            ) : (
              <div>
                <Button onClick={handleSubmitCheckboxOnTask}>Submit</Button>
                <Button onClick={handleSelectAllCheckboxOnTask}>
                  Select All
                </Button>
                <Button onClick={handleCancelCheckboxOnTask}>Cancel</Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <div className={styles.room_hading_container}>
          <b>
            {currentSelectedTestInRoom?.name}:{" "}
            {currentSelectedTestInRoom?.location}
          </b>
        </div>
      </div>

      <div className={styles.feat_container}>
        {featureListLoading ? (
          <div className={styles.loader}>
            <Spinner animation="border" variant="success" />
          </div>
        ) : (
          <div className={styles.task_container}>
            {featureAndTaskList?.length ? (
              featureAndTaskList.map((feature, index) => {
                return feature?.images?.length ? (
                  feature?.images.map((task) => {
                    return (
                      <TaskImageViewTestRec
                        showImageAsEditorMode={true}
                        imgEditorLoading={taskEditImageLoading}
                        handleImageEditBtn={(e) => handleImageEditBtn(e, task)}
                        imageOverLay={true}
                        showIsSelectedCheckbox={showCheckboxOnTask}
                        handleTaskSelection={(e) =>
                          handleIsTaskSelected(e, task?.id)
                        }
                        index={task?.id}
                        isTaskChecked={task?.isSelected}
                        key={task?.id}
                        taskObj={{
                          thumbnail: task?.image_url,
                          image_name: `${task?.name} (${feature?.name})`,
                        }}
                      />
                    );
                  })
                ) : (
                  <></>
                );
              })
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TestView;
