import React, { useState } from "react";
import styles from "./task_image_view_test_rec.module.css";
import { getShortSentenceInString } from "../../shared/helpers";
import CustomTooltip from "main/components/Tooltip/Tooltip";
import FullSizeImageModal from "main/components/FullSizeImageModal";

const TaskImageViewTestRec = ({
  index,
  taskObj,
  isTaskChecked,
  handleTaskSelection,
  imageOverLay = false,
  showImageAsEditorMode = false,
  imgEditorLoading = false,
  showIsSelectedCheckbox = true,
  handleImageEditBtn = () => {},
}) => {
  const [imageFullScreenModal, setImageFullScreenModal] = useState(false);

  const handleFullSizeImageModal = () => {
    setImageFullScreenModal(!imageFullScreenModal);
  };

  return (
    <div key={index}>
      <div
        className={`${styles.inst_img_cont} ${
          imageOverLay ? styles.image_container : {}
        }`}
      >
        <img
          width="100%"
          className={styles.taskImg}
          src={taskObj?.thumbnail}
          alt={taskObj?.image_name}
        />
        {imageOverLay ? (
          <div className={styles.overlay_container}>
            <img
              className={styles.overlay}
              onClick={handleFullSizeImageModal}
              src={require("assets/icons/view-img.png").default}
              alt="fullscreen_icon"
            />
          </div>
        ) : (
          <></>
        )}
        {showIsSelectedCheckbox ? (
          <div className={styles.checkbox_container}>
            <div className={styles.form_group}>
              <input
                checked={isTaskChecked}
                type="checkbox"
                id={`checkbox${taskObj?.thumbnail}${index}`}
                onChange={handleTaskSelection}
              />
              <label htmlFor={`checkbox${taskObj?.thumbnail}${index}`}></label>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className={styles.inst_img_title}>
        <CustomTooltip
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          text={taskObj?.image_name}
        >
          <div className={styles.img_title_chip}>
            {getShortSentenceInString(taskObj?.image_name, 10)}
          </div>
        </CustomTooltip>
      </div>
      <FullSizeImageModal
        selectedSubmittedImage={taskObj?.thumbnail}
        onHide={handleFullSizeImageModal}
        show={imageFullScreenModal}
        showImageAsEditorMode={showImageAsEditorMode}
        handleImageEditBtn={handleImageEditBtn}
        imgEditorLoading={imgEditorLoading}
      />
    </div>
  );
};

export default TaskImageViewTestRec;
