import React from "react";
import styles from "./test_and_room_listing.module.css";
import NameListing from "./NameListing/NameListing";
import { useDispatch } from "react-redux";
import { handleSelectedTestFromRoomAction } from "store/actions/view_test_recommend/viewTestRecommendAction";

const RoomListing = ({ data }) => {
  const dispatch = useDispatch();

  const handleSelectedTest = (type, testId, testInTestId, data) => {
    const roomObj = {
      id: data?.id,
      name: data?.description,
    };
    dispatch(
      handleSelectedTestFromRoomAction(type, testId, testInTestId, roomObj)
    );
  };

  return (
    <div key={data?.id}>
      <div className={styles.listing_detail}>
        <div className={styles.listing_heading}>{data?.description}</div>
      </div>
      <NameListing
        listingData={data?.tests}
        key={data?.id}
        handleSelectedList={(type, testId, testInTestId) =>
          handleSelectedTest(type, testId, testInTestId, data)
        }
      />
    </div>
  );
};

export default RoomListing;
