import React, { Suspense, useEffect, useState } from "react";
import {
  deleteObservation,
  getObservationByRoom,
  getObservationsById,
} from "../../../../../services/ObservationService";
/* eslint-disable react-hooks/exhaustive-deps */
import {
  getInspectionbyId,
  updateInspectionbyId,
} from "../../../../../services/InspectionService";
import { showToast } from "../../../../shared/helpers";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import ObservationModals from "./ObservationModals";
import { Spinner } from "react-bootstrap";
// import bin-icon from '../../../../../public/bin-icon.png'
import { useHistory } from "react-router-dom";
import { ObservationRightSide as ORS } from "./ObservationRightSide";
import { ObservationLeftSide as OLS } from "./ObservationLeftSide";

export default function Observation({ userInfo, generalInfo }) {
  const { insp_id } = useParams();
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const taskId = params.get("task");
  const roomId = params.get("room");

  const [taskIdParams, settaskIdParams] = useState(taskId);
  const [imageFullScreenModal, setImageFullScreenModal] = useState(false);

  const [RoomName, setRoomName] = useState();
  const [selectedRoomId, setSelectedRoomId] = useState(roomId);
  const [loader, setLoader] = useState(false);

  const [conditionalViewer, setConditionalViewer] = useState("");

  // Submitted Image
  const [selectedSubmittedImageIndex, setselectedSubmittedImageIndex] =
    useState();
  const [selectedSubmittedImageModal, setselectedSubmittedImageModal] =
    useState(false);
  const [selectedSubmittedImageNumber, setselectedSubmittedImageNumber] =
    useState();

  // rejected
  const [rejectedModal, setrejectedModal] = useState(false);
  const [BitImage, setBitImage] = useState();

  // rejected view
  const [selectedSubmittedImageModalView, setselectedSubmittedImageModalView] =
    useState(false);
  const [selectedInstructImageModalView, setselectedInstructImageModalView] =
    useState(false);
  const [selectedInstructImageNumber, setselectedInstructImageNumber] =
    useState();
  const [selectedInstructImageIndex, setselectedInstructImageIndex] =
    useState();
  const [inspectionDetails, setinspectionDetails] = useState();
  // edit image
  const [rejectedModalAddnote, setrejectedModalAddnote] = useState(false);

  const [photosLoader, setPhotosLoader] = useState(false);

  //submitInspection
  const [submitInspection, setSubmitInspection] = useState(false);

  // Tasks listing
  const [tasks, settasks] = useState();
  const [selectedTask, setselectedTask] = useState();
  const [selectedTaskIndex, setselectedTaskIndex] = useState(0);
  const [tasksPage, settasksPage] = useState(1);
  const [taskpageIndex, settaskpageIndex] = useState();
  const [selectedTaskNumber, setselectedTaskNumber] = useState();
  const [activePagetasks, setActivePagetasks] = useState(1);
  const [itemsCountPerPagetasks, setItemsCountPerPagetasks] = useState(0);
  const [totalItemsCounttasks, setTotalItemsCounttasks] = useState(0);
  const [refreshlinkTasks, setrefreshlinkTasks] = useState(false);
  const [checkedData, setCheckedData] = useState();
  const [checkedCount, setCheckedCount] = useState();

  // left side loader
  const [leftSideLoader, setleftSideLoader] = useState(false);

  // Add instructional image
  const [addInstructionalModal, setaddInstructionalModal] = useState(false);

  // Add Feature
  const [openAddFeatureModal, setOpenAddFeatureModal] = useState(false);

  // Add Task
  const [openAddTaskModal, setOpenAddTaskModal] = useState(false);

  // AddWholeTaskModal
  const [AddWholeTaskImagesModal, setAddWholeTaskImagesModal] = useState(false);

  const [loadingImg, setloadingImg] = useState(true);
  const [counterImg, setcounterImg] = useState(0);

  // test recommended
  const [testRecommendationModal, settestRecommendationModal] = useState(false);
  const [refreshIcon, setrefreshIcon] = useState(false);
  const [notRootLevelRefresh, setnotRootLevelRefresh] = useState(false);
  const [redirectedLink, setredirectedLink] = useState(taskId ? true : false);
  const [currentRefresh, setcurrentRefresh] = useState(false);

  // confirmation Modal
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmModals, setConfirmModals] = useState(false);

  //test confirm
  const [confirmTestModal, setConfirmTestModal] = useState(false);

  const [showCheckbox, setShowCheckbox] = useState(false);
  const [isChecked, setisChecked] = useState(false);
  const [deleteTask, setdeleteTask] = useState(false);

  const [showApprovedEditModal, setShowApprovedEditModal] = useState(false);

  const ConfirmationModal = React.lazy(() =>
    import("../../../../components/ConfirmationModal/ConfirmationModal")
  );

  const history = useHistory();

  const checkDisable = useSelector((state) => state.inspectionDisable.disable);

  const updateImageFullScreenModal = () => {
    setImageFullScreenModal(!imageFullScreenModal);
  };

  useEffect(() => {
    if (selectedRoomId && !taskIdParams) {
      setleftSideLoader(true);
      setLoader(true);
      getObservation(tasksPage);
    }
  }, [tasksPage]);

  const [totalImages, setTotalImages] = useState([]);
  const [totalInstImgaes, settotalInstImgaes] = useState([]);
  useEffect(() => {
    const totalSubImg = [];
    const totalInst = [];
    let resp = selectedTask?.images?.map((img, i) => {
      if (img.image_url) {
        totalSubImg.push(i);
      } else {
        totalInst.push(i);
      }
    });
    setTotalImages(totalSubImg);
    settotalInstImgaes(totalInst);
  }, [selectedTask]);

  useEffect(() => {
    if (selectedRoomId && refreshlinkTasks && !taskIdParams) {
      settaskIdParams("");
      getObservation(tasksPage);
    }
  }, [refreshlinkTasks]);

  useEffect(() => {
    if (notRootLevelRefresh && redirectedLink && taskIdParams) {
      getParamsData();
    }
  }, [notRootLevelRefresh]);
  useEffect(() => {
    if (taskId && selectedRoomId && currentRefresh) {
      settaskIdParams("");
      getObservation("");
    }
  }, [selectedRoomId, currentRefresh]);

  useEffect(() => {
    if (selectedRoomId && !taskIdParams) {
      setcurrentRefresh(true);
      setLoader(true);

      setleftSideLoader(true);
      settasksPage(1);
      setActivePagetasks(1);
      getObservation(1);
    }
  }, [selectedRoomId]);

  const onSubmitTask = () => {
    const data = {
      submitted: "true",
    };
    updateInspectionbyId(insp_id, data, userInfo?.role)
      .then((res) => {
        setSubmitInspection(false);
        showToast("Inspection has been submitted.", true);
        history.push("/inspections");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setConfirmModals(false);
      });
  };

  const onDeleteTasks = () => {
    if (checkedData.length !== tasks?.data?.length) {
      deleteObservation(userInfo?.role, insp_id, checkedData)
        .then((res) => {
          showToast("Feature(s) has been successfully deleted", true);
        })
        .catch((err) => {
          showToast(err?.response?.data?.message);
        })
        .finally(() => {
          setdeleteTask(false);
          setConfirmModals(false);
          setleftSideLoader(true);
          setcurrentRefresh(true);
          setrefreshlinkTasks(true);
          setrefreshIcon(true);
          setnotRootLevelRefresh(true);
          setShowCheckbox(false);
          setLoader(true);
        });
    } else {
      showToast("Not allowed to delete all features!");
    }
  };
  useEffect(() => {
    if (taskIdParams && generalInfo.hasOwnProperty("id")) {
      getParamsData();
    }
  }, [generalInfo]);

  const getParamsData = async () => {
    setleftSideLoader(true);
    setLoader(true);

    if (generalInfo.rooms.find((rm) => rm.pivot.id === parseInt(roomId))) {
      getObservationsById(userInfo?.role, taskId, roomId)
        .then((obsImages) => {
          setselectedTask({
            images: obsImages.data.data,
            status: obsImages.data?.observation?.status,
            name: obsImages.data?.observation?.name,
            inspection_id: insp_id,
            id: obsImages.data?.observation?.id,
            room_name: obsImages.data?.observation?.room_name,
            room_pivot_id: obsImages.data?.observation?.room_pivot_id,
          });

          setRoomName(obsImages.data?.room_name);
          setselectedTaskNumber(1);
          settaskpageIndex(1);

          settasks({
            data: [
              {
                inspection_id: insp_id,
                name: obsImages.data?.observation?.name,
                room_name: obsImages.data?.observation?.room_name,
                room_pivot_id: roomId,
                status: obsImages.data?.observation?.status,
                images: obsImages.data.data,
                id: obsImages.data?.observation?.id,
                under_review: obsImages.data?.observation?.under_review,
              },
            ],
          });

          console.log(obsImages, "obsImages");
          setnotRootLevelRefresh(false);
          setcurrentRefresh(false);
          setrefreshlinkTasks(false);
          setrefreshIcon(false);

          setleftSideLoader(false);
          setLoader(false);
        })
        .catch((errImages) => {
          showToast("This task has been deleted");

          obsImgCatch();
        });
    } else {
      obsImgCatch();
      showToast("This room has been deleted");
    }
  };

  const obsImgCatch = () => {
    setSelectedRoomId("");
    setnotRootLevelRefresh(false);
    setcurrentRefresh(false);
    setrefreshlinkTasks(false);
    setrefreshIcon(false);
    setleftSideLoader(false);
    setLoader(false);
  };

  const getObservation = (page) => {
    setrefreshIcon(true);
    getObservationByRoom(page, userInfo?.role, insp_id, selectedRoomId)
      .then((res) => {
        settasks(res?.data);
        setItemsCountPerPagetasks(res?.data?.per_page);
        setTotalItemsCounttasks(res?.data?.total);
        setActivePagetasks(res?.data?.current_page);

        if (refreshlinkTasks) {
          setselectedTask(res?.data?.data[selectedTaskIndex]);
        } else {
          setselectedTaskIndex(0);
          setselectedTask(res?.data?.data[0]);
          setselectedTaskNumber(res?.data?.from);
        }
        setleftSideLoader(false);
        settaskpageIndex(res?.data?.from);
        setrefreshlinkTasks(false);
        setcurrentRefresh(false);
        setLoader(false);
        return getInspectionbyId(insp_id, userInfo?.role);
      })
      .then((inspection_data) => {
        setinspectionDetails(inspection_data?.data);
      })
      .catch((err) => showToast("No room exists"))
      .finally(() => {
        setLoader(false);
        setcurrentRefresh(false);
        setrefreshlinkTasks(false);
        setrefreshIcon(false);
      });
  };

  const changeImg = (updatedImageIndex) => {
    setselectedSubmittedImageIndex(updatedImageIndex);
    setselectedSubmittedImageNumber(
      `${selectedTaskNumber}${String.fromCharCode(65 + updatedImageIndex)}`
    );
  };
  const nextImage = () => {
    let currentGreater =
      conditionalViewer !== "instructional"
        ? totalImages.filter((n) => n > selectedSubmittedImageIndex)
        : totalInstImgaes.filter((n) => n > selectedSubmittedImageIndex);
    changeImg(currentGreater[0]);
  };
  const prevImage = () => {
    let currentLess =
      conditionalViewer !== "instructional"
        ? totalImages.filter((n) => n < selectedSubmittedImageIndex)
        : totalInstImgaes.filter((n) => n < selectedSubmittedImageIndex);

    changeImg(currentLess[currentLess.length - 1]);
  };

  return (
    <React.Fragment>
      {!generalInfo.hasOwnProperty("id") ? (
        <div className="spinner-div bg-color">
          <Spinner animation="border" variant="success" />
        </div>
      ) : (
        <div className="tab-child">
          <OLS
            taskIdParams={taskIdParams}
            RoomName={RoomName}
            selectedRoomId={selectedRoomId}
            loader={loader}
            tasks={tasks}
            selectedTask={selectedTask}
            setselectedTask={setselectedTask}
            selectedTaskIndex={selectedTaskIndex}
            setselectedTaskIndex={setselectedTaskIndex}
            settasksPage={settasksPage}
            taskpageIndex={taskpageIndex}
            setselectedTaskNumber={setselectedTaskNumber}
            activePagetasks={activePagetasks}
            itemsCountPerPagetasks={itemsCountPerPagetasks}
            totalItemsCounttasks={totalItemsCounttasks}
            refreshlinkTasks={refreshlinkTasks}
            setrefreshlinkTasks={setrefreshlinkTasks}
            setCheckedData={setCheckedData}
            setCheckedCount={setCheckedCount}
            setOpenAddFeatureModal={setOpenAddFeatureModal}
            setloadingImg={setloadingImg}
            counterImg={counterImg}
            setcounterImg={setcounterImg}
            refreshIcon={refreshIcon}
            currentRefresh={currentRefresh}
            setcurrentRefresh={setcurrentRefresh}
            setConfirmModals={setConfirmModals}
            showCheckbox={showCheckbox}
            setShowCheckbox={setShowCheckbox}
            isChecked={isChecked}
            setisChecked={setisChecked}
            checkDisable={checkDisable}
            userInfo={userInfo}
            insp_id={insp_id}
            roomId={roomId}
            setRoomName={setRoomName}
            setSelectedRoomId={setSelectedRoomId}
            inspectionDetails={inspectionDetails}

          />
          <ORS
            userInfo={userInfo}
            RoomName={RoomName}
            selectedRoomId={selectedRoomId}
            setConditionalViewer={setConditionalViewer}
            setselectedSubmittedImageIndex={setselectedSubmittedImageIndex}
            setselectedSubmittedImageModal={setselectedSubmittedImageModal}
            setselectedSubmittedImageNumber={setselectedSubmittedImageNumber}
            setselectedSubmittedImageModalView={
              setselectedSubmittedImageModalView
            }
            setselectedInstructImageModalView={
              setselectedInstructImageModalView
            }
            setselectedInstructImageNumber={setselectedInstructImageNumber}
            setselectedInstructImageIndex={setselectedInstructImageIndex}
            inspectionDetails={inspectionDetails}
            photosLoader={photosLoader}
            selectedTask={selectedTask}
            setselectedTask={setselectedTask}
            selectedTaskIndex={selectedTaskIndex}
            selectedTaskNumber={selectedTaskNumber}
            leftSideLoader={leftSideLoader}
            setaddInstructionalModal={setaddInstructionalModal}
            openAddTaskModal={openAddTaskModal}
            setOpenAddTaskModal={setOpenAddTaskModal}
            loadingImg={loadingImg}
            setloadingImg={setloadingImg}
            counterImg={counterImg}
            setcounterImg={setcounterImg}
            setConfirmTestModal={setConfirmTestModal}
            checkDisable={checkDisable}
            updateImageFullScreenModal={updateImageFullScreenModal}
            setrefreshlinkTasks={setrefreshlinkTasks}
            setnotRootLevelRefresh={setnotRootLevelRefresh}
            refreshlinkTasks={refreshlinkTasks}
            notRootLevelRefresh={notRootLevelRefresh}
            
          />
        </div>
      )}
      <Suspense fallback={null}>
        <ConfirmationModal
          show={confirmModals}
          onHide={() => setConfirmModals(false)}
          imgSrc={require("../../../../../assets/icons/delete.png").default}
          helperText={
            submitInspection === true
              ? "Are you sure you want to submit the inspections?"
              : "This action can not be undone. Are you sure you want to delete it?"
          }
          onSave={() => {
            submitInspection === true ? onSubmitTask() : onDeleteTasks();
            // setdeleteTask(true);
          }}
        />
      </Suspense>
      <ObservationModals
        selectedSubmittedImage={
          selectedTask?.images[selectedSubmittedImageIndex]
        }
        changeImage={{
          selectedSubmittedImageIndex,
          selectedTask,
          totalImages,
          totalInstImgaes,
        }}
        selectedSubmittedImageInstructional={
          selectedTask?.images[selectedInstructImageIndex]
        }
        role={userInfo?.role}
        selectedSubmittedImageNumber={selectedSubmittedImageNumber}
        showselectedSubmittedImageModal={selectedSubmittedImageModal}
        onHidesetselectedSubmittedImageModal={() =>
          setselectedSubmittedImageModal(false)
        }
        setrejectedModal={setrejectedModal}
        setnotRootLevelRefresh={setnotRootLevelRefresh}
        refreshlinkTasks={refreshlinkTasks}
        setrefreshlinkTasks={setrefreshlinkTasks}
        showselectedSubmittedImageModalView={selectedSubmittedImageModalView}
        onHideselectedSubmittedImageModalView={() =>
          setselectedSubmittedImageModalView(false)
        }
        selectedInstructImageNumber={selectedInstructImageNumber}
        showselectedInstructImageModalView={selectedInstructImageModalView}
        onHideselectedInstructImageModalView={() =>
          setselectedInstructImageModalView(false)
        }
        setBitImage={setBitImage}
        nextImage={nextImage}
        prevImage={prevImage}
        setrejectedModalAddnote={setrejectedModalAddnote}
        setselectedSubmittedImageModal={setselectedSubmittedImageModal}
        setShowApprovedEditModal={setShowApprovedEditModal}
        showApprovedEditModal={showApprovedEditModal}
        showrejectedModal={rejectedModal}
        onHiderejectedModal={() => setrejectedModal(false)}
        BitImage={BitImage}
        showrejectedModalAddnote={rejectedModalAddnote}
        onHiderejectedModalAddnote={() => setrejectedModalAddnote(false)}
        selectedTask={selectedTask?.id}
        showaddInstructionalModal={addInstructionalModal}
        onHideaddInstructionalModal={() => setaddInstructionalModal(false)}
        insp_id={insp_id}
        selectedRoomId={selectedRoomId}
        conditionalView={conditionalViewer}
        setConditionalViewer={setConditionalViewer}
        openAddFeatureModal={openAddFeatureModal}
        setOpenAddFeatureModal={setOpenAddFeatureModal}
        openAddTaskModal={openAddTaskModal}
        setOpenAddTaskModal={setOpenAddTaskModal}
        showAddWholeTaskImagesModal={AddWholeTaskImagesModal}
        onHideAddWholeTaskImagesModal={() => setAddWholeTaskImagesModal(false)}
        testRecommendationModal={testRecommendationModal}
        settestRecommendationModal={settestRecommendationModal}
        setConfirmModal={setConfirmModal}
        confirmModal={confirmModal}
        confirmTestModal={confirmTestModal}
        setConfirmTestModal={setConfirmTestModal}
        inspectionDetails={inspectionDetails}
        updateImageFullScreenModal={updateImageFullScreenModal}
        imageFullScreenModal={imageFullScreenModal}
      />
    </React.Fragment>
  );
}
