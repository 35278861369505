import "./style.css";

import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import React, { Suspense, useEffect, useState } from "react";
import {
  SEARCH_FIELD,
  SET_SEARCH_FILTER,
  TOGGLE_SIDENAV,
} from "../../../store/actions";
/* eslint-disable react-hooks/exhaustive-deps */
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { filtersObj, roleNames } from "../../shared/helpers";
import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PopoverFilter from "../PopoverFilter";
import ReactStars from "react-rating-stars-component";

const NotificationList = React.lazy(() => import("../NotificationsList"));

export default function Header() {
  const history = useHistory();
  const location = useLocation();
  const header = useSelector((state) => state.header);
  const showGoBack = useSelector((state) => state.app.showGoback);
  const dispatch = useDispatch();
  const toggleNavBar = () => {
    dispatch({ type: TOGGLE_SIDENAV });
  };
  const [iText, setiText] = useState(false);
  const [searchWord, setsearchWord] = useState("");
  useEffect(() => {
    const id = setTimeout(() => {
      dispatch({ type: SEARCH_FIELD, payload: encodeURIComponent(searchWord) });
    }, 500);

    return () => clearTimeout(id);
  }, [searchWord]);

  const goBackRedirection = () => {
    console.log(location.pathname, location?.state, "here is your");
    if (location.pathname === "/calendly") {
      if (
        location?.state?.inspection_id &&
        location?.state?.location === "edit" &&
        userInfo?.role === roleNames?.bu_homeowner
      ) {
        history.replace({
          pathname: "/inspections/" + location?.state?.inspection_id,
        });
      } else if (
        location?.state?.inspection_id &&
        location?.state?.location === "submit"
      ) {
        history.replace({
          pathname: "/inspections/new",
          state: {
            inspection_id: location?.state?.inspection_id,
          },
        });
      } else if (
        location?.state?.inspection_id &&
        location?.state?.location === "edit" &&
        userInfo?.role !== roleNames?.bu_homeowner
      ) {
        history.replace(
          "/inspections/" +
            location?.state?.inspection_id +
            "/details?tab=generalInfo"
        );
      }
    } else {
      // history.goBack();

      history.goBack();
      // history.replace("/inspections?page=1");
    }
  };

  useEffect(() => {
    setiText(false);
    setsearchWord("");

    // reseting the inspection filter if page changes
    if (!location.pathname.includes("/inspections")) {
      dispatch({ type: SET_SEARCH_FILTER, payload: filtersObj() });
    }
  }, [location.pathname]);

  const [ratingShow, setratingShow] = useState(header?.rating);
  useEffect(() => {
    setratingShow(header?.rating);
  }, [header?.rating]);

  const userInfo = useSelector((state) => state.user.data);

  const { insp_id } = useParams();

  return (
    <div className={`header ${!header?.icon && " half-header"}`}>
      
      <div>
        {showGoBack && (
          <div
            className="go-back"
            id="go-back-button"
            onClick={() => goBackRedirection()}
          >
            <img
              style={{ marginBottom: "3px" }}
              src={
                require(`../../../assets/icons/${
                  header?.icon ? "back@2x.png" : "white-back@2x.png"
                }`).default
              }
              alt="go back"
              width="20px"
            />

            {/* <FontAwesomeIcon className="text-secondary500" icon={faCaretLeft} /> */}
            <span className={`go-back-text ${!header?.icon && "white-text"}`}>
              Back
            </span>
          </div>
        )}
        {header.icon && (
          <div
            className="d-flex align-items-center"
            style={{
              marginBottom: "0px",
            }}
          >
            <img
              className="heading-icon"
              src={
                require(`../../../assets/icons/${
                  header?.icon.split(".")[0] + "1." + header?.icon.split(".")[1]
                }`).default
              }
              style={{ width: header.iconWidth && header.iconWidth }}
              alt=""
            />

            <h3
              className=" header-title one-line-ellipsis"
              style={{
                marginBottom: "-3px",
              }}
            >
              {header?.title === "Change Password" ||
              header?.title === "User Details"
                ? "Settings"
                : header?.title}
            </h3>

            {location.pathname !== "/users/new" &&
              location.pathname.includes("/users/") &&
              ratingShow >= 0 &&
              ratingShow !== false &&
              ratingShow !== "" && (
                <div style={{ paddingLeft: "5px" }}>
                  <ReactStars
                    size={20}
                    count={5}
                    color="black"
                    value={ratingShow}
                    a11y={true}
                    isHalf={true}
                    edit={false}
                    emptyIcon={
                      <img
                        src={
                          require(`../../../assets/icons/big-star-gray@2x.png`)
                            .default
                        }
                        alt="empty"
                        width="30px"
                        style={{ paddingRight: "5px", paddingBottom: "5px" }}
                      />
                    }
                    halfIcon={
                      <img
                        src={
                          require(`../../../assets/icons/half-star@2x.png`)
                            .default
                        }
                        alt="empty_3"
                        width="30px"
                        style={{ paddingRight: "5px", paddingBottom: "5px" }}
                      />
                    }
                    filledIcon={
                      <img
                        src={
                          require(`../../../assets/icons/big-star-yellow@2x.png`)
                            .default
                        }
                        alt="empty_2"
                        style={{ paddingRight: "5px", paddingBottom: "5px" }}
                        width="30px"
                      />
                    }
                  />
                </div>
              )}
          </div>
        )}
      </div>
      {header.icon && (
        <div className="d-flex align-items-center justify-content-end">
          {header.showSearch && (
            <div
              style={
                iText
                  ? { marginRight: "27px", marginTop: "3px" }
                  : { marginRight: "44px", marginTop: "-27px" }
              }
            >
              <img
                className={` search-box-icon  ${iText ? "" : "margin-without"}`}
                src={require("../../../assets/icons/search-1@2x.png").default}
                alt="search icon"
                style={iText ? { width: "24px" } : {}}
                onClick={() => !iText && setiText(true)}
              />
              {iText && (
                <>
                  <FontAwesomeIcon
                    className="cancel-box-icon text-light20"
                    icon={faTimes}
                    onClick={() => {
                      // const searchParams = new URLSearchParams(location.search);
                      // searchParams.set("page", "1");

                      // history.replace({
                      //   pathname: "/inspections",
                      //   search: searchParams.toString(),
                      // });

                      setsearchWord("");
                      setiText(false);
                    }}
                  />

                  <input
                    type="text"
                    name="search"
                    className={`new-rect ${iText && "wid"}`}
                    onChange={(e) => {
                      setsearchWord(e.target.value);
                    }}
                    value={searchWord}
                    autoFocus
                  />
                </>
              )}
            </div>
          )}
          {location.pathname === "/inspections" && <PopoverFilter />}
          {(location.pathname === "/users" ||
            location.pathname === "/inspections" ||
            location.pathname === "/properties") && (
            <Suspense fallback={null}>
              <NotificationList />
            </Suspense>
          )}
          {header.title === "Inspections" && (
            <>
              {/* <PopoverFilter /> */}
              <Link
                className="ml-auto"
                style={{ textDecoration: "none" }}
                to="/inspections/new"
                id="create_inspection"
              >
                <button
                  className="btn btn-primary"
                  style={{ padding: "10px 10px" }}
                >
                  <img
                    alt="create_inspection"
                    className=""
                    src={require(`../../../assets/icons/add@2x.png`).default}
                  />
                  Create Inspection
                </button>
              </Link>
            </>
          )}

          {header.showSearch && header.title === "Properties" && (
            <Link
              className="ml-auto"
              style={{ textDecoration: "none" }}
              to="/properties/new"
              id="add_property"
            >
              <button className="btn btn-primary">
                <img
                  alt="add property"
                  className=""
                  src={require(`../../../assets/icons/add@2x.png`).default}
                />
                Add Property
              </button>
            </Link>
          )}

          {header.showSearch && header.title === "Users" && (
            <Link
              className="ml-auto"
              style={{ textDecoration: "none" }}
              to="/users/new"
              id="add_user"
            >
              <button className="btn btn-primary">
                <img
                  alt="add_user"
                  className=""
                  src={require(`../../../assets/icons/add@2x.png`).default}
                />
                Add User
              </button>
            </Link>
          )}
          <FontAwesomeIcon
            className="open-menu-icon"
            icon={faBars}
            onClick={() => toggleNavBar()}
            id="toggle-navbar-button"
          />
        </div>
      )}
    </div>
  );
}
