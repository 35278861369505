export const createSubmitTestAPIPayload = (
  testNotes = "",
  currentSelectedTest = {},
  selectedRoomsList = []
) => {
  const data = {
    description: testNotes || "",
    testId: currentSelectedTest?.value,
    tasks: [],
  };

  selectedRoomsList.forEach((room) => {
    room?.observations?.forEach((feature) => {
      feature?.tasks?.forEach((task) => {
        if (task.isSelected) {
          const taskData = {
            roomId: room?.roomUniqueId,
            featureId: feature?.id,
            taskId: task?.id,
          };
          data.tasks.push(taskData);
        }
      });
    });
  });
  return data;
};
