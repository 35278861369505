import React, { useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { components } from "react-select";
import CustomTooltip from "main/components/Tooltip/Tooltip";
import dropDownStyles from "./select_drop_down.module.css";

const { Option } = components;

const getColorStyles = (showMultiRemoveValue = false) => {
  const colorStyles = {
    valueContainer: (provided, state) => ({
      display: "flex",
      maxWidth: "360px",
      paddingLeft: "12px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      flex: "1 1 0%",
      position: "relative",
      overflowX: "auto",
    }),
    input: (provided, state) => ({
      ...provided,
      display: "inline",
    }),
    option: (styles, props) => {
      return {
        background: "none",
        padding: "8px 12px",
      };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        display: "flex",
        alignItems: "center",
        borderRadius: "8px",
      };
    },
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      display: showMultiRemoveValue ? "block" : "none",
      ":hover": {
        color: "black",
        cursor: "pointer",
      },
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "gray",
      };
    },
    clearIndicator: (styles) => {
      return {
        ...styles,
        cursor: "pointer",
        color: "black",
        ":hover": {
          color: "black",
          cursor: "pointer",
        },
      };
    },
  };
  return colorStyles;
};

const SelectDropDown = ({
  placeholder = "Select ..",
  isMulti = false,
  closeMenuOnSelect = true,
  hideSelectedOptions = false,
  defaultMenuIsOpen = false,
  isClearable = true,
  showMultiRemoveValue = false,
  isSearchable = false,
  searchValue = "",
  defaultValue = "",
  handleOnChange = () => {},
  onMenuOpen = () => {},
  onMenuClose = () => {},
  resetCacheKey = "1",
  loadOptions = () => {
    return { options: [], hasMore: false };
  },
  options = [],
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleOnMenuOpen = (e) => {
    setIsMenuOpen(true);
    onMenuOpen(e);
  };

  const handleOnMenuClose = (e) => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
      onMenuClose(e);
    }
  };

  const validateIsSelected = (value) => {
    if (Array.isArray(searchValue)) {
      const isInArray = searchValue.find((val) => val?.value === value);
      return isInArray;
    } else {
      if (searchValue?.value === value) {
        return true;
      } else {
        return false;
      }
    }
  };
  const IconOption = (props) => {
    return (
      <Option {...props}>
        <div className="d-flex align-items-center justify-content-between">
          <span> {props.data.label}</span>
          <span>
            {validateIsSelected(props.data.value) ? (
              <img
                src={require("assets/icons/radio@2x.png").default}
                className={dropDownStyles.radioIconImage}
                alt={props.data.label}
              />
            ) : (
              <img
                src={require("assets/icons/radio1@2x.png").default}
                className={dropDownStyles.radioIconImage}
                alt={props.data.label}
              />
            )}
          </span>
        </div>
      </Option>
    );
  };

  const MultiValueContainer = (props) => {
    return (
      <CustomTooltip
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        text={props?.data?.label}
      >
        <div className={dropDownStyles.multiValueContainer}>
          <components.MultiValueContainer {...props} />
        </div>
      </CustomTooltip>
    );
  };
  return (
    <div>
      <AsyncPaginate
        key={resetCacheKey}
        isMulti={isMulti}
        placeholder={placeholder}
        defaultMenuIsOpen={defaultMenuIsOpen}
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={hideSelectedOptions}
        isClearable={isClearable}
        isSearchable={isSearchable}
        value={searchValue}
        onChange={handleOnChange}
        components={{
          Option: IconOption,
          MultiValueContainer,
        }}
        loadOptions={loadOptions}
        styles={getColorStyles(showMultiRemoveValue)}
        onMenuClose={handleOnMenuClose}
        onMenuOpen={handleOnMenuOpen}
        options={options}
      />
    </div>
  );
};

export default SelectDropDown;
