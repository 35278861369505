import React from "react";
import styles from "./test_right_side.module.css";
import { useLocation } from "react-router-dom";
import {
  viewTestAndRoomsQueryTabName,
  viewTestAndRoomsTabs,
} from "main/shared/constants";
import { useSelector } from "react-redux";
import RoomView from "./RoomView/RoomView";
import TestView from "./TestView/TestView";

const TestRightSide = ({
  resetSelectedRoomData,
  keyForResetCacheOFSelect,
  setKeyForResetCacheOFSelect,
  setRoomSearchValue,
  roomSearchValue,
  resetSelectedTestData,
  testSearchValue,
  setTestSearchValue,
}) => {
  const fetchRoomList = useSelector(
    (state) => state.fetchViewRecommendedRoomList
  );
  const fetchTestList = useSelector(
    (state) => state.fetchViewRecommendedTestList
  );
  const { currentSelectedRoom } = fetchTestList;
  const { currentSelectedTest } = fetchRoomList;

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  searchParams.get(viewTestAndRoomsQueryTabName);

  const isRoomModeEnable = () => {
    const mode = searchParams.get(viewTestAndRoomsQueryTabName);
    return mode === viewTestAndRoomsTabs.roomView.value;
  };

  return (
    <div className={styles.container}>
      {!currentSelectedTest?.name && !currentSelectedRoom?.description ? (
        <div className={styles.default_image}>
          <img
            alt="default_image"
            src={require("assets/icons/no-task-update.png").default}
            width="288px"
          />
        </div>
      ) : (
        <div className={styles.content_container}>
          {isRoomModeEnable() ? (
            <RoomView
              resetSelectedRoomData={resetSelectedRoomData}
              keyForResetCacheOFSelect={keyForResetCacheOFSelect}
              setKeyForResetCacheOFSelect={setKeyForResetCacheOFSelect}
              setRoomSearchValue={setRoomSearchValue}
              roomSearchValue={roomSearchValue}
            />
          ) : (
            <TestView
              resetSelectedTestData={resetSelectedTestData}
              keyForResetCacheOFSelect={keyForResetCacheOFSelect}
              setKeyForResetCacheOFSelect={setKeyForResetCacheOFSelect}
              testSearchValue={testSearchValue}
              setTestSearchValue={setTestSearchValue}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TestRightSide;
