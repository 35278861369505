import "./style.css";
import { useState } from "react";
export default function ImageBox({
  src,
  style,
  imgStyle,
  image_number,
  selectedTaskIndex,
  setselectedSubmittedImageIndex,
  setselectedSubmittedImageNumber,
  setselectedSubmittedImageModal,
  status,
  selectedTaskNumber,
  selectedTask,
  counterImg,
  setcounterImg,
  setloadingImg,
  imageData,
  setConfirmTestModal,
  handleFullscreenModal,
}) {
  const [imageLoadError, setImageLoadError] = useState(false);

  const imageLoaded = () => {
    if (counterImg + 4 >= selectedTask?.images?.length) {
      setloadingImg(false);
    }
    if (counterImg <= selectedTask?.images?.length) {
      setcounterImg(counterImg + 1);
    }
  };

  const imageBoxCTA = () => {
    setselectedSubmittedImageModal(true);
    setselectedSubmittedImageIndex(image_number);
    setselectedSubmittedImageNumber(
      `${selectedTaskNumber}${String.fromCharCode(65 + image_number)}`
    );
  };

  const handleViewFullScreenImage = () => {
    handleFullscreenModal(image_number);
  };

  const handleImageLoadError = () => {
    setImageLoadError(true);
  };

  return (
    <div className=" flex-column mb-3" style={{ textAlign: "center" }}>
      <div
        style={{
          height: "120px",
          width: "120px",
          border: "2px solid #DEE2DF",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          borderRadius: "8px",
          margin: "5px 28px 10px 1px",
          transition: "0.5s",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          borderRadius: "18px",
          ...style,
        }}
      >
        {src ? (
          <div
            className={
              !imageLoadError ? "image-container" : "error-image-container"
            }
          >
            {imageData.tests_recommended && (
              <img
                alt="test recommended"
                src={
                  require("../../../assets/icons/test_recommend.png").default
                }
                className="img-test-recommend"
              />
            )}
            <img
              src={`${src}`}
              key={`${src}`}
              style={{
                color: "transparent",
                height: "120px",
                width: "120px",
                objectFit: "contain",
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                borderRadius: "18px",
                boxShadow: "4px 2px 13px 0px #00000029",
                ...imgStyle,
              }}
              // onload="this.style.color='black'"
              // onerror="this.style.color='black'"
              alt="test"
              className={!imageLoadError ? "uploaded-image" : ""}
              onErrorCapture={handleImageLoadError}
            />
            {!imageLoadError ? (
              <div className="overlay-container">
                <img
                  className="overlay"
                  onClick={handleViewFullScreenImage}
                  src={require("../../../assets/icons/view-img.png").default}
                  alt="fullscreen_icon"
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <img
            src={require("../../../assets/icons/dummy-image.png").default}
            width="365px"
            style={{
              color: "transparent",
              border: status === "pending_2" && "1px solid #fed636",
              height: "120px",
              width: "120px",
              objectFit: "contain",
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              borderRadius: "18px",
              boxShadow: "4px 2px 13px 0px #00000029",
              ...imgStyle,
            }}
            // onload="this.style.color='black'"
            // onerror="this.style.color='black'"
            alt="status test"
          />
        )}
      </div>

      {src && (
        <span
          className="image-text"
          style={{
            cursor: "pointer",
            marginLeft:
              status !== "rejected" && status !== "approved"
                ? "-20px"
                : "-15px",
          }}
          onClick={() => {
            imageBoxCTA();
          }}
        >
          View Task
          {/* ({selectedTaskNumber > 0 && selectedTaskNumber}
          {String.fromCharCode(65 + image_number)}) */}
          {status === "rejected" && (
            <img
              src={require("../../../assets/icons/Group 80487@2x.png").default}
              alt="icon"
              style={{
                width: "16px",
                marginBottom: "4px",
                marginLeft: "4px",
              }}
            />
          )}
          {status === "approved" && (
            <img
              src={require("../../../assets/icons/Group_80486@2x.png").default}
              alt="icon"
              style={{
                width: "16px",
                marginBottom: "4px",
                marginLeft: "4px",
              }}
            />
          )}
        </span>
      )}
    </div>
  );
}
